import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App/App";
import "./styles/global.css";
import MetaTags from "react-meta-tags";

render(
  <Router>
    <MetaTags>
      <meta
        property="og:image"
        content="https://d13lb9ktuialea.cloudfront.net/resources/images/video.poster.png"
      />
      <meta
        property="og:image:secure_url"
        content="https://d13lb9ktuialea.cloudfront.net/resources/images/video.poster.png"
      />
    </MetaTags>
    <App />
  </Router>,
  document.getElementById("root")
);
