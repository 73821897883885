import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";

export function setFilters(filters) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_FILTERS,
    filters,
  });
}

export function resetFilters() {
  dispatcher.dispatch({
    actionType: actionTypes.RESET_FILTERS,
  });
}
