import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { IsMobileContext } from "../../../providers/IsMobileService";

function CarouselItem(props) {
  const isMobile = useContext(IsMobileContext);

  return (
    <Box
      sx={{
        height: 250,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: 125,
          width: isMobile ? "100vw" : 900,
        }}
      >
        {props.content.map((line) => (
          <Typography
            key={line}
            noWrap
            sx={{
              lineHeight: "1.4em",
              color: "rgba(8,34,36,.8)",
              fontWeight: "light",
            }}
            variant={"h6"}
          >
            {line}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

export default CarouselItem;
