import React, { useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import MessagesDisplay from "./MessagesDisplay";
import chatStore from "../../stores/chatStore";
import * as chatActions from "../../actions/chatActions";
import { useHistory } from "react-router-dom";
import { IsMobileContext } from "../../providers/IsMobileService";
import { KeyboardArrowLeft } from "@mui/icons-material";

export default function ChatWindow(props) {
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [content, setContent] = useState("");
  const [chatLog, setChatLog] = useState(
    props.chatLog ||
      chatStore.getChatLog(props.receiver.id, props.receiver.type)
  );

  const BUSY_WAIT_INTERVAL_SEC = 30;

  useEffect(() => {
    if (isLoading) {
      chatStore.addChangeListener(chatLogUpdated);
    }

    if (!chatLog || chatLog.length === 0) {
      chatActions.getChatLog(props.receiver, props.currentUser);
    }

    setInterval(() => {
      chatActions.getChatLog(props.receiver, props.currentUser);
    }, 1000 * BUSY_WAIT_INTERVAL_SEC);

    return () => {
      chatStore.removeChangeListener(chatLogUpdated);
    };
  }, []);

  useEffect(() => {
    chatLogUpdated();
  }, [props]);

  function chatLogUpdated() {
    let updatedChatData = chatStore.getChatLog(
      props.receiver.id,
      props.receiver.type
    );

    setChatLog(updatedChatData);
    setIsLoading(false);
  }

  function handleSubmit(e) {
    e && e.preventDefault();

    if (content) {
      let message = {
        time: Date.now(),
        content,
        sender: props.currentUser,
      };

      setContent("");

      setChatLog([...chatLog, message]);
      chatActions.sendMessage(props.currentUser, props.receiver, message);
    }
  }

  function handleChange({ target }) {
    setContent(target.value);
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      {isMobile && (
        <Box
          sx={{
            width: "100vw",
            position: "fixed",
            backgroundColor: theme.palette.body.foreground,
            py: ".5em",
            px: "1em",
            borderBottom: "1px solid #ddd",
            left: 0,
            zIndex: 1,
            top: `calc(${theme.sizing.navBar.height} + 5em)`,
          }}
        >
          <Button
            sx={{ textTransform: "none" }}
            startIcon={<KeyboardArrowLeft />}
            onClick={props.handleClose}
          >
            {"Messages"}
          </Button>
        </Box>
      )}
      <Box
        id={"messageBoard"}
        sx={{
          alignItems: "center",
        }}
      >
        <MessagesDisplay
          isTyping={isTyping}
          messages={chatLog}
          currentUser={props.currentUser}
          receiver={props.receiver}
        />
      </Box>
      <Box
        sx={{ position: "sticky", bottom: 0, pb: 2, backgroundColor: "#fff" }}
      >
        <TextField
          inputRef={(input) => input && input.focus()}
          autoFocus
          fullWidth
          id="content"
          name={"content"}
          onChange={handleChange}
          label="Write a message"
          value={content}
          color={props.currentUser.type}
        />
        <Button
          color={props.currentUser.type}
          sx={{ mt: 1, float: "right" }}
          type={"submit"}
          variant={"contained"}
        >
          Send
        </Button>
      </Box>
    </form>
  );
}
