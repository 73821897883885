import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import SideMenu from "./SideMenu/SideMenu";
import userStore from "../../../stores/userStore";
import { useLocation } from "react-router-dom";
import * as userActions from "../../../actions/userActions";
import ActionApproveDialog from "../ActionApproveDialog/ActionApproveDialog";
import { Auth } from "aws-amplify";
import { IsMobileContext } from "../../../providers/IsMobileService";

function Navigation({ handleLogin }) {
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(userStore.getCurrentUser());
  const [currentUserType, setCurrentUserType] = useState(
    userStore.getCurrentType()
  );

  const [approveModalState, setApproveModalState] = useState({
    isOpen: false,
  });
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  useEffect(() => {
    userStore.addChangeListener(userChanged);

    return () => userStore.removeChangeListener(userChanged);
  }, [location]);

  function userChanged() {
    setCurrentUser(userStore.getCurrentUser());
    setCurrentUserType(userStore.getCurrentType());
  }

  function toggleSideMenuOpen() {
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  function handleLogout() {
    setApproveModalState({
      dialogTitleText: "Logout",
      messageText: `Are you sure you want to logout?`,
      isOpen: true,
      handleReject: () => {
        setApproveModalState({ isOpen: false });
      },
      handleApprove: () => {
        logout();
        setApproveModalState({ isOpen: false });
      },
    });
  }

  function logout() {
    try {
      Auth.signOut().then((awsUser) => {
        userActions.logout();
      });
    } catch (error) {
      console.log("error:", error);
    }
    userActions.logout();
  }

  function handleToggleDeactivateUser() {
    deactivateUser();
  }

  function deactivateUser() {
    userActions.updateUser({
      ...currentUser,
      [currentUserType]: {
        ...currentUser[currentUserType],
        availability: !currentUser[currentUserType].availability,
      },
    });
  }

  return (
    <>
      <ActionApproveDialog {...approveModalState} />
      <Navbar
        handleLogin={handleLogin}
        handleToggleDeactivateUser={handleToggleDeactivateUser}
        toggleSideMenuOpen={toggleSideMenuOpen}
      />
      <SideMenu
        isSideMenuOpen={isSideMenuOpen}
        toggleSideMenuOpen={toggleSideMenuOpen}
        currentUser={currentUser}
        currentUserType={currentUserType}
        handleLogout={handleLogout}
      />
    </>
  );
}

export default Navigation;
