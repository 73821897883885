import React from "react";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import { userType } from "../../../../../shared/userType";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import CONF from "../../../../../configurations/applicationConf";
import ListItemText from "@mui/material/ListItemText";
import FieldsStore from "../../../../../stores/FieldsStore";
import { useTheme } from "@mui/material";

function RoleSwitch({ user, currentUserType, handleClick, selectedUser }) {
  const theme = useTheme();

  return (
    <div>
      {user.userType.map((type) => (
        <NavLink to={`/${type}/${user.username}`} key={type}>
          <ListItem
            sx={{
              "&:hover": {
                backgroundColor:
                  theme.palette[currentUserType || userType.PERSONAL].hover,
              },
              "&.Mui-selected": {
                backgroundColor: "#fff",
              },
              "&.Mui-selected:hover": {
                backgroundColor: theme.palette[type || userType.PERSONAL].hover,
              },
              "&.Mui-selected .MuiSvgIcon-root": {
                color: theme.palette[type || userType.PERSONAL].main,
              },
              "&.Mui-selected .MuiTypography-root": {
                color: theme.palette[type || userType.PERSONAL].main,
                fontSize: "0.9rem",
              },
            }}
            button
            selected={selectedUser === type}
            onClick={() => {
              handleClick(type);
            }}
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  width: "1.8em",
                  height: "1.8em",
                  border: `2px solid ${theme.palette[type].main}`,
                }}
                src={
                  user[type].image
                    ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${user[type].image}`
                    : user[type]?.socialImage || ""
                }
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              sx={{
                color: theme.palette.sideNav.labels,
              }}
              primary={
                FieldsStore.getFieldByUserAndType(user, type)?.name ||
                user.firstName
              }
            />
          </ListItem>
        </NavLink>
      ))}
    </div>
  );
}

export default RoleSwitch;
