import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { TermTexts } from "../../../shared/TextsAndMessages/Terms";
import InformationDialog from "../../common/InformationDialog/InformationDialog";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import logo from "../../../shared/resources/images/logo.white.svg";
import { IsMobileContext } from "../../../providers/IsMobileService";

function Footer() {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    footerBasic: {
      position: "relative",
      width: "100vw",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      background: "#0a2224",
      color: theme.palette.footer.bright,
      padding: "3em 0",

      "& .MuiTypography-root": { color: theme.palette.footer.bright },

      "& ul": {
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        textAlign: "center",
        padding: 0,
        listStyle: "none",
        fontSize: 18,
        lineHeight: "1.6",
        marginBottom: 0,
      },

      "& li": {
        padding: !isMobile ? "0 1.2em" : ".5em 0",
      },

      "& ul *": {
        color: "inherit",
        textDecoration: "none",
        opacity: 0.85,
      },
      "& ul *:hover": {
        opacity: 1,
      },
    },

    social: {
      color: theme.palette.footer.bright,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "flex-start",
    },
    socialIcons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      textAlign: "center",
      padding: "2em 0",
    },

    roundButton: {
      fontSize: 24,
      width: 40,
      height: 40,
      lineHeight: 40,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "50%",
      border: "1px solid #ccc",
      margin: "0 .5em",
      color: "inherit",
      opacity: 0.75,

      "&:hover": {
        opacity: 1,
      },
    },

    copyright: {
      marginTop: "15px",
      textAlign: "center",
      fontSize: "13px",
      color: "inherit",
      marginBottom: 0,
    },
  }));
  const classes = useStyles();

  return (
    // <div className={classes.footerBasic}>
    <footer className={classes.footerBasic}>
      <Box
        sx={{
          width: isMobile ? "90%" : "40%",
          ml: 2,
          mb: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            mb: isMobile && 5,
          }}
        >
          <img
            style={{
              width: isMobile ? "40vw" : "140px",
              marginBottom: "1em",
            }}
            src={logo}
            alt={"GoGeThem"}
          />
          <Typography
            variant={"body2"}
            sx={{ fontWeight: 300, lineHeight: 2, ml: !isMobile && "2em" }}
          >
            Go GeT Them is an international website based on a video platform.
            The site connects job seekers to employers, freelancers and talents.
            We have a place for everyone!
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {isMobile && (
            <Typography variant={"h6"} sx={{ color: "inherit" }}>
              MENU
            </Typography>
          )}
          <ul>
            <li>
              <a href="#">
                <Typography>Home</Typography>
              </a>
            </li>
            <li>
              <a href="#">
                <Typography>Services</Typography>
              </a>
            </li>
            <li>
              <a href="#">
                <Typography>About</Typography>
              </a>
            </li>
            <li>
              <a href="mailto:support@gogethem.com?subject=Support&body=Hi I have an issue with (describe in details, please provide the email you are registered with)">
                <Typography>Contact Us</Typography>
              </a>
            </li>
          </ul>
        </Box>

        {!isMobile && (
          <Typography
            variant={"body2"}
            sx={{
              color: theme.palette.footer.dark,
              fontWeight: 300,
              mt: 4,
            }}
          >
            &copy; 2021 GOGETHEM. all rights reserved
          </Typography>
        )}
      </Box>

      {isMobile && (
        <Divider
          variant={"fullWidth"}
          light
          sx={{
            backgroundColor: theme.palette.footer.dark,
            my: 1,
            width: "100vw",
          }}
        />
      )}

      {isMobile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "90%",
            ml: 2,
          }}
        >
          <ul>
            <li>
              <InformationDialog
                bodyText={TermTexts.TERM_TEXT_AGREEMENT}
                buttonText={"Close"}
              >
                <Typography>Terms</Typography>
              </InformationDialog>
            </li>
            <li>
              <a href="#">
                <InformationDialog
                  bodyText={TermTexts.DATA_POLICY_TEXT_AGREEMENT}
                  buttonText={"Close"}
                >
                  <Typography>Privacy Policy</Typography>
                </InformationDialog>
              </a>
            </li>
          </ul>
        </Box>
      )}

      <Box
        sx={{
          color: theme.palette.footer.bright,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "flex-start",
          mt: 3,
        }}
      >
        <Typography sx={{ color: "inherit", ml: 3 }}>
          JOIN OUR COMMUNITY
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            textAlign: "center",
            mb: 3,
            mt: 2,
          }}
        >
          <IconButton className={classes.roundButton}>
            <Instagram />
          </IconButton>
          <IconButton className={classes.roundButton}>
            <Twitter />
          </IconButton>
          <IconButton
            className={classes.roundButton}
            onClick={() => {
              window.open(
                "https://www.facebook.com/GoGeThem.Community",
                "_blank"
              );
            }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            className={classes.roundButton}
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UCX0FVlgPotO0Gz4Wdyu50XA",
                "_blank"
              );
            }}
          >
            <YouTube />
          </IconButton>
        </Box>
        {!isMobile && (
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <ul>
              <li>
                <InformationDialog
                  bodyText={TermTexts.TERM_TEXT_AGREEMENT}
                  buttonText={"Close"}
                >
                  <Typography>Terms</Typography>
                </InformationDialog>
              </li>
              <li>
                <a href="#">
                  <InformationDialog
                    bodyText={TermTexts.DATA_POLICY_TEXT_AGREEMENT}
                    buttonText={"Close"}
                  >
                    <Typography>Privacy Policy</Typography>
                  </InformationDialog>
                </a>
              </li>
            </ul>
          </Box>
        )}

        {isMobile && (
          <Box
            sx={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"body2"}
              sx={{ color: theme.palette.footer.dark, fontWeight: 100 }}
            >
              &copy; 2021 GOGETHEM. all rights reserved
            </Typography>
          </Box>
        )}
      </Box>
    </footer>
    // </div>
  );
}

export default Footer;
