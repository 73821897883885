import React, { useContext, useState } from "react";
import "./styles.css";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuList from "@mui/material/MenuList";
import MenuItem from "./MenuItem/MenuItem";
import {
  HomeOutlined,
  SearchOutlined,
  SettingsOutlined,
  WorkOutline,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import RoleSwitch from "./RoleSwitch/RoleSwitch";
import RegisterUserRole from "./RegisterUserRole/RegisterUserRole";
import { userType } from "../../../../shared/userType";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as userActions from "../../../../actions/userActions";
import { IsMobileContext } from "../../../../providers/IsMobileService";

function SideMenu(props) {
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  const currentUser = props.currentUser;
  const currentUserType = props.currentUserType;
  const handleLogout = props.handleLogout;
  const [selectedItem, setSelectedItem] = useState("home");
  const [selectedUser, setSelectedUser] = useState(currentUserType);

  const menuItems = [
    {
      tooltip: "Home page",
      label: "Home",
      name: "home",
      icon: <HomeOutlined />,
      url: "/profile",
    },
    {
      tooltip: "Search page",
      label: "Search",
      name: "search",
      icon: <SearchOutlined />,
      url: "/search",
    },

    {
      tooltip: "Settings",
      label: "Settings",
      name: "settings",
      icon: <SettingsOutlined />,
      url: "/settings",
    },
    {
      isHidden: currentUserType !== userType.BUSINESS,
      tooltip: "Vacancy Management Page",
      label: "Vacancy Manage",
      name: "vacancyManage",
      icon: <WorkOutline />,
      url: "/vacancyManage",
    },
  ];

  function handleSelectPage(newVal) {
    setSelectedItem(newVal);
  }

  function handleSelectUser(newType) {
    setSelectedUser(newType);
    userActions.changeType(currentUser, newType);
  }

  return (
    <div>
      {currentUser && (
        <>
          <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={props.isSideMenuOpen}
            onClose={props.toggleSideMenuOpen}
            anchor="left"
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile ? "center" : "flex-start",
                width: theme.sizing.sideNav.width,
                paddingLeft: `calc((100vw - ${
                  theme.sizing.mainView.width + 20
                }px) / 2)`,
                pt: isMobile ? 0 : `calc(${theme.sizing.navBar.height} + 3em)`,
                zIndex: 1000,
                backgroundColor: theme.palette.body.background,
                boxShadow: "6px 3px 6px 0 rgba(0, 0, 0, 0.08)",
              },
            }}
          >
            <MenuList>
              {menuItems
                .filter((x) => !x.isHidden)
                .map((item) => (
                  <NavLink to={item.url} key={item.name}>
                    <MenuItem
                      colorType={currentUserType}
                      selected={selectedItem === item.name}
                      handleClick={(e) => {
                        if (isMobile) {
                          props.toggleSideMenuOpen();
                        }

                        handleSelectPage(e);
                      }}
                      tooltip={item.tooltip}
                      button={item.label}
                      name={item.name}
                      icon={item.icon}
                    />
                  </NavLink>
                ))}
            </MenuList>

            <Divider variant="middle" sx={{ my: 2 }} />

            <List>
              <Typography
                sx={{ ml: 2, color: theme.palette.sideNav.headers }}
                variant={"body2"}
              >
                YOUR ACCOUNTS
              </Typography>

              <RoleSwitch
                user={currentUser}
                currentUserType={currentUserType}
                selectedUser={selectedUser}
                handleClick={(type) => {
                  if (isMobile) {
                    props.toggleSideMenuOpen();
                  }

                  handleSelectUser(type);
                  setSelectedItem(null);
                }}
              />

              {currentUser.userType.length < 4 && (
                <RegisterUserRole
                  user={currentUser}
                  userTypes={currentUser.userType}
                />
              )}

              <Divider variant="middle" sx={{ my: 2 }} />

              <NavLink to={"/"}>
                <MenuItem
                  colorType={currentUserType}
                  button={"Logout"}
                  name={"logout"}
                  icon={<LogoutOutlinedIcon />}
                  handleClick={(e) => {
                    if (isMobile) {
                      props.toggleSideMenuOpen();
                    }

                    handleLogout(e);
                  }}
                />
              </NavLink>
            </List>
          </Drawer>
        </>
      )}
    </div>
  );
}

export default SideMenu;
