import React, { useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Webcam from "@uppy/webcam";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import PropTypes from "prop-types";
import ExampleComponent from "../../../../shared/ExampleComponent";
import { Storage } from "aws-amplify";
import * as userActions from "../../../../actions/userActions";
import userStore from "../../../../stores/userStore";
import * as vacancyActions from "../../../../actions/vacancyActions";

function VideoUpload(props) {
  const [modalOpen, setModalOpen] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  function handleOpen() {
    setModalOpen(true);
  }
  function handleClose() {
    setModalOpen(false);
  }

  const uppy = new Uppy({
    id: "uppy1",
    meta: { type: "video" },
    autoProceed: false,
    restrictions: {
      maxFileSize: 1024 * 1024 * 500,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ["video/*"],
    },
  });

  uppy.use(Webcam, {
    onBeforeSnapshot: () => Promise.resolve(),
    countdown: true,
    modes: ["video-audio"],
    mirror: true,
    facingMode: "user",
    showRecordingLength: true,
    preferredVideoMimeType: null,
    preferredImageMimeType: null,
    locale: {},
  });

  uppy.on("file-added", (file) => {
    Storage.configure({
      AWSS3: {
        bucket: process.env.REACT_APP_S3_VIDEO_UPLOAD_BUCKET,
        region: "us-east-1",
      },
    });

    let filename = file.name.slice(0, -(file.extension.length + 1));
    setIsUploading(true);

    uppy.reset();
    handleClose();

    vacancyActions.addVideo(filename, props.vacancyId).then((result) => {
      file.name = result.updatedFilename + "." + file.extension;

      Storage.put(file.name, file.data, {
        progressCallback(progress) {
          let percent = (progress.loaded * 100) / progress.total;

          if (percent < 100) {
            setUploadProgress(percent);
          } else {
            setIsUploading(false);
            setUploadProgress(0);

            // Flag upload finnish
            props.uploadFinish(result.updatedVacancy);
          }
        },
      });
    });
  });

  return (
    <Dashboard
      uppy={uppy}
      open={modalOpen}
      replaceTargetContent
      height={300}
      showProgressDetails
      waitForThumbnailsBeforeUpload
      note={`Video upload, up to one file`}
      plugins={["Webcam"]}
      locale={{
        strings: {
          // Text to show on the droppable area.
          // `%{browse}` is replaced with a link that opens the system file selection dialog.
          dropHereOr: "Drop here or %{browse}",
          // Used as the label for the link that opens the system file selection dialog.
          browse: "browse",
        },
      }}
    />
  );
}

ExampleComponent.propTypes = {
  vacancyId: PropTypes.string.isRequired,
  uploadFinish: PropTypes.func.isRequired,
};

export default VideoUpload;
