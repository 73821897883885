import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import { Box, useTheme } from "@mui/material";
import ExampleComponent from "../../../shared/ExampleComponent";

TitleAndText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string, // "socialNetwork", "website", "email", "text"
};

TitleAndText.defaultProps = {
  type: "text",
};

export default function TitleAndText(props) {
  const theme = useTheme();

  const MAX_LENGTH = 32;
  const shouldDisplayText = props.type !== "socialNetwork";
  const title =
    props.type === "socialNetwork" ? createUrl() : props.title + " - ";
  const text = props.type !== "socialNetwork" && createUrl();

  function trimText() {
    return props.text?.length > MAX_LENGTH
      ? props.text.slice(0, MAX_LENGTH) + "…"
      : props.text;
  }

  function createUrl() {
    let url, result;

    switch (props.type) {
      case "socialNetwork": {
        if (!props.text.match(/^[a-zA-Z]+:\/\//)) {
          url = "http://" + props.text;
        } else {
          url = props.text;
        }

        result = (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            style={{ color: "rgb(13, 71, 161)" }}
          >{`My ${props.title}`}</a>
        );

        break;
      }
      case "website": {
        if (!props.text.match(/^[a-zA-Z]+:\/\//)) {
          url = "http://" + props.text;
        } else {
          url = props.text;
        }

        result = (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url || props.text}
            style={{ color: "rgb(13, 71, 161)" }}
          >
            {props.text}
          </a>
        );

        break;
      }
      case "email": {
        result = (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"mailto:" + props.text}
            style={{ color: "rgb(13, 71, 161)" }}
          >
            {props.text}
          </a>
        );
        break;
      }
      case "text": {
        result = trimText();

        break;
      }
    }

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& .MuiTypography-root": { lineHeight: "2.7em", fontWeight: 300 },
        "& a": {
          textDecoration: "underline",
        },
      }}
    >
      <Typography
        sx={{ color: theme.palette.body.lightText, mr: 1 }}
        variant={"body1"}
      >
        {title}
      </Typography>
      {shouldDisplayText && (
        <>
          {text ? (
            <Typography
              sx={{ color: theme.palette.body.text }}
              variant={"body1"}
              title={props.text}
            >
              {text}
            </Typography>
          ) : (
            <Typography
              sx={{ color: theme.palette.body.lightText }}
              variant={"body1"}
              title={"Unknown"}
            >
              Unknown
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}
