import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormControl } from "@mui/material";
import Geolocation from "../../Geolocation/GooglePlaces";

function LocationFilter(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
  }));
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.root}>
      <Geolocation
        style={props.style}
        label="City"
        handleChange={props.handleChange}
      />
    </FormControl>
  );
}

LocationFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default LocationFilter;
