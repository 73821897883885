import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FieldFilter from "./Filters/FieldFilter";
import FieldsStore from "../../../stores/FieldsStore";
import * as fieldsActions from "../../../actions/fieldsActions";
import LocationFilter from "./Filters/LocationFilter";
import { userType } from "../../../shared/userType";
import SalaryFilter from "./Filters/SalaryFilter";
import GenderFilter from "./Filters/GenderFilter";
import RangeFilter from "./Filters/RangeFilter";
import { searchType } from "../../../shared/searchType";
import FilterSet from "./FilterSet";
import VacancyTypeFilter from "./Filters/VacancyTypeFilter";
import * as filterActions from "../../../actions/filterActions";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { IsMobileContext } from "../../../providers/IsMobileService";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

function FiltersContainer(props) {
  const isMobile = useContext(IsMobileContext);

  const currentUserType = props.userType;
  const filters = props.filters;
  const [fields, setFields] = useState(getFields(filters.searchType));

  const [roleOptions, setRoleOptions] = useState({
    sub1: null,
    sub2: null,
    sub3: null,
  });
  const [selectedRole, setSelectedRole] = useState({
    sub1: 1,
    sub2: 1,
    sub3: 1,
  });

  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);

  useEffect(() => {
    FieldsStore.addChangeListener(fieldsUpdated);

    switch (filters.searchType) {
      case userType.TALENT: {
        fieldsActions.getTalentFields();
        break;
      }
      case userType.FREELANCER: {
        fieldsActions.getFreelancerFields();
        break;
      }
      default: {
        fieldsActions.getFields();
        break;
      }
    }

    return () => {
      FieldsStore.removeChangeListener(fieldsUpdated);
    };
  }, [filters.searchType]);

  useEffect(() => {
    props.resetFilters();
  }, []);

  useEffect(() => {
    if (filters.field && fields.length > 0) {
      let currentField = getFieldById(filters.field);

      if (currentField) {
        setRoleOptions({ ...roleOptions, sub1: currentField.roles });
        setSelectedRole({ ...selectedRole, sub1: 1 });
      } else {
        setRoleOptions({ sub1: null, sub2: null, sub3: null });
      }
    }
  }, [filters.field]);

  function openMoreFilters() {
    setIsMoreFiltersOpen(true);
  }
  function closeMoreFilters() {
    setIsMoreFiltersOpen(false);
  }

  function getFieldById(fieldId) {
    return fields.find((f) => f.id === fieldId);
  }

  function fieldsUpdated() {
    let fields = getFields(filters.searchType);
    setFields(fields);
  }

  function getFields(type) {
    switch (type) {
      case userType.TALENT: {
        return FieldsStore.getTalentFields();
      }
      case userType.FREELANCER: {
        return FieldsStore.getFreelancerFields();
      }
      default: {
        return FieldsStore.getFields();
      }
    }
  }

  function handleChange({ target }) {
    if (target.name) {
      let newFilters = {
        ...filters,
        [target.name]: target.value || "",
        userType: currentUserType,
      };

      if (target.name === "field") {
        newFilters.subField = "";
      }

      filterActions.setFilters(newFilters);
    }
  }

  function handleSubRoleChange({ target }) {
    let selectedField = getFieldById(filters.field);
    handleChange({ target: { name: target.name, value: target.value } });

    switch (target.name) {
      // Case sub1 selected
      case "subField1": {
        if (target.value === 1) {
          setSelectedRole({ sub1: 1, sub2: 1, sub3: 1 });
          // Set the sub2 options
          setRoleOptions({
            ...roleOptions,
            sub2: null,
            sub3: null,
          });

          break;
        }

        // Find the selectedSub1 obj
        let selectedSub1Object = selectedField.roles.find(
          (f) => f.id === target.value
        );

        //Save the selected sub1 id
        setSelectedRole({ sub1: selectedSub1Object.id, sub2: 1, sub3: 1 });
        // Set the sub2 options
        setRoleOptions({
          ...roleOptions,
          sub2: selectedSub1Object?.roles,
          sub3: null,
        });

        break;
      }
      case "subField2": {
        if (target.value === 1) {
          setSelectedRole({ ...selectedRole, sub2: 1, sub3: 1 });
          // Set the sub2 options
          setRoleOptions({
            ...roleOptions,
            sub3: null,
          });

          break;
        }

        // Find the selectedSub2 obj
        let selectedSub2Object = roleOptions.sub2.find(
          (f) => f.id === target.value
        );

        //Save the selected sub2 id
        setSelectedRole({
          ...selectedRole,
          sub2: selectedSub2Object.id,
          sub3: 1,
        });
        // Set the sub3 options
        setRoleOptions({
          ...roleOptions,
          sub3: selectedSub2Object?.roles,
        });

        break;
      }
      case "subField3": {
        if (target.value === 1) {
          setSelectedRole({ ...selectedRole, sub3: 1 });

          break;
        }

        // Find the selectedSub3 obj
        let selectedSub3Object = roleOptions.sub3.find(
          (f) => f.id === target.value
        );

        //Save the selected sub3 id
        setSelectedRole({ ...selectedRole, sub3: selectedSub3Object.id });

        break;
      }
      default: {
        break;
      }
    }
  }

  let inputFilterStyleObj = {
    "& .MuiAutocomplete-input": {
      position: "relative",
      top: "-0.8em",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "19px",
      height: "2em",
    },
  };

  let locationFilterStyleObj = {
    "& .MuiInputLabel-root": {
      top: "-0.8em",
    },
    "& .MuiInputLabel-shrink": {
      top: 0,
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "inherit",
      }}
    >
      {!isMobile && (
        <Typography sx={{ mr: 2 }} variant="body2">
          Filter by:
        </Typography>
      )}
      {fields && (
        <>
          <FilterSet>
            <FieldFilter
              style={inputFilterStyleObj}
              fieldName={"Field"}
              name={"field"}
              handleChange={(e) => {
                setRoleOptions({ sub1: null, sub2: null, sub3: null });
                handleChange(e);
              }}
              fields={fields}
              selected={filters.field}
            />
          </FilterSet>
          {filters.field && roleOptions.sub1 && roleOptions.sub1.length > 0 && (
            <FilterSet isDisplayed={filters.searchType !== userType.BUSINESS}>
              <FieldFilter
                style={inputFilterStyleObj}
                fieldName={"Choose skill"}
                name={"subField1"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub1}
                selected={selectedRole.sub1}
              />
            </FilterSet>
          )}
        </>
      )}

      {filters.searchType === userType.PERSONAL && (
        <FilterSet>
          <LocationFilter
            style={{ ...inputFilterStyleObj, ...locationFilterStyleObj }}
            handleChange={handleChange}
          />
        </FilterSet>
      )}

      {filters.searchType === userType.TALENT && (
        <>
          {roleOptions && roleOptions.sub2 && roleOptions.sub2.length > 0 && (
            <>
              <FilterSet>
                <FieldFilter
                  style={inputFilterStyleObj}
                  fieldName={"Choose role"}
                  name={"subField2"}
                  handleChange={handleSubRoleChange}
                  fields={roleOptions.sub2}
                  selected={selectedRole.sub2}
                />
              </FilterSet>
              {roleOptions.sub2 &&
                roleOptions.sub3 &&
                roleOptions.sub3.length > 0 && (
                  <FilterSet>
                    <FieldFilter
                      style={inputFilterStyleObj}
                      fieldName={"Sub role"}
                      name={"subField3"}
                      handleChange={handleSubRoleChange}
                      fields={roleOptions.sub3}
                      selected={selectedRole.sub3}
                    />
                  </FilterSet>
                )}
            </>
          )}
          <FilterSet>
            <LocationFilter
              style={{ ...inputFilterStyleObj, ...locationFilterStyleObj }}
              handleChange={handleChange}
            />
          </FilterSet>
        </>
      )}
      {filters.searchType === userType.FREELANCER && (
        <>
          <FilterSet>
            <LocationFilter
              style={{ ...inputFilterStyleObj, ...locationFilterStyleObj }}
              handleChange={handleChange}
            />
          </FilterSet>
        </>
      )}
      {filters.searchType === userType.BUSINESS && (
        <FilterSet>
          <LocationFilter
            style={{ ...inputFilterStyleObj, ...locationFilterStyleObj }}
            handleChange={handleChange}
          />
        </FilterSet>
      )}
      {filters.searchType === searchType.JOB_SEARCH && (
        <FilterSet isDisplayed={filters.searchType === searchType.JOB_SEARCH}>
          <LocationFilter
            style={{ ...inputFilterStyleObj, ...locationFilterStyleObj }}
            handleChange={handleChange}
          />
        </FilterSet>
      )}

      {false && filters.searchType !== userType.FREELANCER && (
        <Button
          variant="outlined"
          color={"buttons"}
          sx={{ textTransform: "none", borderRadius: "19px" }}
          onClick={openMoreFilters}
        >
          <FilterAltOutlinedIcon color={props.userType || userType.TALENT} />
          <Typography variant="body2">More Filters</Typography>
        </Button>
      )}
      <Dialog onClose={closeMoreFilters} open={isMoreFiltersOpen}>
        <DialogTitle>More Filters</DialogTitle>
        <DialogContent dividers sx={{ width: 400, height: 600 }}>
          {filters.searchType === userType.PERSONAL && (
            <>
              <FilterSet sx={{ width: "100%" }}>
                <SalaryFilter
                  handleChange={handleChange}
                  from={filters.salaryFrom}
                  to={filters.salaryTo}
                />
              </FilterSet>

              <Divider sx={{ width: "100%", my: 4 }} />

              <FilterSet sx={{ width: "100%" }}>
                <RangeFilter
                  handleChange={handleChange}
                  from={filters.ageFrom}
                  to={filters.ageTo}
                  idFrom={"ageFrom"}
                  idTo={"ageTo"}
                  nameFrom={"ageFrom"}
                  nameTo={"ageTo"}
                  label={"Age range"}
                />
              </FilterSet>

              <Divider sx={{ width: "100%", my: 4 }} />

              <FilterSet sx={{ width: "100%" }}>
                <GenderFilter
                  handleChange={handleChange}
                  selected={filters.gender}
                />
              </FilterSet>

              <Divider sx={{ width: "100%", my: 4 }} />

              <FilterSet sx={{ width: "100%" }}>
                <RangeFilter
                  handleChange={handleChange}
                  from={filters.rankFrom}
                  to={filters.rankTo}
                  idFrom={"rankFrom"}
                  idTo={"rankTo"}
                  nameFrom={"rankFrom"}
                  nameTo={"rankTo"}
                  label={"Rank range"}
                />
              </FilterSet>
            </>
          )}
          {filters.searchType === userType.TALENT && (
            <>
              <FilterSet sx={{ width: "100%" }}>
                <RangeFilter
                  handleChange={handleChange}
                  from={filters.ageFrom}
                  to={filters.ageTo}
                  idFrom={"ageFrom"}
                  idTo={"ageTo"}
                  nameFrom={"ageFrom"}
                  nameTo={"ageTo"}
                  label={"Age range"}
                />
              </FilterSet>

              <Divider sx={{ width: "100%", my: 4 }} />

              <FilterSet sx={{ width: "100%" }}>
                <GenderFilter
                  handleChange={handleChange}
                  selected={filters.gender}
                />
              </FilterSet>
            </>
          )}

          {filters.searchType === userType.BUSINESS && (
            <FilterSet sx={{ width: "100%" }}>
              <RangeFilter
                handleChange={handleChange}
                from={filters.employeeAmountFrom}
                to={filters.employeeAmountTo}
                idFrom={"employeeAmountFrom"}
                idTo={"employeeAmountTo"}
                nameFrom={"employeeAmountFrom"}
                nameTo={"employeeAmountTo"}
                label={"Employee Amount"}
              />
            </FilterSet>
          )}
          {filters.searchType === searchType.JOB_SEARCH && (
            <>
              <FilterSet sx={{ width: "100%" }}>
                <SalaryFilter
                  handleChange={handleChange}
                  from={filters.salaryFrom}
                  to={filters.salaryTo}
                />
              </FilterSet>

              <Divider sx={{ width: "100%", my: 4 }} />

              <FilterSet sx={{ width: "100%" }}>
                <VacancyTypeFilter
                  handleChange={handleChange}
                  selected={filters.vacancyType}
                />
              </FilterSet>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeMoreFilters}
            variant={"outlined"}
            sx={{ borderRadius: "19px", textTransform: "none", my: 1, mr: 2 }}
            color={currentUserType}
          >
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

FiltersContainer.propTypes = {
  filters: PropTypes.any.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default FiltersContainer;
