import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import React, { useContext } from "react";
import { CONST } from "../../shared/consts";
import { Box, useTheme } from "@mui/material";
import CONF from "../../configurations/applicationConf";
import Button from "@mui/material/Button";
import { IsMobileContext } from "../../providers/IsMobileService";

export default function ImageButton(props) {
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  return (
    <Paper
      variant={"elevation"}
      elevation={0}
      onClick={() => props.handleClick(props.type)}
      sx={{
        backgroundColor: "#eee",
        backgroundImage: `url('${CONF.AWS_ACCESS_BUCKET_URL}/resources/images/homepage/${props.type}.image.button.jpeg')`,
        position: "relative",
        transition: theme.transitions.create("opacity"),

        mx: isMobile ? 0 : 1,
        mb: isMobile && 2,
        width: isMobile ? "100vw" : "21em",
        height: props.height || "25em",

        "&:hover .overlay": {
          transition: theme.transitions.create(["top"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.short,
          }),
          top: "40%",
        },
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        className={"overlay"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          position: "absolute",
          left: 0,
          right: 0,
          top: "50%",
          bottom: 0,
          transition: theme.transitions.create(["top"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.short,
          }),
        }}
      >
        <Box
          sx={{
            zIndex: 2,
            backgroundColor: theme.palette[props.type]?.main,
            opacity: 0.8,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        />
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} sx={{ color: "#fff", fontWeight: "bold" }}>
            {props.label}
          </Typography>
          <Typography
            variant={"subtitle1"}
            sx={{
              color: "#fff",
              width: "90%",
              fontWeight: "light",
              textAlign: "center",
            }}
          >
            {props.subtitle}
          </Typography>
        </Box>
        <Button
          color={"white"}
          sx={{
            zIndex: 3,
            color: "#fff",
            borderRadius: "19px",
            border: "#fff solid 2px",
            "&:hover": { borderWidth: "2px" },
          }}
          variant={"outlined"}
        >
          Start now
        </Button>
      </Box>
    </Paper>
  );
}
