import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function FilterSet(props) {
  return (
    props.isDisplayed && (
      <Box
        sx={
          props.sx || {
            mr: "1.5em",
            width: "17%",
          }
        }
      >
        {props.children}
      </Box>
    )
  );
}

FilterSet.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
};

FilterSet.defaultProps = {
  isDisplayed: true,
};

export default FilterSet;
