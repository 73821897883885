import React, { useEffect, useState } from "react";
import * as searchActions from "../../../actions/searchActions";
import { userType } from "../../../shared/userType";
import { searchType } from "../../../shared/searchType";
import FeedItem from "./FeedItem";
import CONF from "../../../configurations/applicationConf";
import { Box } from "@mui/material";
import * as reactionsActions from "../../../actions/reactionsActions";

export default function FeedComponent(props) {
  const { user, currentUserType, rest } = props;

  const [pagination, setPagination] = useState({
    main: { number: 0, size: 3 },
    secondary: { number: 0, size: 1 },
    entertainment: { number: 0, size: 2 },
  });
  const [feedItems, setFeedItems] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const [playingElem, setPlayingElem] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (feedItems.length === 0) {
      getFeed();
    } else {
      setIsLoading(false);
    }
  }, []);

  function getFeed() {
    /*
    sortBy: search.sortBy
    */
    let currentSearchType = getSearchTypeByUserType(currentUserType); // "job","personal"....
    let feedUpdate = [];

    let searcheRequests = [
      searchActions.search({
        pageSize: pagination.main.size,
        currentPage: pagination.main.number,
        searchType: currentSearchType[0],
        isVideoReq: true,
      }),
      searchActions.search({
        pageSize: pagination.secondary.size,
        currentPage: pagination.secondary.number,
        searchType: currentSearchType[1],
        isVideoReq: true,
      }),
      searchActions.search({
        pageSize: pagination.entertainment.size,
        currentPage: pagination.entertainment.number,
        searchType: currentSearchType[2],
        isVideoReq: true,
      }),
    ];

    Promise.all(searcheRequests).then((results) => {
      results.map((resArray, index) => {
        resArray.forEach((item) => {
          // For each result array use the currentSearchType and current index
          // to know what userType should be returned from the db
          switch (currentSearchType[index]) {
            case searchType.JOB_SEARCH: {
              feedUpdate.push({
                _id: item._id,
                video: `${CONF.AWS_ACCESS_BUCKET_URL}/videos/${item.video}#t=0.1`,
                entity: item,
                link: `view/vacancy/${item._id}`,
                type: currentSearchType[index],
                isPlaying: false,
              });

              break;
            }
            case userType.BUSINESS:
            case userType.PERSONAL:
            case userType.TALENT:
            case userType.FREELANCER: {
              feedUpdate.push({
                _id: item._id,
                video: `${CONF.AWS_ACCESS_BUCKET_URL}/videos/${
                  item[currentSearchType[index]].videos[0]
                }#t=0.1`,
                entity: item,
                link: `${currentSearchType[index]}/${item.username}`,
                type: currentSearchType[index],
                isPlaying: false,
              });

              break;
            }
          }
        });

        setFeedItems([...feedItems, ...feedUpdate]);
      });
    });
  }

  function getSearchTypeByUserType(type) {
    switch (type) {
      case userType.PERSONAL: {
        return [
          searchType.JOB_SEARCH,
          searchType.TALENT_SEARCH,
          searchType.FREELANCER_SEARCH,
        ];
      }
      case userType.TALENT: {
        return [
          searchType.JOB_SEARCH,
          searchType.TALENT_SEARCH,
          searchType.FREELANCER_SEARCH,
        ];
      }
      case userType.BUSINESS: {
        return [
          searchType.PERSONAL_SEARCH,
          searchType.TALENT_SEARCH,
          searchType.FREELANCER_SEARCH,
        ];
      }
      case userType.FREELANCER: {
        return [
          searchType.BUSINESS_SEARCH,
          searchType.TALENT_SEARCH,
          searchType.PERSONAL_SEARCH,
        ];
      }
    }
  }

  function handleMuteClick() {
    setIsMuted(!isMuted);
  }

  function isFavorite(item) {
    return user[currentUserType].favorites.some(
      (fav) => fav.favType === item.type && fav.favId === item._id
    );
  }

  function addOrRemoveFavorite(item) {
    reactionsActions.addOrRemoveFavorite(currentUserType, item.type, item._id);
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {feedItems.length > 0 &&
        feedItems.map((item) => (
          <FeedItem
            isFavorite={isFavorite(item)}
            handleToggleAddToFavorites={() => addOrRemoveFavorite(item)}
            key={`coll-${item._id}-${item.type}`}
            id={`coll-${item._id}-${item.type}`}
            elemId={`coll-${item._id}-${item.type}`}
            item={item}
            isMuted={isMuted}
            handleMuteClick={handleMuteClick}
            isPlaying={playingElem}
          />
        ))}
    </Box>
  );
}
