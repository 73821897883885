import React from "react";
import { makeStyles } from "@mui/styles";

export default function ApplicationSettingsForm() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      // flexDirection: "column",
      // flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const classes = useStyles();

  // TODO: create application wide settings like: mute all videos, language, other

  return (
    <div className={classes.root}>
      Application Settings will be available soon
    </div>
  );
}
