import * as vacancyApi from "../api/vacancyApi";
import * as userApi from "../api/userApi";

export function createVacancy(vacancy) {
  return vacancyApi
    .createVacancy(vacancy)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateVacancy(vacancy) {
  vacancy.updatedDate = new Date();

  return vacancyApi
    .updateVacancy(vacancy)
    .then((result) => {
      return result.vacancy;
    })
    .catch((error) => {
      throw error;
    });
}

export function getVacancyById(vacancyId) {
  return vacancyApi
    .getVacancyById(vacancyId)
    .then((result) => {
      return result.vacancy;
    })
    .catch((error) => {
      throw error;
    });
}

export function getVacanciesByIds(vacancyIdList) {
  return vacancyApi
    .getVacanciesByIds(vacancyIdList)
    .then((result) => {
      return result.vacancyList;
    })
    .catch((error) => {
      throw error;
    });
}

export function addVideo(videoNameToAdd, vacancyId) {
  return vacancyApi
    .addVideo(videoNameToAdd, vacancyId)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
}
