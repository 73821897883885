import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import "./styles.css";
import CONF from "../../../configurations/applicationConf";
import chatStore from "../../../stores/chatStore";
import * as chatActions from "../../../actions/chatActions";
import ChatModal from "../../Messages/ChatModal";
import { getUserById } from "../../../actions/userActions";
import * as userActions from "../../../actions/userActions";
import { Badge, Box, Chip, Paper, useTheme } from "@mui/material";
import ChatWindow from "../../Messages/ChatWindow";
import Link from "@mui/material/Link";
import { IsMobileContext } from "../../../providers/IsMobileService";

export default function MessageBoard(props) {
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  const [chatRoomDetails, setChatRoomDetails] = useState({});
  const [chats, setChats] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    loadChats();

    // Register to chat store updates to repopulate chats
    chatStore.addChangeListener(getChats);

    return () => {
      chatStore.removeChangeListener(getChats);
    };
  }, [props.currentUserType]);

  // TODO: change the function to send list of requested chats once instead one by one
  function loadChats() {
    props.currentUser[props.currentUserType].chats.forEach((chat) => {
      chatActions.getChatLogById(chat.chatId, chat.user);
    });
  }

  function getChats() {
    let chatsData = [];
    let userPromises = [];

    props.currentUser[props.currentUserType].chats.forEach((chat) => {
      let chatLogWithData = chatStore.getChatLogWithData(chat.chatId);

      if (chatLogWithData) {
        userPromises.push(userActions.getUserById(chatLogWithData.withUser.id));

        chatsData.push(chatLogWithData);
      }
    });

    Promise.all(userPromises).then((users) => {
      chatsData.forEach((chat) => {
        let user = users.find((x) => x._id === chat.withUser.id);

        // Setup all user data needed for message board display
        chat.withUser.name = user.firstName + " " + user.lastName || "";
        chat.withUser.image = user[chat.withUser.type].image;
      });

      setChats(
        chatsData.sort(
          (a, b) => new Date(b.lastMessage) - new Date(a.lastMessage)
        )
      );
    });
  }

  function handleOpenChat(chat) {
    setChatRoomDetails(chat);
    setIsChatOpen(true);
  }

  function handleCloseChat() {
    setChatRoomDetails({});
    setIsChatOpen(false);
  }

  function getAvatarForUser(id, type) {
    return chats.find(
      (chat) => chat.withUser.id === id && chat.withUser.type === type
    );
  }

  return (
    <>
      {!isMobile ? (
        <Paper
          elevation={2}
          sx={{
            width: "100%",
            height: "90vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              alignItems: "center",
              // height: "6em",
            }}
          >
            <Box sx={{ width: "34%" }}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontWeight: "bold", px: 2, py: 3 }}
              >
                All Messages
              </Typography>
            </Box>
            <Divider orientation={"vertical"} />
            <Box sx={{ width: "66%" }}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontWeight: "bold", px: 2, py: 3 }}
              >
                Chat
              </Typography>
            </Box>
          </Box>
          <Divider orientation={"horizontal"} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              height: "90vh",
            }}
          >
            {chats.length > 0 ? (
              <Box sx={{ width: "35%", overflow: "scroll" }}>
                <List>
                  {chats.map((chat) => (
                    <Box key={chat._id}>
                      <ListItem
                        onClick={() => handleOpenChat(chat)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "125px",
                          width: "100%",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "80%",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: "2.5em",
                              height: "2.5em",
                              border: `2px solid ${
                                theme.palette[chat.withUser.type].main
                              }`,
                              mr: 2,
                            }}
                            alt={chat.withUser.name}
                            src={
                              chat.withUser.image &&
                              `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${chat.withUser.image}`
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "75%",
                              pr: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box sx={{ width: "70%" }}>
                                <Typography noWrap color="textPrimary">
                                  {chat.withUser.name}
                                </Typography>
                              </Box>
                              <Typography
                                variant={"body2"}
                                sx={{ fontWeight: "100", color: "#777" }}
                              >
                                6 hours
                              </Typography>
                            </Box>
                            <Typography
                              noWrap
                              variant={"body2"}
                              sx={{
                                fontWeight: "100",
                                color: "#333",
                                mt: "1.5em",
                              }}
                            >
                              {chat.messages[chat.messages.length - 1].content}
                            </Typography>
                          </Box>
                        </Box>

                        {!chat.messages[0].isRead && (
                          <Chip label="unread" variant="outlined" clickable />
                        )}
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
                </List>
              </Box>
            ) : (
              <Box sx={{ width: "35%" }}>
                <Typography
                  sx={{ fontWeight: "bold", px: 2, py: 3, color: "#777" }}
                >
                  No conversations yet
                </Typography>
              </Box>
            )}
            <Divider orientation={"vertical"} />
            <Box sx={{ width: "65%", overflow: "scroll", px: 2, pt: 2 }}>
              {props.currentUser && props.currentUserType && isChatOpen && (
                <ChatWindow
                  // roomData={{
                  //   otherName: chatRoomDetails.withUser.name,
                  //   myName: props.currentUser.firstName,
                  // }}
                  chatLog={chatRoomDetails.messages}
                  currentUser={{
                    id: props.currentUser._id,
                    name: props.currentUser.firstName,
                    type: props.currentUserType,
                    avatarSrc: `${
                      CONF.AWS_ACCESS_BUCKET_URL
                    }/public/profile_images/${
                      props.currentUser[props.currentUserType].image
                    }`,
                  }}
                  receiver={{
                    id: chatRoomDetails.withUser.id,
                    name: chatRoomDetails.withUser.name,
                    type: chatRoomDetails.withUser.type,
                    // avatarSrc: `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${chat.withUser.image}`,
                    avatarSrc: getAvatarForUser(
                      chatRoomDetails.withUser.id,
                      chatRoomDetails.withUser.type
                    ),
                  }}
                />
              )}
            </Box>
          </Box>
        </Paper>
      ) : (
        <>
          {props.currentUser && props.currentUserType && isChatOpen && (
            <Box
              sx={{
                position: "absolute",
                backgroundColor: theme.palette.body.foreground,
                width: "calc(100vw - 2em)",
                height: `calc(100vh - ${theme.sizing.navBar.height} - 5em)`,
                overflow: "scroll",
                // padding: "2em 2em",
                px: "1em",
                zIndex: 2,
              }}
            >
              <ChatWindow
                handleClose={handleCloseChat}
                chatLog={chatRoomDetails.messages}
                currentUser={{
                  id: props.currentUser._id,
                  name: props.currentUser.firstName,
                  type: props.currentUserType,
                  avatarSrc: `${
                    CONF.AWS_ACCESS_BUCKET_URL
                  }/public/profile_images/${
                    props.currentUser[props.currentUserType].image
                  }`,
                }}
                receiver={{
                  id: chatRoomDetails.withUser.id,
                  name: chatRoomDetails.withUser.name,
                  type: chatRoomDetails.withUser.type,
                  // avatarSrc: `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${chat.withUser.image}`,
                  avatarSrc: getAvatarForUser(
                    chatRoomDetails.withUser.id,
                    chatRoomDetails.withUser.type
                  ),
                }}
              />
            </Box>
          )}
          {chats.length > 0 ? (
            <Box sx={{ overflow: "scroll", width: "100%" }}>
              <List>
                {chats.map((chat) => (
                  <Box key={chat._id}>
                    <ListItem
                      onClick={() => handleOpenChat(chat)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "125px",
                        width: "100%",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "2.5em",
                            height: "2.5em",
                            border: `2px solid ${
                              theme.palette[chat.withUser.type].main
                            }`,
                            mr: 2,
                          }}
                          alt={chat.withUser.name}
                          src={
                            chat.withUser.image &&
                            `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${chat.withUser.image}`
                          }
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "75%",
                            pr: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ width: "70%" }}>
                              <Typography noWrap color="textPrimary">
                                {chat.withUser.name}
                              </Typography>
                            </Box>
                            <Typography
                              variant={"body2"}
                              sx={{ fontWeight: "100", color: "#777" }}
                            >
                              6 hours
                            </Typography>
                          </Box>
                          <Typography
                            noWrap
                            variant={"body2"}
                            sx={{
                              fontWeight: "100",
                              color: "#333",
                              mt: "1.5em",
                            }}
                          >
                            {chat.messages[chat.messages.length - 1].content}
                          </Typography>
                        </Box>
                      </Box>

                      {!chat.messages[0].isRead && (
                        <Chip label="unread" variant="outlined" clickable />
                      )}
                    </ListItem>
                    <Divider />
                  </Box>
                ))}
              </List>
            </Box>
          ) : (
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Typography
                sx={{ fontWeight: "bold", px: 2, py: 3, color: "#777" }}
              >
                No conversations yet
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}
