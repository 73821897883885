import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AvatarEditor from "react-avatar-editor";
import { makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";

export default function RepositionDialog(props) {
  const useStyles = makeStyles((theme) => ({
    dialog: {
      width: "100vw",
      maxWidth: "800px",
    },
    content: {
      display: "flex",
      justifyContent: "space-around",
    },
  }));
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  function handleScaleChange(event, newValue) {
    setScale(newValue);
  }

  const editor = useRef(null);

  function onClickSave() {
    if (editor) {
      const canvas = editor.current.getImage();
      canvas.toBlob((blob) => {
        props.handleSave(
          new File([blob], props.imageFileOrSrc.name, {
            type: props.imageFileOrSrc.type,
          })
        );
      }, props.imageFileOrSrc.type);
    }

    props.handleClose();
  }

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.dialogTitleText || "Edit Profile Image"}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div
          style={{
            width: props.selectProps.modalWidth,
            aspectRatio: props.selectProps.aspectRatio,
          }}
        >
          <AvatarEditor
            ref={editor}
            image={props.imageFileOrSrc}
            borderRadius={props.selectProps?.radius}
            width={props.selectProps?.width}
            height={props.selectProps?.height}
            scale={scale}
            position={position}
            // disableBoundaryChecks - {Boolean} - Set to true to allow the image to be moved outside the cropping boundary.
            onPositionChange={(newPosition) => {
              setPosition(newPosition);
            }}
          />
          <Slider
            aria-label="Scale"
            value={scale}
            onChange={handleScaleChange}
            min={0.4}
            max={5}
            step={0.05}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          {props.rejectButtonText || "Cancel"}
        </Button>
        <Button onClick={onClickSave} color="primary" autoFocus>
          {props.acceptButtonText || "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
