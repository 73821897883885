import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { CONST } from "../../shared/consts";
import Paper from "@mui/material/Paper";
import * as vacancyActions from "../../actions/vacancyActions";
import { ButtonGroup, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import VideoManage from "../common/VideoManage/VideoManage";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import {
  Chat,
  Favorite,
  FavoriteBorder,
  PinDrop,
  ShareOutlined,
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import userStore from "../../stores/userStore";
import Tooltip from "@mui/material/Tooltip";
import * as reactionsActions from "../../actions/reactionsActions";
import { searchType } from "../../shared/searchType";
import CONF from "../../configurations/applicationConf";
import ChatModal from "../Messages/ChatModal";
import { userType } from "../../shared/userType";
import TitleAndText from "../common/Text/TitleAndText";
import MetaTags from "react-meta-tags";
import ShareDialog from "../ShareDialog/ShareDialog";

function VacancyViewPage(props) {
  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      maxWidth: "1440px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.up(CONST.LG)]: {
        padding: "2em 3em",
        // width: "50%",
      },
      [theme.breakpoints.down(CONST.LG)]: {
        width: "90%",
      },
    },
    header: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    info: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      width: "30%",
      height: "100%",
      justifyContent: "space-around",
    },
    infoDetails: { display: "flex", flexDirection: "column" },
    companyInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%",
      maxHeight: "3em",
      marginBottom: "1em",
      cursor: "pointer",
    },
    companyLogoContainer: {
      width: "25%",
    },
    companyLogo: {
      width: "3em",
      height: "3em",
    },
    companyLogoSkeleton: {
      width: "4em",
      height: "6em",
      borderRadius: "50%",
    },
    companyNameContainer: {
      width: "70%",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    vacancyNumber: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      width: "100%",
    },
    jobTitle: {},
    jobLocation: {
      display: "flex",
      alignItems: "center",
    },
    jobLocationIcon: {
      fontSize: "1em",
      marginRight: ".5em",
    },
    actionButtons: {},
    shareContainer: {
      bottom: 95,
      position: "absolute",
      width: "100%",
    },
    video: {
      width: "68%",
      height: "auto",
    },
    body: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      "& > *": {
        marginBottom: ".5em",
      },
    },
    footer: {},
  }));
  const classes = useStyles();
  const history = useHistory();
  const vacancyId = props.match?.params.vacancyId;
  const [vacancy, setVacancy] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [chatOptions, setChatOptions] = useState({
    isOpen: false,
    presetMessage: "",
  });
  const [isShareOpen, setIsShareOpen] = useState(false);

  const subtitle2 = "1.2em";

  const [currentUser, setCurrentUser] = useState(userStore.getCurrentUser());
  const [currentUserType] = useState(userStore.getCurrentType());

  useEffect(() => {
    if (!vacancy) {
      vacancyActions
        .getVacancyById(vacancyId)
        .then((vacancy) => setVacancy(vacancy))
        .catch((error) => {
          history.push(`/view/vacancy/error`);
        });
    } else {
      setIsLoading(false);
    }

    userStore.addChangeListener(userChanged);
    return () => userStore.removeChangeListener(userChanged);
  }, [vacancy]);

  function userChanged() {
    setCurrentUser(userStore.getCurrentUser());
  }

  function handleCompanyView() {
    history.push(`/business/${vacancy.publisherId}`);
  }

  function addOrRemoveFavorite() {
    reactionsActions.addOrRemoveFavorite(
      currentUserType,
      searchType.JOB_SEARCH,
      vacancy._id
    );
  }

  function isHeartFull() {
    return (
      !isLoading &&
      currentUser &&
      currentUserType &&
      currentUser[currentUserType].favorites.find(
        (job) =>
          job.favId === vacancy._id && job.favType === searchType.JOB_SEARCH
      )
    );
  }

  function handleApply() {
    setChatOptions({
      isOpen: true,
      presetMessage: `Hi, my name is ${currentUser.firstName} ${
        currentUser.lastName || ""
      },
      I would like you to check out my profile and see if I'll suit you for vacancy number: ${
        vacancy.number
      },
      Thank you!`,
    });
  }

  function handleCloseChat() {
    setChatOptions({ isOpen: false, presetMessage: "" });
  }
  function handleOpenChat() {
    setChatOptions({ ...chatOptions, isOpen: true });
  }

  function handleAction(cb) {
    if (currentUser !== null) {
      cb();
    } else {
      //TODO: open modal with options to login or register
    }
  }

  return (
    <Paper className={classes.mainContainer}>
      {/*<MetaTags>*/}
      {/*  <title>{`${vacancy.name} ${vacancy.companyName} | GoGeThem`}</title>*/}
      {/*  {vacancy.companyLogo && (*/}
      {/*    <meta*/}
      {/*      property="og:image"*/}
      {/*      content={`${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${vacancy.companyLogo}`}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</MetaTags>*/}
      {!isLoading && currentUser && (
        <ChatModal
          options={chatOptions}
          handleClose={handleCloseChat}
          roomName={vacancy.companyName}
          currentUser={{ id: currentUser._id, type: currentUserType }}
          receiver={{ id: vacancy.publisherId, type: userType.BUSINESS }}
        />
      )}
      <Button onClick={history.goBack}>back</Button>
      <div className={classes.header}>
        <div className={classes.info}>
          <div className={classes.infoDetails}>
            <div className={classes.companyInfo} onClick={handleCompanyView}>
              <div className={classes.companyLogoContainer}>
                {isLoading ? (
                  <Skeleton className={classes.companyLogoSkeleton} />
                ) : (
                  <Avatar
                    className={classes.companyLogo}
                    src={`${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${vacancy.companyLogo}`}
                  />
                )}
              </div>
              <div className={classes.companyNameContainer}>
                <Typography variant={"h6"}>
                  {isLoading ? (
                    <Skeleton style={{ width: "90%" }} />
                  ) : (
                    vacancy.companyName
                  )}
                </Typography>
              </div>
            </div>
            <div className={classes.vacancyNumber}>
              <Typography variant={"subtitle2"} style={{ marginRight: "1em" }}>
                Job ID:
              </Typography>
              <Typography variant={"body2"}>
                {isLoading ? (
                  <Skeleton style={{ width: "5em" }} />
                ) : (
                  vacancy.number
                )}
              </Typography>
            </div>
            <div className={classes.jobTitle}>
              <Typography variant={"h6"}>
                {isLoading ? (
                  <Skeleton style={{ width: "13em" }} />
                ) : (
                  vacancy.name
                )}
              </Typography>
            </div>
            <Typography variant={"subtitle2"} className={classes.jobLocation}>
              <PinDrop className={classes.jobLocationIcon} />
              {isLoading ? (
                <Skeleton style={{ width: "18em" }} />
              ) : (
                vacancy.address
              )}
            </Typography>
          </div>
          <div className={classes.actionButtons}>
            <ButtonGroup
              color="primary"
              aria-label="large outlined primary button group"
            >
              {currentUser && (
                <Tooltip title={"Apply to this job"} placement="bottom" arrow>
                  <Button onClick={handleApply}>Apply</Button>
                </Tooltip>
              )}
              {currentUser && (
                <Tooltip
                  title={
                    isHeartFull()
                      ? "Remove from favorites"
                      : "Save to favorites"
                  }
                  placement="bottom"
                  arrow
                >
                  <Button onClick={() => handleAction(addOrRemoveFavorite)}>
                    {isHeartFull() ? <Favorite /> : <FavoriteBorder />}
                  </Button>
                </Tooltip>
              )}
              <Tooltip title={"Share"} placement="bottom" arrow>
                <Button onClick={() => setIsShareOpen(true)}>
                  <ShareOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={"Have a question?"} placement="bottom" arrow>
                <Button onClick={() => handleAction(handleOpenChat)}>
                  <Chat />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </div>
          <div className={classes.shareContainer}>
            {!isLoading && (
              <ShareDialog
                isOpen={isShareOpen}
                handleClose={() => setIsShareOpen(false)}
                pathname={window.location.pathname}
              />
            )}
          </div>
        </div>

        <div className={classes.video}>
          {isLoading ? (
            <Skeleton style={{ width: "100%", height: "500px" }} />
          ) : (
            <VideoManage
              isEdit={false}
              isVacancyVideo
              videos={[vacancy.video]}
            />
          )}
        </div>
      </div>

      {!isLoading && (
        <div className={classes.body}>
          <TitleAndText
            titleVariant={"subtitle2"}
            titleSize={subtitle2}
            title={"About"}
            textVariant={"body1"}
            style={{ whiteSpace: "pre-line" }}
            text={vacancy.about}
          />
          <TitleAndText
            titleVariant={"subtitle2"}
            titleSize={subtitle2}
            title={"Requirements"}
            textVariant={"body1"}
            style={{ whiteSpace: "pre-line" }}
            text={vacancy.requirements}
          />
        </div>
      )}
    </Paper>
  );
}

export default VacancyViewPage;
