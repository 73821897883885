import { EventEmitter } from "events";
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";

const CHANGE_EVENT = "CHANGE";
let _chats = [];

class chatStore extends EventEmitter {
  addChangeListener(cb) {
    this.on(CHANGE_EVENT, cb);
  }

  removeChangeListener(cb) {
    this.removeListener(CHANGE_EVENT, cb);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  //-------------------------------------
  getChatLogWithData(chatId) {
    return _chats.find((chat) => chat._id === chatId);
  }

  getChatLog(userId, userType) {
    let result = _chats.find(
      (chat) => chat.withUser.id === userId && chat.withUser.type === userType
    );

    return result?.messages || [];
  }
}

const store = new chatStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.UPDATE_CHAT_LOG: {
      if (action.chat?.messages) {
        let updatedChat = {
          ...action.chat,
          withUser: action.withUser,
        };

        let chatIndex = _chats.findIndex(
          (chat) => chat._id === action.chat._id
        );

        if (chatIndex !== -1) {
          // Update the chat at the store list
          _chats[chatIndex] = updatedChat;
        } else {
          _chats.push(updatedChat);
        }

        store.emitChange();
      }
      break;
    }
    default:
    // Nothing to do
  }
});

export default store;
