import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertModal(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleReject}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.dialogTitleText || "Info"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.messageText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleApprove} color="primary" autoFocus>
          {props.buttonText || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
