import React from "react";
import PropTypes from "prop-types";
import { Box, FormLabel, TextField } from "@mui/material";

function RangeFilter(props) {
  return (
    <>
      <FormLabel>{props.label}</FormLabel>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: ".5em",
        }}
      >
        <TextField
          sx={{
            width: "48%",
          }}
          size="small"
          id={props.idFrom}
          name={props.nameFrom}
          onChange={props.handleChange}
          label="From"
          variant="outlined"
          value={props.from}
          type={"number"}
        />
        <TextField
          sx={{
            width: "48%",
          }}
          size="small"
          id={props.idTo}
          name={props.nameTo}
          onChange={props.handleChange}
          label="To"
          variant="outlined"
          value={props.to}
          type={"number"}
        />
      </Box>
    </>
  );
}

RangeFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  nameFrom: PropTypes.string.isRequired,
  idFrom: PropTypes.string.isRequired,
  nameTo: PropTypes.string.isRequired,
  idTo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default RangeFilter;
