import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function GenderFilter(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
  }));
  const classes = useStyles();

  const selectedGender = props.selected || "";

  return (
    <FormControl component="fieldset" className={classes.root}>
      <InputLabel id="gender-select-label">Gender</InputLabel>
      <Select
        labelId="gender-select-label"
        label={"Gender"}
        id="gender-select"
        name={"gender"}
        value={selectedGender}
        onChange={props.handleChange}
      >
        <MenuItem value={"male"} key={1}>
          Male
        </MenuItem>
        <MenuItem value={"female"} key={2}>
          Female
        </MenuItem>
        <MenuItem value={"other"} key={3}>
          Other
        </MenuItem>
      </Select>
    </FormControl>
  );
}

GenderFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default GenderFilter;
