import React, { useEffect, useState } from "react";
import AddVacancyFab from "../VacancyManage/AddVacancyFab";
import userStore from "../../stores/userStore";
import { userType } from "../../shared/userType";

function Fabs() {
  const [currentUserType, setCurrentUserType] = useState(
    userStore.getCurrentType()
  );

  useEffect(() => {
    userStore.addChangeListener(userChangeListener);

    return () => {
      userStore.removeChangeListener(userChangeListener);
    };
  }, []);

  function userChangeListener() {
    setCurrentUserType(userStore.getCurrentType());
  }

  return (
    <>{currentUserType === userType.BUSINESS && false && <AddVacancyFab />}</>
  );
}

export default Fabs;
