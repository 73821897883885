import React, { useContext, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

// third party
import { useForm } from "react-hook-form";

// project imports
import * as userActions from "../../actions/userActions";
import { Auth } from "aws-amplify";
import SignInWithGoogle from "./SignInWithGoogleButton/SignInWithGoogle";
import { userType } from "../../shared/userType";
import AlertModal from "../common/AlertModal/AlertModal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TypeChooseDialog from "./TypeChooseDialog";
import { IsMobileContext } from "../../providers/IsMobileService";

// ===========================|| FIREBASE - REGISTER ||=========================== //
const RegistrationPage = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useContext(IsMobileContext);

  const [alertModalState, setAlertModalState] = useState({ isOpen: false });
  const [showPassword, setShowPassword] = useState(false);
  const [chosenType, setChosenType] = useState(location.state?.type || "");

  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();

  const { register, handleSubmit, errors } = useForm();

  function onSubmit(userData) {
    userData = {
      ...userData,
      userType: [chosenType],
      personal: {},
      talent: {},
      freelancer: {},
      business: {},
    };

    try {
      Auth.signUp({
        attributes: {
          email: userData.email,
          // phone_number: "+" + user.phone.replace(/\D/g, ""),
          name: userData.firstName,
          family_name: userData.lastName,
          // address: user.address,
        },
        username: userData.email,
        password: userData.password,
      })
        .then((awsUser) => {
          userData = {
            ...userData,
            userType: [chosenType],
            personal: {},
            talent: {},
            freelancer: {},
            business: {},
          };

          // Save the user to mongo
          userActions
            .saveUser(userData)
            .then(() => {
              setAlertModalState({
                dialogTitleText: "Success",
                messageText: `Please confirm your email address`,
                isOpen: true,
                handleReject: () => {
                  history.push("/");
                  setAlertModalState({ isOpen: false });
                },
                handleApprove: () => {
                  history.push("/");
                  setAlertModalState({ isOpen: false });
                },
              });
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          switch (e.code) {
            case "UsernameExistsException": {
              errors.email = {
                message: "An account with the given email already exists",
              };

              break;
            }
            default: {
              break;
            }
          }
        });
    } catch (error) {
      console.log("error signing up:", error);
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };

  function handleTypeChange({ target }) {
    setChosenType(target.value);
  }

  function handleTypeChoose(type) {
    setChosenType(type);
  }
  function handleTypeChooseReject() {
    history.goBack();
  }

  // has number
  const hasNumber = (number) => new RegExp(/[0-9]/).test(number);
  // has mix of small and capitals
  const hasMixed = (number) =>
    new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);
  // has special chars
  const hasSpecial = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);
  // set color based on password strength
  const strengthColor = (count) => {
    if (count < 2) return { label: "Poor", color: "#f44336" };
    if (count < 3) return { label: "Weak", color: "#ffc107" };
    if (count < 4) return { label: "Normal", color: "#ffab91" };
    if (count < 5) return { label: "Good", color: "#00e676" };
    if (count < 6) return { label: "Strong", color: "#00c853" };
    return { label: "Poor", color: "#f44336" };
  };
  // password strength indicator
  const strengthIndicator = (number) => {
    let strengths = 0;
    if (number.length > 5) strengths += 1;
    if (number.length > 7) strengths += 1;
    if (hasNumber(number)) strengths += 1;
    if (hasSpecial(number)) strengths += 1;
    if (hasMixed(number)) strengths += 1;
    return strengths;
  };

  return (
    <Box
      sx={{
        pt: !isMobile && "3em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AlertModal {...alertModalState} />
      <TypeChooseDialog
        isOpen={!chosenType}
        handleClick={handleTypeChoose}
        handleReject={handleTypeChooseReject}
      />
      <Box
        sx={{
          width: isMobile ? "80%" : 400,
          backgroundColor: "#fff",
          px: "3em",
          py: !isMobile && "4em",
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Sign up</Typography>
        </Box>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <Typography
              variant={"subtitle2"}
              sx={{ color: theme.palette.body.lightText, pb: 1 }}
            >
              Who are you?
            </Typography>
            <Select
              sx={{ mb: 3 }}
              name={"type"}
              id="type"
              color={chosenType || "personal"}
              value={chosenType}
              onChange={handleTypeChange}
            >
              <MenuItem value={userType.PERSONAL}>{"Personal"}</MenuItem>
              <MenuItem value={userType.TALENT}>{"Talent"}</MenuItem>
              <MenuItem value={userType.BUSINESS}>{"Business"}</MenuItem>
              <MenuItem value={userType.FREELANCER}>{"Freelancer"}</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box component={"span"}>
              <Typography
                variant={"subtitle2"}
                sx={{ color: theme.palette.body.lightText, pb: 1 }}
              >
                First Name
              </Typography>
              <TextField
                name={"firstName"}
                onChange={props.handleChange}
                placeholder="Your name"
                margin="none"
                variant="outlined"
                color={chosenType || "personal"}
                sx={{ mb: 3 }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Enter your first name address to continue",
                  },
                })}
              />
            </Box>
            <Box component={"span"}>
              <Typography
                variant={"subtitle2"}
                sx={{ color: theme.palette.body.lightText, pb: 1 }}
              >
                Last Name
              </Typography>
              <TextField
                name={"lastName"}
                onChange={props.handleChange}
                placeholder="Family name"
                margin="none"
                variant="outlined"
                color={chosenType || "personal"}
                sx={{ mb: 3 }}
                inputRef={register()}
              />
            </Box>
          </Box>

          <FormControl fullWidth error={!!errors.email}>
            <Typography
              variant={"subtitle2"}
              sx={{ color: theme.palette.body.lightText, pb: 1 }}
            >
              Email address
            </Typography>
            <TextField
              id="outlined-adornment-email-register"
              type="email"
              name="email"
              placeholder="Your email"
              margin="none"
              variant="outlined"
              color={chosenType || "personal"}
              sx={{ mb: 3 }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Enter your email address to continue",
                },
                pattern: {
                  value: /^([a-zA-Z0-9])(([\-.]|[_]+)?([a-zA-Z0-9]+))*(@)([a-zA-Z0-9])((([-]+)?([a-zA-Z0-9]+))?)*((.[a-zA-Z]{2,3})?(.[a-zA-Z]{2,6}))$/,
                  message: "Enter a valid email",
                },
              })}
            />
            {!!errors.email && (
              <FormHelperText error id="standard-weight-helper-text--register">
                {errors.email.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth error={!!errors.password}>
            <Typography
              variant={"subtitle2"}
              sx={{ color: theme.palette.body.lightText, pb: 1 }}
            >
              Password
            </Typography>
            <TextField
              id="outlined-adornment-password-register"
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Choose password"
              margin="none"
              variant="outlined"
              color={chosenType || "personal"}
              sx={{ mb: 3 }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Enter your password to continue",
                },
                minLength: {
                  value: 8,
                  message: "Choose a strong password",
                },
              })}
              onChange={(e) => {
                changePassword(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {!!errors.password && (
              <FormHelperText
                error
                id="standard-weight-helper-text-password-register"
              >
                {errors.password.message}
              </FormHelperText>
            )}
          </FormControl>

          {strength !== 0 && (
            <FormControl fullWidth>
              <Box sx={{ mb: 3 }}>
                <Box
                  style={{ backgroundColor: level?.color }}
                  sx={{ width: 85, height: 8, borderRadius: 7 }}
                />
                <Typography variant="subtitle1" fontSize="0.75rem">
                  {level?.label}
                </Typography>
              </Box>
            </FormControl>
          )}

          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                size={"large"}
                color={chosenType || "personal"}
                inputRef={register({
                  required: {
                    value: true,
                    message: "You must agree with the terms to continue",
                  },
                })}
                name="terms"
              />
            }
            label={
              <Typography variant="subtitle1">
                Agree with{" "}
                <Typography variant="subtitle1" component={Link} to="#">
                  Terms & Conditions.
                </Typography>
              </Typography>
            }
          />
          {!!errors.terms && (
            <FormHelperText error id="terms-register">
              {errors.terms.message}
            </FormHelperText>
          )}
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color={chosenType || "personal"}
              sx={{
                borderRadius: 19,
              }}
            >
              Sign up
            </Button>
          </Box>
        </form>

        <Box sx={{ alignItems: "center", display: "flex" }}>
          <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
          <Button
            variant="outlined"
            sx={{
              cursor: "unset",
              m: 2,
              py: 0.5,
              px: 7,
              borderColor: `${theme.palette.grey[100]} !important`,
              color: `${theme.palette.grey[900]}!important`,
              fontWeight: 500,
            }}
            disableRipple
            disabled
          >
            OR
          </Button>
          <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
        </Box>
        <SignInWithGoogle signInOrUp={"Sign up"} />
      </Box>
    </Box>
  );
};

export default RegistrationPage;
