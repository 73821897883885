import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import ImageButton from "../HomePage/ImageButton";
import Typography from "@mui/material/Typography";

export default function TypeChooseDialog(props) {
  return (
    <Dialog
      maxWidth={"lg"}
      open={props.isOpen}
      onClose={props.handleReject}
      aria-labelledby="type-choose-dialog"
      aria-describedby="type-choose-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: "4em",
          mb: "1em",
        }}
        id="type-choose-dialog-title"
      >
        <Typography variant={"h5"}>Sign Up</Typography>
        <Typography variant={"h6"} sx={{ fontWeight: "300", mt: 1 }}>
          Who are you?
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          mb: "7em",
          mx: "1em",
          display: "flex",
        }}
      >
        <ImageButton
          height={"18em"}
          type={"business"}
          label={"Business"}
          subtitle={"Lorem ipsum dolor bus"}
          handleClick={props.handleClick}
        />
        <ImageButton
          height={"18em"}
          type={"personal"}
          label={"Personal"}
          subtitle={"Lorem ipsum dolor amet"}
          handleClick={props.handleClick}
        />
        <ImageButton
          height={"18em"}
          type={"freelancer"}
          label={"Freelancer"}
          subtitle={"Lorem ipsum dolor sit"}
          handleClick={props.handleClick}
        />
        <ImageButton
          height={"18em"}
          type={"talent"}
          label={"Talent"}
          subtitle={"Lorem ipsum dolor tal"}
          handleClick={props.handleClick}
        />
      </DialogContent>
    </Dialog>
  );
}
