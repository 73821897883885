import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { userType } from "../../shared/userType";
import TitleAndText from "../common/Text/TitleAndText";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Public,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FieldsStore from "../../stores/FieldsStore";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { IsMobileContext } from "../../providers/IsMobileService";

function BusinessInfo(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [user, setUser] = useState(props.user);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  function handleAction(cb) {
    if (props.isLoggedIn) {
      cb();
    } else {
      //TODO: open modal with options to login or register
    }
  }

  function getSocialIcon(socialNetwork) {
    switch (socialNetwork) {
      case "Facebook":
        return <Facebook sx={{ mr: 1 }} />;
      case "Instagram":
        return <Instagram sx={{ mr: 1 }} />;
      case "YouTube":
        return <YouTube sx={{ mr: 1 }} />;
      case "LinkedIn":
        return <LinkedIn sx={{ mr: 1 }} />;
      case "Twitter":
        return <Twitter sx={{ mr: 1 }} />;
      default:
        return <Public sx={{ mr: 1 }} />;
    }
  }

  return (
    <Box>
      {(user[userType.BUSINESS].about ||
        user[userType.BUSINESS].field ||
        user[userType.BUSINESS].employeeAmount ||
        user[userType.BUSINESS].website) && (
        <>
          <Typography variant={"h5"} sx={{ mb: 2 }}>
            About the Company
          </Typography>
          <Typography
            variant={"body1"}
            sx={{ color: theme.palette.body.text, lineHeight: 1.56 }}
          >
            {user[userType.BUSINESS].about}
          </Typography>

          <Divider variant={"middle"} light sx={{ my: 4 }} />

          <Box
            name={"personal-details"}
            sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
          >
            <Box sx={{ width: isMobile ? "100%" : "50%" }}>
              <Typography variant={"h5"}>Professional Field</Typography>

              {user[userType.BUSINESS].field && (
                <TitleAndText
                  title={"Company Field"}
                  text={
                    FieldsStore.getPersonalFieldById(
                      user[userType.BUSINESS].field
                    )?.name
                  }
                />
              )}
              <TitleAndText
                title={"Employee Amount"}
                text={user[userType.BUSINESS].employeeAmount}
              />
              {user[userType.BUSINESS].isDisplayed.website && (
                <TitleAndText
                  title={"Website"}
                  type={"website"}
                  text={user[userType.BUSINESS].website}
                />
              )}
            </Box>

            <Box sx={{ width: isMobile ? "100%" : "50%" }}>
              <Typography variant={"h5"}>Social Presence</Typography>

              {user[userType.BUSINESS].isDisplayed.socialNetworks &&
                user[userType.BUSINESS].socialNetworks.map((network) => {
                  if (!network.url) return;
                  return (
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      key={network.id}
                    >
                      {getSocialIcon(network.type)}
                      <TitleAndText
                        title={network.type}
                        text={network.url}
                        type={"socialNetwork"}
                      />
                    </Box>
                  );
                })}
              {user[userType.BUSINESS].socialNetworks.length < 1 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant={"body1"}
                    sx={{
                      color: theme.palette.body.lightText,
                      lineHeight: "2.7em",
                      fontWeight: 300,
                    }}
                  >
                    No social presence yet
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Typography variant={"h5"}>Contact Details</Typography>

      <TitleAndText
        title={"Name"}
        text={`${user.firstName} ${user.lastName || ""}`}
      />
      <TitleAndText
        title={"Email"}
        type={"email"}
        text={`${
          user[userType.BUSINESS].isDisplayed.email ? user.email : "Hidden"
        }`}
      />
      <TitleAndText
        title={"Phone"}
        text={`${
          user[userType.BUSINESS].isDisplayed.phone ? user.phone : "Hidden"
        }`}
      />
      <TitleAndText
        title={"Address"}
        text={`${
          user[userType.BUSINESS].isDisplayed.address ? user.address : "Hidden"
        }`}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  infoRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid #eee",
    padding: "1em",
    marginBottom: "1em",
    boxShadow: "1px 1px 2px #ddd, 2px 2px 4px #aaa",
    backgroundColor: "#fff",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

BusinessInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default BusinessInfo;
