import { handleResponse, handleError } from "./apiUtils";
import CONF from "../configurations/applicationConf";
const baseUrl = CONF.API_URL + "/fields/";

export function getFields() {
  return fetch(baseUrl + "getFields")
    .then(handleResponse)
    .catch(handleError);
}

export function getTalentFields() {
  return fetch(baseUrl + "getTalentFields")
    .then(handleResponse)
    .catch(handleError);
}

export function getFreelancerFields() {
  return fetch(baseUrl + "getFreelancerFields")
    .then(handleResponse)
    .catch(handleError);
}
