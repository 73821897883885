import { handleResponse, handleError } from "./apiUtils";
import CONF from "../configurations/applicationConf";
import { Auth } from "aws-amplify";
const baseUrl = CONF.API_URL + "/reactions/";

export async function addOrRemoveFavorite(userType, favoriteType, id) {
  const actionUrl = "addOrRemoveFavorite/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({ userType, favoriteType, id }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getFavorites(userType, pagination) {
  const actionUrl = "getFavorites/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;
  const params = `?type=${userType}&pageNum=${pagination.number}&pageSize=${pagination.size}`;

  return fetch(baseUrl + actionUrl + params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}
