export default {
  LOAD_FIELDS: "LOAD_FIELDS",
  LOAD_TALENT_FIELDS: "LOAD_TALENT_FIELDS",
  LOAD_FREELANCER_FIELDS: "LOAD_FREELANCER_FIELDS",
  UPDATE_USER: "UPDATE_USER",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  TYPE_CHANGE: "TYPE_CHANGE",
  DEFAULT_USER_TYPE: "DEFAULT_USER_TYPE",

  TOGGLE_MUTE_VIDEO: "TOGGLE_MUTE_VIDEO",

  CREATE_VACANCY: "CREATE_VACANCY",

  UPDATE_FILTERS: "UPDATE_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",

  UPDATE_RESULTS: "UPDATE_RESULTS",
  SET_RESULTS: "SET_RESULTS",

  UPDATE_CHAT_LOG: "UPDATE_CHAT_LOG",
};
