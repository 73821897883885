export const CONST = {
  PARENTAL_APPROVAL_AGE: 16,
  TEXT_FIELD_MAX_LENGTH: 5000,
  MSG_DURATION: 7 * 1000,

  SM: 600,
  MD: 960,
  LG: 1280,
  XL: 1920,

  USER_COLORS: {
    talent: "#ba68c8",
    personal: "#e63a46",
    business: "#ff9800",
    freelancer: "#fdd835",
  },
};
