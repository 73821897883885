import { EventEmitter } from "events";
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import { searchType as searchTypes } from "../shared/searchType";

const CHANGE_EVENT = "CHANGE";
const EMPTY_SEARCH_FILTERS = {
  pageSize: 15,
  currentPage: 0,
  searchQuery: "",
  salaryFrom: "",
  salaryTo: "",
  ageFrom: "",
  ageTo: "",
  rankFrom: "",
  rankTo: "",
  userType: "",
  field: 1,
  subField: "",
  searchType: searchTypes.TALENT_SEARCH,
  skills: [],
};

let _currentFilters = EMPTY_SEARCH_FILTERS;

class filterStore extends EventEmitter {
  addChangeListener(cb) {
    this.on(CHANGE_EVENT, cb);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  removeChangeListener(cb) {
    this.removeListener(CHANGE_EVENT, cb);
  }

  //-------------------------------------
  getCurrentFilters() {
    return _currentFilters;
  }

  getEmptyFilters() {
    return EMPTY_SEARCH_FILTERS;
  }
}

const store = new filterStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.UPDATE_FILTERS: {
      _currentFilters = action.filters;

      store.emitChange();
      break;
    }

    case actionTypes.RESET_FILTERS: {
      if (_currentFilters.searchType) {
        _currentFilters = {
          searchType: _currentFilters.searchType,
          ...EMPTY_SEARCH_FILTERS,
        };
      } else {
        _currentFilters = {
          ...EMPTY_SEARCH_FILTERS,
        };
      }

      store.emitChange();
      break;
    }
    default:
    // Nothing to do
  }
});

export default store;
