import React, { useContext } from "react";
import Geolocation from "../../common/Geolocation/GooglePlaces";
import { Box, Divider, TextField, Typography, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { userType } from "../../../shared/userType";
import FormControl from "@mui/material/FormControl";
import SocialNetworksInput from "../SocialNetworksInput/SocialNetworksInput";
import FieldFilter from "../../common/Search/Filters/FieldFilter";
import DatePickerCalendar from "../../common/DatePickerCalendar/DatePickerCalendar";
import { IsMobileContext } from "../../../providers/IsMobileService";

function BusinessEditForm(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  function handleBusinessChange({ target }) {
    let businessUserObj = props.user.business;

    businessUserObj[target.name] = target.value;

    props.handleChange({
      target: {
        name: userType.BUSINESS,
        value: businessUserObj,
      },
    });
  }

  const listOptions = [
    { value: "1-10", id: 1 },
    { value: "10-50", id: 2 },
    { value: "50-100", id: 3 },
    { value: "100-500", id: 4 },
    { value: "500+", id: 5 },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.body.foreground,
        display: "flex",
        flexDirection: "column",

        "& .MuiOutlinedInput-root": {
          px: 1,
          lineHeight: "1.5em",
          fontSize: "0.95em",
          fontWeight: 300,
          color: theme.palette.text.primary,
          mb: 2,
        },
        "& .MuiInputBase-multiline": {
          py: 3,
          px: 2,
          mb: 0,
        },

        "& .MuiSelect-select": { px: 1, py: 2 },
      }}
    >
      <Typography variant={"h6"} sx={{ mb: 1 }}>
        About the Company
      </Typography>

      <TextField
        name={"about"}
        id="about"
        fullWidth
        multiline
        minRows={5}
        maxRows={15}
        variant="outlined"
        color={"business"}
        margin="none"
        value={props.user.business.about}
        onChange={handleBusinessChange}
      />

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 4 }}>
          Company information
        </Typography>
        <FormControl fullWidth>
          <InputLabel color={"business"} id="selectLabel">
            Amount of employees
          </InputLabel>
          <Select
            labelId="selectLabel"
            label="Amount of employees"
            name={"employeeAmount"}
            id="employeeAmount"
            color={"business"}
            value={props.user.business.employeeAmount}
            onChange={handleBusinessChange}
          >
            {listOptions.map((range) => {
              return (
                <MenuItem key={range.id} value={range.value}>
                  {range.value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          id="website"
          name={"website"}
          onChange={handleBusinessChange}
          label="Company website"
          type={"text"}
          variant="outlined"
          color={"business"}
          fullWidth
          value={props.user.business.website}
        />
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Social Presence (Optional)
        </Typography>

        <SocialNetworksInput
          networks={props.user.business.socialNetworks}
          color={"business"}
          handleChange={({ target }) => {
            props.handleChange({
              target: {
                name: "business",
                value: {
                  ...props.user.business,
                  socialNetworks: target.value,
                },
              },
            });
          }}
        />
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "80%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Company Field
        </Typography>
        {props.fields?.length > 0 && (
          <FieldFilter
            fieldName={"Company field"}
            name={"field"}
            color={"business"}
            handleChange={handleBusinessChange}
            fields={props.fields}
            selected={props.user.business.field}
          />
        )}
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 4 }}>
          Contact Details
        </Typography>

        <Typography variant={"subtitle2"}>Username</Typography>
        <TextField
          id="firstName"
          name={"firstName"}
          onChange={props.handleChange}
          placeholder="Full name"
          margin="none"
          variant="outlined"
          color={"business"}
          fullWidth
          value={props.user.firstName}
          error={!!props.errors.firstName}
          helperText={props.errors.firstName}
        />

        <Typography variant={"subtitle2"}>Email</Typography>
        <TextField
          id="email"
          name={"email"}
          onChange={props.handleChange}
          placeholder={"Your email"}
          type={"email"}
          margin="none"
          variant="outlined"
          color={"business"}
          fullWidth
          value={props.user.email}
          error={!!props.errors.email}
          helperText={props.errors.email}
        />

        <Typography variant={"subtitle2"}>Phone number</Typography>
        <TextField
          id="phone"
          name={"phone"}
          type={"text"}
          onChange={props.handleChange}
          placeholder="Your phone number"
          margin="none"
          variant="outlined"
          color={"business"}
          fullWidth
          value={props.user.phone}
          error={!!props.errors.phone}
          helperText={props.errors.phone}
        />

        <Typography variant={"subtitle2"}>Address</Typography>
        <Geolocation
          placeholder="Enter your address"
          color={"business"}
          handleChange={props.handleChange}
          address={props.user.address}
          error={props.errors?.address}
        />

        <Typography variant={"subtitle2"}>Birth date</Typography>
        <DatePickerCalendar
          name={"birthDate"}
          label={"Date of birth"}
          color={"business"}
          value={props.user.business.birthDate}
          handleChange={handleBusinessChange}
        />
      </Box>
    </Box>
  );
}

export default BusinessEditForm;
