import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import MenuListButton from "../../../MenuListButton/MenuListButton";
import { Box, IconButton } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

function VideoListItem(props) {
  const fullName = props.video.name;

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [isLoadSucceed, setIsLoadSucceed] = useState(true);

  useEffect(() => {
    videoRef.current.addEventListener("error", playbackFailed);
    videoRef.current.addEventListener("loadedmetadata", updateTime);
    videoRef.current.addEventListener("timeupdate", takeSnap, false);
  }, []);

  function updateTime() {
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = 5;
    }
  }

  function takeSnap() {
    if (canvasRef.current) {
      let context = canvasRef.current.getContext("2d");

      let ratio = videoRef.current.videoWidth / videoRef.current.videoHeight;
      let w = videoRef.current.videoWidth - 100;
      let h = w / ratio;
      canvasRef.current.width = w;
      canvasRef.current.height = h;

      context.drawImage(videoRef.current, 0, 0, w, h);
    }
  }

  function playbackFailed() {
    setIsLoadSucceed(false);
  }

  return (
    <>
      {isLoadSucceed && (
        <Box
          title={fullName}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "calc(100% / 3 - 8px)",
            mb: 1,
            cursor: "pointer",

            aspectRatio: "16 / 10",
            objectFit: "contain",
            backgroundColor: "#fff",
          }}
        >
          <video
            style={{ display: "none" }}
            ref={videoRef}
            src={props.video.src}
            autoPlay={false}
            controls={false}
          />
          <Box
            name={"video-overly"}
            sx={{
              position: "absolute",
              inset: 0,
              backgroundColor: "rgba(0,0,0,0.2)",
            }}
          />

          <Box
            name={"play-button"}
            onClick={props.handleVideoSelect}
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              inset: 0,
              "& .MuiIconButton-root": {
                color: props.userColor,
                backgroundColor: "rgba(255,255,255,0.7)",
              },
              "&:hover": {
                "& .MuiIconButton-root": {
                  color: props.userColor,
                  backgroundColor: "rgba(255,255,255,0.9)",
                },
              },
            }}
          >
            <IconButton
              aria-controls="play"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <PlayArrow />
            </IconButton>
          </Box>

          {props.isEdit && (
            <Box
              name={"menu-button"}
              sx={{
                position: "absolute",
                top: 0,
                right: "2.5em",

                "& .MuiIconButton-root": {
                  color: props.userColor,
                  backgroundColor: "rgba(255,255,255,0.7)",
                },
                "& .MuiIconButton-root:hover": {
                  color: props.userColor,
                  backgroundColor: "rgba(255,255,255,0.9)",
                },
              }}
            >
              <MenuListButton
                value={props.video}
                options={[
                  { name: "Move to front", func: props.handleSetMainVideo },
                  { name: "Remove", func: props.handleVideoDelete },
                ]}
              />
            </Box>
          )}

          <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />
        </Box>
      )}
    </>
  );
}

export default VideoListItem;
