import { EventEmitter } from "events";
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";

const CHANGE_EVENT = "CHANGE";

let _lastResults = [];
let _isEndOfResults = false;

class searchStore extends EventEmitter {
  addChangeListener(cb) {
    this.on(CHANGE_EVENT, cb);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  removeChangeListener(cb) {
    this.removeListener(CHANGE_EVENT, cb);
  }

  //-------------------------------------
  getResults() {
    return _lastResults;
  }

  isEndOfResults() {
    return _isEndOfResults;
  }
}

const store = new searchStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SET_RESULTS: {
      _isEndOfResults = action.isEndOfResults;
      _lastResults = action.results;

      store.emitChange();

      break;
    }

    case actionTypes.UPDATE_RESULTS: {
      _isEndOfResults = action.isEndOfResults;
      _lastResults.push(...action.results);

      store.emitChange();

      break;
    }

    default:
    // Nothing to do
  }
});

export default store;
