import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { userType } from "../../shared/userType";
import TitleAndText from "../common/Text/TitleAndText";
import FieldsStore from "../../stores/FieldsStore";
import * as fieldsActions from "../../actions/fieldsActions";
import Divider from "@mui/material/Divider";
import { Box, Typography, useTheme } from "@mui/material";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Public,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { IsMobileContext } from "../../providers/IsMobileService";

function TalentInfo(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [talentField, setTalentField] = useState();
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    setUser(props.user);

    if (FieldsStore.getTalentFields().length > 0) {
      fieldsActions.getTalentFields();
    }
  }, [props.user]);

  useEffect(() => {
    FieldsStore.addChangeListener(getField);
    return () => FieldsStore.addChangeListener(getField);
  }, []);

  function getField() {
    setTalentField(
      FieldsStore.getFieldByUserAndType(props.user, userType.TALENT)
    );
  }

  function getSocialIcon(socialNetwork) {
    switch (socialNetwork) {
      case "Facebook":
        return <Facebook sx={{ mr: 1 }} />;
      case "Instagram":
        return <Instagram sx={{ mr: 1 }} />;
      case "YouTube":
        return <YouTube sx={{ mr: 1 }} />;
      case "LinkedIn":
        return <LinkedIn sx={{ mr: 1 }} />;
      case "Twitter":
        return <Twitter sx={{ mr: 1 }} />;
      default:
        return <Public sx={{ mr: 1 }} />;
    }
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.body.foreground }}>
      <Typography variant={"h5"} sx={{ mb: 2 }}>
        About Me
      </Typography>
      <Typography
        variant={"body1"}
        sx={{
          color: theme.palette.body.text,
          lineHeight: 1.56,
          fontWeight: 300,
        }}
      >
        {user[userType.TALENT].about}
      </Typography>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box
        name={"personal-details"}
        sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
          <Typography variant={"h5"}>Personal Details</Typography>
          <TitleAndText
            title={"Name"}
            text={`${user.firstName} ${user.lastName || ""}`}
          />
          <TitleAndText
            title={"Email"}
            text={`${
              user[userType.TALENT].isDisplayed.email ? user.email : "Hidden"
            }`}
            type={user[userType.TALENT].isDisplayed.email ? "email" : "text"}
          />
          <TitleAndText
            title={"Phone"}
            text={`${
              user[userType.TALENT].isDisplayed.phone ? user.phone : "Hidden"
            }`}
          />
          <TitleAndText
            title={"Address"}
            text={`${
              user[userType.TALENT].isDisplayed.address
                ? user.address
                : "Hidden"
            }`}
          />
          {user[userType.TALENT].isDisplayed.gender && (
            <TitleAndText
              title={"Gender"}
              text={user[userType.TALENT].gender}
            />
          )}
          {user[userType.TALENT].isDisplayed.birthDate && (
            <TitleAndText
              title={"Date Of Birth"}
              text={new Date(
                user[userType.TALENT].birthDate
              ).toLocaleDateString("en-GB")}
            />
          )}
          <TitleAndText
            title={"Nickname"}
            text={user[userType.TALENT].nickname}
          />
        </Box>
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
          <Typography variant={"h5"}>Social Presence</Typography>

          {user[userType.TALENT].socialNetworks.length > 0 &&
            user[userType.TALENT].isDisplayed.socialNetworks &&
            user[userType.TALENT].socialNetworks.map((network) => {
              if (!network.url) return;
              return (
                <Box
                  key={network.id}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {getSocialIcon(network.type)}
                  <TitleAndText
                    title={network.type}
                    text={network.url}
                    type={"socialNetwork"}
                  />
                </Box>
              );
            })}
          {user[userType.TALENT].socialNetworks.length < 1 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={"body1"}
                sx={{
                  color: theme.palette.body.lightText,
                  lineHeight: "2.7em",
                  fontWeight: 300,
                }}
              >
                No social presence yet
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Typography variant={"h5"}>Talent Skills</Typography>
      {talentField && (
        <TitleAndText title={"Talent Field"} text={talentField.name} />
      )}
      <TitleAndText title={"Skills"} text={user[userType.TALENT].skills} />
    </Box>
  );
}

TalentInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default TalentInfo;
