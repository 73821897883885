import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material";
import { userType } from "../../../../../shared/userType";

function MenuItem({
  icon,
  tooltip,
  button,
  selected,
  handleClick,
  name,
  colorType,
  isLink,
}) {
  let theme = useTheme();
  return (
    <Tooltip
      title={tooltip || " "}
      placement="right"
      arrow
      disableHoverListener={!tooltip}
    >
      <ListItem
        sx={{
          "&:hover": {
            backgroundColor: isLink
              ? "transparent"
              : theme.palette[colorType || userType.PERSONAL].hover,
          },
          "&.Mui-selected .MuiSvgIcon-root": {
            color: theme.palette[colorType || userType.PERSONAL].main,
          },
          "&.Mui-selected .MuiTypography-root": {
            color: theme.palette[colorType || userType.PERSONAL].main,
          },
          "&.Mui-selected:hover": {
            backgroundColor: isLink
              ? "transparent"
              : theme.palette[colorType || userType.PERSONAL].hover,
          },
          "&.Mui-selected": {
            backgroundColor: "#fff",
            borderRight: `${
              theme.palette[colorType || userType.PERSONAL].main
            } solid 4px`,
            borderRadius: " 0 2px 2px 0",
          },
        }}
        button
        selected={selected}
        onClick={() => {
          handleClick(name);
        }}
      >
        {icon && (
          <ListItemIcon sx={{ color: theme.palette.sideNav.icons }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          sx={{
            color: isLink
              ? theme.palette.sideNav.linkLabel
              : theme.palette.sideNav.labels,
            textDecoration: isLink && "underline",
            fontSize: isLink && "0.8rem",
          }}
          primary={button}
        />
      </ListItem>
    </Tooltip>
  );
}

export default MenuItem;
