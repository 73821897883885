import React, { useEffect } from "react";
import { CONST } from "../shared/consts";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import RegularText from "../components/common/Text/RegularText";
import Slide from "@mui/material/Slide";

let displayNotification;

export function Notifier() {
  const [snackbar, setSnackbar] = React.useState({
    isOpen: false,
    msg: "",
    type: "success",
  });

  useEffect(() => {
    displayNotification = setSnackbar;
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ isOpen: false, msg: "", type: "success" });
  };

  let transition = (props) => <Slide {...props} direction="up" />;

  return (
    <>
      {snackbar.isOpen && (
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={CONST.MSG_DURATION}
          onClose={handleSnackbarClose}
          TransitionComponent={transition}
          key={transition ? transition.name : ""}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.type}
            variant="filled"
          >
            <RegularText>{snackbar.msg}</RegularText>
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export function openSnackbar(msg, type) {
  displayNotification({ isOpen: true, msg, type });
}
