import { handleResponse, handleError } from "./apiUtils";
import CONF from "../configurations/applicationConf";
import { Auth } from "aws-amplify";
const baseUrl = CONF.API_URL + "/vacancy/";

export async function createVacancy(vacancy) {
  const actionUrl = "create/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(vacancy),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateVacancy(vacancy) {
  const actionUrl = "save/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT",
    body: JSON.stringify(vacancy),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getVacancyById(vacancyId) {
  const actionUrl = "view/";
  const params = `?id=${vacancyId}`;

  return fetch(baseUrl + actionUrl + params, {
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getVacanciesByIds(vacancyIdList) {
  const actionUrl = "getVacancyList/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({ vacancyIdList }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function addVideo(videoNameToAdd, vacancyId) {
  const actionUrl = `addVideo`;
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT",
    body: JSON.stringify({ videoNameToAdd, vacancyId }),
  })
    .then(handleResponse)
    .catch(handleError);
}
