import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import VideoUpload from "./VideoUpload/VideoUpload";
import * as userActions from "../../../actions/userActions";
import CONF from "../../../configurations/applicationConf";
import videoStore from "../../../stores/videoStore";
import { Box, Typography, useTheme } from "@mui/material";
import VideoListItem from "./VideoList/VideoListItem/VideoListItem";
import { userType } from "../../../shared/userType";

export default function VideoManage(props, ref) {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    videoPlayback: {
      width: "100%",
      backgroundColor: "#fafafa",
      height: "auto",
      border: "1px solid #d6d6d6",
    },
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  }));

  const isVacancyVideo = props.isVacancyVideo;
  const [videos, setVideos] = useState(populateState());

  const [selectedVideo, setSelectedVideo] = useState(
    (videos && videos[0]) || { name: "", src: "" }
  );
  const classes = useStyles();

  const MAX_VIDEO_COUNT = 9;
  const posterSrc = `${CONF.AWS_ACCESS_BUCKET_URL}/resources/images/video.poster.png`;

  const playerOptions = {
    controls: props.isControlEnabled,
    autoPlay: props.isAutoplay,
    muted: videoStore.getIsMuted(),
    loop: true,
    className: classes.videoPlayback,
    preload: "metadata",
    poster: posterSrc,
  };
  const playerRef = useRef();

  function populateState() {
    let videoSrcList;

    videoSrcList = props.videos.map((videoName) => {
      return {
        src: `${CONF.AWS_ACCESS_BUCKET_URL}/videos/${videoName}`,
        name: videoName,
      };
    });

    return videoSrcList;
  }

  useEffect(() => {
    const currentVideos = populateState();
    setVideos(currentVideos);
    setSelectedVideo(
      (currentVideos && currentVideos[0]) || { name: "", src: "" }
    );

    if (playerRef.current) {
      const aspectRatio =
        playerRef.current.clientWidth / playerRef.current.clientHeight;
      playerRef.current.height = aspectRatio * playerRef.current.clientWidth;
    }
  }, [props.videos]);

  function handleVideoDelete(videoToDelete) {
    userActions.deleteUserVideo(videoToDelete, props.userType);
    setSelectedVideo({});
  }

  function handleSetMainVideo(videoToDelete) {
    userActions.setUserMainVideo(videoToDelete, props.userType);
  }

  function handleVideoSelect(video) {
    setSelectedVideo(video);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2.5 }}>
      {/* ------------------- Video Player ------------------------*/}
      {selectedVideo.name ? (
        <video
          {...playerOptions}
          src={selectedVideo.src + "#t=0.1"}
          ref={playerRef}
        />
      ) : (
        <>
          <Box
            style={{
              backgroundColor: "#fafafa",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "calc(100% - 2.5em)",
                aspectRatio: "16 / 9",
                backgroundColor: "rgba(0,0,0,0.7)",
              }}
            >
              <VideoUpload
                userColor={props.userColor || userType.PERSONAL}
                maxVideo={MAX_VIDEO_COUNT}
                currUploaded={videos?.length || 0}
              />
              <Typography sx={{ mt: 3, color: "#fff" }} variant={"h5"}>
                Add your first video!
              </Typography>
              <Typography sx={{ color: "#fff" }} variant={"subtitle2"}>
                Shoot horizontal videos up to 500mb
              </Typography>
            </Box>
            <img
              style={{
                width: "100%",
                height: "auto",
              }}
              src={posterSrc}
            />
          </Box>
        </>
      )}

      {/* ------------------- Video List ------------------------*/}
      {!isVacancyVideo && (props.isEdit || videos.length > 0) && (
        <Box
          id={"uploadContainer"}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "flex-start",
            flexDirection: "row",
            flexWrap: "wrap",
            mt: 1,
            width: "inherit",
          }}
        >
          {videos &&
            videos.length > 0 &&
            videos.map((video) => (
              <VideoListItem
                key={video.name}
                video={video}
                handleVideoSelect={() => handleVideoSelect(video)}
                handleVideoDelete={handleVideoDelete}
                handleSetMainVideo={handleSetMainVideo}
                isEdit={props.isEdit}
                userColor={props.userColor}
              />
            ))}

          {/*-------------------- Uppy ----------------------------*/}
          {props.isEdit && videos && videos.length < MAX_VIDEO_COUNT && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "calc(100% / 3 - 8px)",
                mb: 1,
                cursor: "pointer",

                aspectRatio: "16 / 10",
                backgroundColor: "#fff",
                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <VideoUpload
                userColor={props.userColor || userType.PERSONAL}
                maxVideo={MAX_VIDEO_COUNT}
                currUploaded={videos?.length || 0}
                label={
                  <Typography
                    variant={"body2"}
                    sx={{
                      mt: 1,
                      textDecoration: "underline",
                      color: theme.palette.sideNav.linkLabel,
                    }}
                  >
                    Add new video
                  </Typography>
                }
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

VideoManage.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isVacancyVideo: PropTypes.bool,
  videos: PropTypes.array.isRequired,
  isControlEnabled: PropTypes.bool,
  isAutoplay: PropTypes.bool,
};

VideoManage.defaultProps = {
  isVacancyVideo: false,
  isAutoplay: false,
  isControlEnabled: true,
};
