import { EventEmitter } from "events";
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";

const IS_MUTED = "IS_MUTED";
const CHANGE_EVENT = "CHANGE_EVENT";

let _isMuted = !!(localStorage.getItem(IS_MUTED) - 0);

class videoStore extends EventEmitter {
  addChangeListener(cb) {
    this.on(CHANGE_EVENT, cb);
  }

  removeChangeListener(cb) {
    this.removeListener(CHANGE_EVENT, cb);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  //-------------------------------------
  getIsMuted() {
    return _isMuted;
  }
}

const store = new videoStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.TOGGLE_MUTE_VIDEO: {
      localStorage.setItem(IS_MUTED, action.isMuted ? "1" : "0");
      _isMuted = action.isMuted;

      store.emitChange();
      break;
    }
    default:
    // Nothing to do
  }
});

export default store;
