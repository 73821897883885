import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardActions, TextField } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { openSnackbar } from "../../shared/Notifier";

export default function PasswordReset() {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
    },
    mainContainer: {
      top: "15vh",
      position: "relative",
      maxWidth: "1440px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      userSelect: "none",
      padding: "2em",
    },
  }));
  const classes = useStyles();
  const [getEmail, getConfirmationCode, newPassword] = [1, 2, 3];
  const [step, setStep] = useState(getStep(getEmail));

  function handleGetConfirmationCode(email) {
    setStep(getStep(getConfirmationCode, email));
  }

  function handleNewPassword() {
    setStep(getStep(newPassword));
  }

  function getStep(num, userData) {
    switch (num) {
      case getEmail:
        return <EmailForReset handleNextStep={handleGetConfirmationCode} />;

      case getConfirmationCode:
        return (
          <ConfirmationCode
            handleNextStep={handleNewPassword}
            userData={{ email: userData }}
          />
        );

      default:
        return <EmailForReset />;
    }
  }

  return (
    <Box
      sx={{
        pt: "3em",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: 400, backgroundColor: "#fff", px: "3em", py: "4em" }}>
        {step}
      </Box>
    </Box>
  );
}

function EmailForReset(props) {
  const useStyles = makeStyles((theme) => ({
    errorLabel: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  function handleChange({ target }) {
    setEmail(target.value);
  }

  function handleCodeSend() {
    if (email) {
      Auth.forgotPassword(email)
        .then((data) => {
          props.handleNextStep(email);
        })
        .catch((err) => setError(err));
    } else {
      setError("Write your email");
    }
  }

  return (
    <>
      <Typography variant={"h6"}>Reset your password</Typography>
      <Typography variant={"subtitle1"} sx={{ mb: "1em" }}>
        you will receive a confirmation code to your email
      </Typography>
      <TextField
        autoFocus
        id="email"
        name={"email"}
        onChange={handleChange}
        label="Email"
        fullWidth
        variant="outlined"
        color="personal"
        value={email}
      />
      {!!error && (
        <Typography className={classes.errorLabel}>{error}</Typography>
      )}
      <Button onClick={handleCodeSend} color="personal" sx={{ mt: "1em" }}>
        Send code
      </Button>
    </>
  );
}

function ConfirmationCode(props) {
  const useStyles = makeStyles((theme) => ({
    errorLabel: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const [formData, setFormData] = useState({
    confirmationCode: "",
    password: "",
    passwordCheck: "",
  });
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  function handleChange({ target }) {
    setFormData({ ...formData, [target.name]: target.value });
  }

  function handleReset(e) {
    if (formData.password === formData.passwordCheck) {
      Auth.forgotPasswordSubmit(
        props.userData.email,
        formData.confirmationCode,
        formData.password
      )
        .then(() => {
          openSnackbar("Password updated successfully", "success");
          history.goBack();
        })
        .catch((err) => setError("Try again, code seems to be wrong"));
    } else {
      setPasswordError("Passwords are not identical");
    }

    e.preventDefault();
  }

  return (
    <>
      <Typography variant={"h6"} sx={{ mb: "1em" }}>
        Enter confirmation code
      </Typography>

      <TextField
        autoFocus
        sx={{ mb: "1em" }}
        id="confirmationCode"
        name={"confirmationCode"}
        onChange={handleChange}
        label="Confirmation code"
        variant="outlined"
        fullWidth
        value={formData.confirmationCode}
      />
      {!!error && (
        <Typography className={classes.errorLabel}>{error}</Typography>
      )}
      <TextField
        autoFocus
        sx={{ mb: "1em" }}
        id="password"
        name={"password"}
        onChange={handleChange}
        label="New password"
        variant="outlined"
        fullWidth
        value={formData.password}
      />
      <TextField
        autoFocus
        sx={{ mb: "1em" }}
        id="passwordCheck"
        name={"passwordCheck"}
        onChange={handleChange}
        label="Repeat password"
        variant="outlined"
        fullWidth
        value={formData.passwordCheck}
      />
      {!!passwordError && (
        <Typography className={classes.errorLabel}>{passwordError}</Typography>
      )}

      <Button type={"submit"} color="primary">
        Save new password
      </Button>
    </>
  );
}
