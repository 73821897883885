import { handleResponse, handleError } from "./apiUtils";
import CONF from "../configurations/applicationConf";
import { Auth } from "aws-amplify";
const baseUrl = CONF.API_URL + "/chats/";

export async function getChatLog(otherUser, askingUser) {
  const actionUrl = "getChatLog/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({ otherUser, askingUser }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getChatLogById(chatId) {
  const actionUrl = "getChatLogById/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({ chatId }),
  })
    .then(handleResponse)
    .catch(handleError);
}

/**
 * @params
 * sender:{userId, userType},
 * receiver:{userId, userType},
 * message:{sender, time, content}
 */
export async function sendMessage(sender, receiver, message) {
  const actionUrl = "sendMessage/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({ sender, receiver, message }),
  })
    .then(handleResponse)
    .catch(handleError);
}
