import { EventEmitter } from "events";
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import { userType } from "../shared/userType";

const CHANGE_EVENT = "CHANGE";
const CURRENT_USER_TYPE = "CURRENT_USER_TYPE";

let _currentUser = null;

class userStore extends EventEmitter {
  addChangeListener(cb) {
    this.on(CHANGE_EVENT, cb);
  }

  removeChangeListener(cb) {
    this.removeListener(CHANGE_EVENT, cb);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  //-------------------------------------
  getCurrentUser() {
    return _currentUser;
  }
  getCurrentType() {
    return localStorage.getItem(CURRENT_USER_TYPE);
  }
}

const store = new userStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.UPDATE_USER: {
      _currentUser = action.user;

      store.emitChange();
      break;
    }
    case actionTypes.LOGIN_USER: {
      _currentUser = action.loggedInUser;
      localStorage.setItem(CURRENT_USER_TYPE, _currentUser.userType[0]);

      store.emitChange();
      break;
    }
    case actionTypes.LOGOUT_USER: {
      _currentUser = null;
      localStorage.removeItem(CURRENT_USER_TYPE);

      store.emitChange();
      break;
    }
    case actionTypes.TYPE_CHANGE: {
      if (_currentUser.userType.includes(action.userType)) {
        localStorage.setItem(CURRENT_USER_TYPE, action.userType);

        store.emitChange();
      } else {
        console.error(
          `user type: ${action.userType} is not included at userId: ${_currentUser._id}`
        );
      }
      break;
    }
    case actionTypes.DEFAULT_USER_TYPE: {
      localStorage.setItem(CURRENT_USER_TYPE, userType.PERSONAL);

      break;
    }
    default:
    // Nothing to do
  }
});

export default store;
