import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { CONST } from "../../shared/consts";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PrivacySettingsForm from "./PrivacySettingsForm";
import DeleteAccountForm from "./DeleteAccountForm";
import ApplicationSettingsForm from "./ApplicationSettingsForm";

export default function Settings() {
  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      maxWidth: "1440px",
      display: "flex",
      alignItems: "flex-start",
      [theme.breakpoints.up(CONST.LG)]: {
        padding: "2em 3em",
        width: "65%",
      },
      [theme.breakpoints.down(CONST.LG)]: {
        width: "90%",
      },
    },
    contentContainer: {
      width: "100%",
    },
    tabs: {},
  }));
  const classes = useStyles();
  const tabs = [
    {
      value: "privacy",
      label: "PRIVACY SETTINGS",
      ariaLabel: "privacy",
      component: <PrivacySettingsForm />,
    },
    {
      value: "application",
      label: "APPLICATION",
      ariaLabel: "application",
      component: <ApplicationSettingsForm />,
    },
    {
      value: "deleteAccount",
      label: "DELETE ACCOUNT",
      ariaLabel: "delete account",
      component: <DeleteAccountForm />,
    },
  ];
  const [tab, setTab] = useState(tabs[0]);

  const handleChange = (event, newValue) => {
    setTab(tabs.find((x) => x.value === newValue));
  };

  return (
    <div className={classes.mainContainer}>
      <aside className={classes.tabs}>
        <Tabs
          value={tab.value}
          onChange={handleChange}
          orientation="vertical"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          aria-label="settings tabs"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              aria-label={tab.ariaLabel}
            />
          ))}
        </Tabs>
      </aside>
      <main className={classes.contentContainer}>{tab.component}</main>
    </div>
  );
}
