import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";

function DatePickerCalendar(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        openTo={"year"}
        disableFuture
        views={["year", "month", "day"]}
        inputFormat="dd/MM/yyyy"
        value={props.value || null}
        renderInput={(params) => (
          <TextField
            {...params}
            color={props.color || "personal"}
            margin={"none"}
            fullWidth
          />
        )}
        onChange={(newDate) => {
          props.handleChange({
            target: { name: props.name, value: newDate },
          });
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePickerCalendar;
