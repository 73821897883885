import { EventEmitter } from "events";
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import { userType } from "../shared/userType";

const CHANGE_EVENT = "CHANGE";
let _fields = [];
let _talentFields = [];
let _freelancerFields = [];

let searchFunc = function (field, id) {
  let res = null;

  if (id === +field.id) {
    return field;
  } else {
    if (field.roles?.length > 0) {
      field.roles.some((role) => {
        res = searchFunc(role, id);

        return !!res;
      });
    }

    return res;
  }
};

class FieldsStore extends EventEmitter {
  addChangeListener(cb) {
    this.on(CHANGE_EVENT, cb);
  }

  removeChangeListener(cb) {
    this.removeListener(CHANGE_EVENT, cb);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  //-------------------------------------
  getFields() {
    return _fields;
  }

  getTalentFields() {
    return _talentFields;
  }
  getFreelancerFields() {
    return _freelancerFields;
  }

  getPersonalFieldById(id) {
    if (_fields.length > 0) {
      return searchFunc({ roles: _fields, id: -1 }, id);
    } else {
      return null;
    }
  }
  getTalentFieldById(id) {
    if (_talentFields.length > 0) {
      return searchFunc({ roles: _talentFields, id: -1 }, id);
    } else {
      return null;
    }
  }
  getFreelancerFieldById(id) {
    if (_freelancerFields.length > 0) {
      return searchFunc({ roles: _freelancerFields, id: -1 }, id);
    } else {
      return null;
    }
  }

  getFieldByUserAndType(user, type) {
    let mostSpecificFieldId =
      user[type].subField3 ||
      user[type].subField2 ||
      user[type].subField1 ||
      user[type].field;

    switch (type) {
      case userType.PERSONAL:
      case userType.BUSINESS: {
        return this.getPersonalFieldById(mostSpecificFieldId);
      }
      case userType.TALENT: {
        return this.getTalentFieldById(mostSpecificFieldId);
      }
      case userType.FREELANCER: {
        return this.getFreelancerFieldById(mostSpecificFieldId);
      }
      default: {
        break;
      }
    }
  }
}

const store = new FieldsStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.LOAD_FIELDS: {
      _fields = action.fields;
      store.emitChange();
      break;
    }
    case actionTypes.LOAD_TALENT_FIELDS: {
      _talentFields = action.talentFields;
      store.emitChange();
      break;
    }
    case actionTypes.LOAD_FREELANCER_FIELDS: {
      _freelancerFields = action.freelancerFields;
      store.emitChange();
      break;
    }
    default:
    // Nothing to do
  }
});

export default store;
