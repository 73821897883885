import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalLinearStepper({
  shouldNext,
  toggleShouldNextOff,
  getSteps,
  getStepContent,
  getStepForm,
  handleClose,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  useEffect(() => {
    if (shouldNext) {
      handleNext();
      toggleShouldNextOff();
    }
  }, [shouldNext]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = function () {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = function () {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography key={index} variant="caption">
                Optional
              </Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep < steps.length && (
          <div>
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
            <DialogActions>
              {isStepOptional(activeStep) && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              {activeStep !== 0 && (
                <Button
                  onClick={handleBack}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Back
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  type={"submit"}
                  form={getStepForm(activeStep)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Next
                </Button>
              )}
            </DialogActions>
          </div>
        )}
      </div>
    </div>
  );
}
