import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { userType } from "../../shared/userType";
import TitleAndText from "../common/Text/TitleAndText";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Public,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FieldsStore from "../../stores/FieldsStore";
import Divider from "@mui/material/Divider";
import { IsMobileContext } from "../../providers/IsMobileService";

function PersonalInfo(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [user, setUser] = useState(props.user);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  function handleAction(cb) {
    if (props.isLoggedIn) {
      cb();
    } else {
      //TODO: open modal with options to login or register
    }
  }

  function getSocialIcon(socialNetwork) {
    switch (socialNetwork) {
      case "Facebook":
        return <Facebook sx={{ mr: 1 }} />;
      case "Instagram":
        return <Instagram sx={{ mr: 1 }} />;
      case "YouTube":
        return <YouTube sx={{ mr: 1 }} />;
      case "LinkedIn":
        return <LinkedIn sx={{ mr: 1 }} />;
      case "Twitter":
        return <Twitter sx={{ mr: 1 }} />;
      default:
        return <Public sx={{ mr: 1 }} />;
    }
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.body.foreground }}>
      <Typography variant={"h5"} sx={{ mb: 2 }}>
        About Me
      </Typography>
      <Typography
        variant={"body1"}
        sx={{ color: theme.palette.body.text, lineHeight: 1.56 }}
      >
        {user[userType.PERSONAL].about}
      </Typography>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box
        name={"personal-details"}
        sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
          <Typography variant={"h5"}>Professional Skills</Typography>

          <TitleAndText
            title={"Professional Field"}
            text={
              FieldsStore.getPersonalFieldById(user[userType.PERSONAL].field)
                ?.name
            }
          />
          <TitleAndText
            title={"Experience"}
            text={`${user[userType.PERSONAL].rank} Years`}
          />

          <TitleAndText
            title={"Salary Expectation"}
            text={`${formatter.format(
              user[userType.PERSONAL].salaryFrom
            )} to ${formatter.format(user[userType.PERSONAL].salaryTo)} ${
              user[userType.PERSONAL].salaryPeriod
            }`}
          />
        </Box>
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
          <Typography variant={"h5"}>Social Presence</Typography>

          {user[userType.PERSONAL].isDisplayed.socialNetworks &&
            user[userType.PERSONAL].socialNetworks.map((network) => {
              if (!network.url) return;
              return (
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  key={network.id}
                >
                  {getSocialIcon(network.type)}
                  <TitleAndText
                    title={network.type}
                    text={network.url}
                    type={"socialNetwork"}
                  />
                </Box>
              );
            })}
          {user[userType.PERSONAL].socialNetworks.length < 1 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={"body1"}
                sx={{
                  color: theme.palette.body.lightText,
                  lineHeight: "2.7em",
                  fontWeight: 300,
                }}
              >
                No social presence yet
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Typography variant={"h5"}>Personal Details</Typography>

      {user[userType.PERSONAL].isDisplayed.gender && (
        <TitleAndText title={"Gender"} text={user[userType.PERSONAL].gender} />
      )}
      {user[userType.PERSONAL].isDisplayed.birthDate && (
        <TitleAndText
          title={"Date Of Birth"}
          text={new Date(user[userType.PERSONAL].birthDate).toLocaleDateString(
            "en-GB"
          )}
        />
      )}
      <TitleAndText
        title={"Name"}
        text={`${user.firstName} ${user.lastName || ""}`}
      />
      <TitleAndText
        title={"Email"}
        type={user[userType.PERSONAL].isDisplayed.email ? "email" : "text"}
        text={`${
          user[userType.PERSONAL].isDisplayed.email ? user.email : "Hidden"
        }`}
      />
      <TitleAndText
        title={"Phone"}
        text={`${
          user[userType.PERSONAL].isDisplayed.phone ? user.phone : "Hidden"
        }`}
      />
      <TitleAndText
        title={"Address"}
        text={`${
          user[userType.PERSONAL].isDisplayed.address ? user.address : "Hidden"
        }`}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  infoRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid #eee",
    padding: "1em",
    marginBottom: "1em",
    boxShadow: "1px 1px 2px #ddd, 2px 2px 4px #aaa",
    backgroundColor: "#fff",
  },
  moreSpace: {
    marginBottom: ".5em",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

PersonalInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PersonalInfo;
