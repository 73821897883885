import React from "react";
import * as userActions from "../../../../../actions/userActions";
import SpeedDialUsers from "../SpeedDialUsers/SpeedDialUsers";
import Add from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { changeTypeTemporarily } from "../../../../../actions/userActions";

function RegisterUserRole(props) {
  const history = useHistory();

  const TYPES = [
    { display: "c", name: "personal", tooltip: `Register as candidate` },
    { display: "t", name: "talent", tooltip: `Register as talent` },
    { display: "c", name: "business", tooltip: `Register as company` },
    { display: "f", name: "freelancer", tooltip: `Register as freelancer` },
  ];

  const userTypes = TYPES.filter((type) =>
    props.userTypes.every((userType) => userType !== type.name)
  );

  function navigateToRegisterPage(tempType) {
    // Access to state like this:
    // props.location.state.temporaryType
    history.push({
      pathname: "/edit",
      state: { temporaryType: tempType },
    });
  }

  return (
    <SpeedDialUsers
      tooltip={"Register as new type"}
      handleOpenSpeedDial={props.handleOpenSpeedDial}
      buttonLabel={"Add new account"}
      userTypes={userTypes}
      handleUserClick={navigateToRegisterPage}
    />
  );
}

export default RegisterUserRole;
