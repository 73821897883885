import React, { useContext, useState } from "react";
import * as userActions from "../../../actions/userActions";
import { Storage } from "aws-amplify";
import Typography from "@mui/material/Typography";
import MenuListButton from "../../common/MenuListButton/MenuListButton";
import RepositionDialog from "../../common/ImageScaleAndReposition/RepositionDialog";
import { Box, Button, useTheme } from "@mui/material";
import CONF from "../../../configurations/applicationConf";
import Avatar from "@mui/material/Avatar";
import { userType } from "../../../shared/userType";
import FieldsStore from "../../../stores/FieldsStore";
import { Edit, PhotoCamera, Share } from "@mui/icons-material";
import ShareDialog from "../../ShareDialog/ShareDialog";
import { IsMobileContext } from "../../../providers/IsMobileService";
import { useHistory } from "react-router-dom";
import UploadAvatarImage from "../UploadAvatarImage/UploadAvatarImage";

export default function UploadCoverImage(props) {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);

  let myInput;

  const [isImageRepositionModalOpen, setIsImageRepositionModalOpen] = useState(
    false
  );
  const [imageFile, setImageFile] = useState(null);
  const [isShareOpen, setIsShareOpen] = useState(false);

  function handleCoverImageSet() {
    setImageFile(myInput.files[0]);

    setIsImageRepositionModalOpen(true);
  }

  function handleCoverImageUpload(image) {
    Storage.configure({
      AWSS3: {
        bucket: process.env.REACT_APP_S3_IMAGE_UPLOAD_BUCKET,
        region: "us-east-1",
      },
    });

    if (image) {
      userActions
        .uploadCoverImage(image.name, props.currentUserType)
        .then((filename) => {
          // Upload to aws
          Storage.put("cover_images/" + filename, image, {});
        });
    }
  }

  function handleCoverImageDelete() {
    let UserObj = props.user[props.currentUserType];

    UserObj.coverImage = "";

    props.handleChange({
      target: {
        name: props.currentUserType,
        value: UserObj,
      },
    });

    myInput.value = "";
  }

  function handleCloseShare() {
    setIsShareOpen(false);
  }
  function handleOpenShare() {
    setIsShareOpen(true);
  }

  return (
    <>
      <Box
        name={"cover-image"}
        sx={{
          position: "relative",
          width: isMobile ? "100vw" : theme.sizing.mainView.width,
          height: isMobile ? "40vh" : 440,
          boxShadow: "inset 0 -100px 400px rgb(0 0 0 / 80%)",
          overflow: "hidden",
        }}
      >
        <ShareDialog
          isOpen={isShareOpen}
          handleClose={handleCloseShare}
          pathname={window.location.pathname}
        />
        <RepositionDialog
          isOpen={isImageRepositionModalOpen}
          handleClose={() => setIsImageRepositionModalOpen(false)}
          handleSave={(image) => {
            handleCoverImageUpload(image);
          }}
          imageFileOrSrc={imageFile}
          dialogTitleText={"Edit Cover Image"}
          selectProps={{
            radius: 0,
            width: 540,
            modalWidth: 600,
            height: 200,
            aspectRatio: "27 / 10",
          }}
        />

        {props.isEdit && (
          <MenuListButton
            disablePortal
            value={"coverImage"}
            options={
              props.coverImage
                ? [
                    {
                      name: "Change Cover",
                      func: () => {
                        myInput.click();
                      },
                    },
                    { name: "Delete", func: handleCoverImageDelete },
                  ]
                : [
                    {
                      name: "Change Cover",
                      func: () => {
                        myInput.click();
                      },
                    },
                  ]
            }
            button={{ text: "Edit", variant: "contained" }}
          />
        )}
        <input
          id="myInput"
          type="file"
          ref={(ref) => (myInput = ref)}
          style={{ display: "none" }}
          onChange={handleCoverImageSet}
        />
        {props.coverImage ? (
          <img
            style={{
              zIndex: -1,
              position: "absolute",
              inset: 0,
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
            src={props.coverImage}
          />
        ) : (
          <>
            <img
              style={{
                zIndex: -1,
                position: "absolute",
                inset: 0,
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
              src={`${CONF.AWS_ACCESS_BUCKET_URL}/resources/images/${props.currentUserType}.cover.image.png`}
            />
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                top: "104px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => myInput.click()}
            >
              <PhotoCamera />
              <Typography
                variant={"h6"}
                style={{ color: "inherit", fontWeight: 300 }}
              >
                Add a Cover Image
              </Typography>
              <Typography
                variant={"subtitle1"}
                style={{ color: "inherit", fontWeight: 300 }}
              >
                Must be a jpg. gif. png file smaller than 10Mb, best dimensions
                are 1640x515.
              </Typography>
            </Box>
          </>
        )}

        <Box
          name={"avatar-name-and-field"}
          sx={{
            position: "absolute",
            padding: "1em",
            bottom: "3em",
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - 2em)",
            height: 156,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <></>
          ) : (
            <Box
              name={"desktop-name-and-field"}
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  borderRadius: 999,
                  overflow: "hidden",
                  width: "10em",
                  height: "10em",
                }}
              >
                {/*<Avatar*/}
                {/*  sx={{*/}
                {/*    width: "100%",*/}
                {/*    height: "100%",*/}
                {/*    objectFit: "cover",*/}
                {/*    backgroundColor: theme.palette.body.avatar,*/}
                {/*    fontSize: "2em",*/}
                {/*  }}*/}
                {/*  alt={props.user.firstName}*/}
                {/*  src={*/}
                {/*    props.currentUserType &&*/}
                {/*    props.user[props.currentUserType].image*/}
                {/*      ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${*/}
                {/*          props.user[props.currentUserType].image*/}
                {/*        }`*/}
                {/*      : props.user[props.currentUserType].socialImage || ""*/}
                {/*  }*/}
                {/*/>*/}
                <UploadAvatarImage
                  currentUserType={props.currentUserType}
                  user={props.user}
                  isEdit={props.isEdit}
                  alt={props.user.firstName}
                  src={
                    props.currentUserType &&
                    props.user[props.currentUserType].image
                      ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${
                          props.user[props.currentUserType].image
                        }`
                      : props.user[props.currentUserType].socialImage || ""
                  }
                />
              </Box>
              <Box
                sx={{
                  ml: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  variant={"h3"}
                  style={{
                    color: "#fff",
                    lineHeight: 1.1,
                    fontWeight: "bold",
                  }}
                >
                  {[userType.PERSONAL, userType.TALENT].includes(
                    props.currentUserType
                  )
                    ? `${props.user.firstName} ${props.user.lastName || ""}`
                    : props.currentUserType === userType.BUSINESS
                    ? props.user[userType.BUSINESS].companyName
                    : props.user[userType.FREELANCER].businessName}
                </Typography>

                <Typography variant={"subtitle1"} style={{ color: "#fff" }}>
                  {
                    FieldsStore.getFieldByUserAndType(
                      props.user,
                      props.currentUserType
                    )?.name
                  }
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    "& .MuiIconButton-root, & .MuiButton-root": {
                      backgroundColor: "rgba(255,255,255,0.4)",
                      mr: 1,
                      mt: 2,
                      color: "#fff",
                      border: 0,
                    },
                    "& .MuiButton-root": {
                      borderRadius: "20px",
                    },
                    "& .MuiIconButton-root:hover, & .MuiButton-root:hover": {
                      backgroundColor: "rgba(255,255,255,0.6)",
                      border: 0,
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<Share />}
                    onClick={handleOpenShare}
                  >
                    Share my profile
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {isMobile && (
        <Box
          name={"mobile-name-and-field"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100vw",
            position: "relative",
            top: "-50vw",
            height: "50vw",
          }}
        >
          <Avatar
            sx={{
              width: "60vw",
              height: "60vw",
              objectFit: "cover",
              backgroundColor: theme.palette.body.avatar,
              fontSize: "2em",
              border: ".2em solid #fff",
            }}
            alt={props.user.firstName}
            src={
              props.currentUserType && props.user[props.currentUserType].image
                ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${
                    props.user[props.currentUserType].image
                  }`
                : props.user[props.currentUserType].socialImage || ""
            }
          />

          <Box
            sx={{
              ml: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h4"}
              style={{
                color: theme.palette.body.text,
                lineHeight: 1.1,
                fontWeight: "bold",
              }}
            >
              {[userType.PERSONAL, userType.TALENT].includes(
                props.currentUserType
              )
                ? `${props.user.firstName} ${props.user.lastName || ""}`
                : props.currentUserType === userType.BUSINESS
                ? props.user[userType.BUSINESS].companyName
                : props.user[userType.FREELANCER].businessName}
            </Typography>

            <Typography
              variant={"h6"}
              style={{ color: theme.palette.body.text }}
            >
              {
                FieldsStore.getFieldByUserAndType(
                  props.user,
                  props.currentUserType
                )?.name
              }
            </Typography>

            <Box
              sx={{
                display: "flex",
                "& .MuiIconButton-root, & .MuiButton-root": {
                  textTransform: "none",

                  backgroundColor: theme.palette.body.foreground,
                  mr: 1,
                  mt: 2,
                  color: theme.palette.body.text,
                },
                "& .MuiButton-root": {
                  borderRadius: "20px",
                },
                "& .MuiIconButton-root:hover, & .MuiButton-root:hover": {
                  backgroundColor: theme.palette.body.foreground,
                },
              }}
            >
              <Button
                variant="outlined"
                size={"medium"}
                startIcon={
                  <Share
                    sx={{ color: theme.palette[props.currentUserType]?.main }}
                  />
                }
                onClick={handleOpenShare}
              >
                Share my profile
              </Button>
              {props.isSelfView && (
                <Button
                  variant="outlined"
                  size={"medium"}
                  startIcon={
                    <Edit
                      sx={{ color: theme.palette[props.currentUserType]?.main }}
                    />
                  }
                  onClick={() => history.push("/edit")}
                  sx={{ height: "3em" }}
                >
                  Edit Profile
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
