import React, { useEffect, useState } from "react";
import * as reactionsActions from "../../../actions/reactionsActions";
import { searchType } from "../../../shared/searchType";
import { userType } from "../../../shared/userType";
import FavoriteItem from "./FavoriteItem";
import CONF from "../../../configurations/applicationConf";
import videoStore from "../../../stores/videoStore";
import { Box, Button, Card, CardActions } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useHistory } from "react-router-dom";

export default function FavoritesList(props) {
  const history = useHistory();

  const currentUserType = props.currentUserType;
  const emptyImageSrc = "https://svgshare.com/i/ZPc.svg";

  const [pagination, setPagination] = useState({ number: 0, size: 15 });
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(videoStore.getIsMuted());

  useEffect(() => {
    videoStore.addChangeListener(updateVideoOptions);

    return () => videoStore.removeChangeListener(updateVideoOptions);
  }, []);

  useEffect(() => {
    if (currentUserType) {
      reactionsActions.getFavorites(currentUserType, pagination).then((res) => {
        if (res) {
          initFavorites(res.favorites);
        }

        setIsLoading(false);
      });
    }
  }, [currentUserType]);

  function updateVideoOptions() {
    setIsVideoMuted(videoStore.getIsMuted());
  }

  function initFavorites(favoritesData) {
    let favoritesArray = [];
    favoritesData.forEach((item) => {
      if (item.entity) {
        switch (item.type) {
          case searchType.JOB_SEARCH: {
            favoritesArray.push({
              _id: item.entity._id,
              video: `${CONF.AWS_ACCESS_BUCKET_URL}/videos/${item.entity.video}#t=1`,
              entity: item.entity,
              link: `view/vacancy/${item.entity._id}`,
              type: item.type,
            });

            break;
          }
          case userType.BUSINESS:
          case userType.PERSONAL:
          case userType.TALENT:
          case userType.FREELANCER: {
            favoritesArray.push({
              _id: item.entity._id,
              video: `${CONF.AWS_ACCESS_BUCKET_URL}/videos/${
                item.entity[item.type].videos[0]
              }#t=0.1`,
              entity: item.entity,
              link: `${item.type}/${item.entity.username}`,
              type: item.type,
            });

            break;
          }
        }
      }
    });

    setFavorites(favoritesArray);
  }

  function handleRemoveFromFavorites(item) {
    setFavorites(favorites.filter((fav) => fav._id !== item._id));
    reactionsActions.addOrRemoveFavorite(currentUserType, item.type, item._id);
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {!isLoading ? (
        favorites.length > 0 ? (
          favorites.map((item) => (
            <FavoriteItem
              key={item._id}
              item={item}
              height={200}
              isMuted={isVideoMuted}
              handleRemoveFromFavorites={handleRemoveFromFavorites}
            />
          ))
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Card
              sx={{
                maxWidth: "35%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 5,
              }}
            >
              <img style={{ maxWidth: "60%" }} src={emptyImageSrc} />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant={"h6"}>Get started</Typography>
                <Typography
                  align={"center"}
                  variant={"subtitle1"}
                  color={"textSecondary"}
                >
                  Find the best talents and freelancers to see them here.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => history.push("/search")}
                >
                  FIND FAVORITES
                </Button>
              </CardActions>
            </Card>
          </Box>
        )
      ) : (
        <Skeleton variant="rect" />
      )}
    </Box>
  );
}
