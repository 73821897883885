import React, { useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Avatar, Box, Paper, Typography, useTheme } from "@mui/material";

function MessagesDisplay(props) {
  const theme = useTheme();
  const bottomSection = useRef(null);

  function scrollToBottom() {
    let msgBoard = document.querySelector("#messageBoard");

    msgBoard.scrollTo({
      top: bottomSection.current.offsetTop,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    scrollToBottom();
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {props.messages.length > 0 &&
        props.messages.map((msg) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems:
                msg.sender.id === props.currentUser.id
                  ? "flex-end"
                  : "flex-start",
            }}
            key={msg.time}
          >
            <Box
              sx={{
                mb: 1,
                display: "flex",
                alignItems: "center",
                flexDirection:
                  msg.sender.id === props.currentUser.id
                    ? "row-reverse"
                    : "row",
              }}
            >
              <Avatar
                src={
                  msg.sender.id === props.currentUser.id
                    ? props.currentUser.avatarSrc
                    : props.receiver.avatarSrc
                }
                alt={
                  msg.sender.id === props.currentUser.id
                    ? props.currentUser.name
                    : props.receiver.name
                }
                sx={{
                  border: `2px solid ${
                    theme.palette[
                      msg.sender.id === props.currentUser.id
                        ? props.currentUser.type
                        : props.receiver.type
                    ].main
                  }`,
                }}
              />

              <Typography variant={"body1"} sx={{ mx: 1 }}>
                {msg.sender.id === props.currentUser.id
                  ? props.currentUser.name
                  : props.receiver.name}
              </Typography>
            </Box>
            <Paper
              elevation={1}
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                marginBottom: "1em",
                padding: ".4em",
                maxWidth: "100%",
                overflowX: "hidden",
                px: 3,
                py: 2,
                // color: msg.sender.id === props.currentUser.id ? "#fff" : "#000",
              }}
            >
              <Typography variant={"body2"} sx={{ fontWeight: 100 }}>
                {msg.content}
              </Typography>
            </Paper>
          </Box>
        ))}
      {props.isTyping && (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              marginBottom: "1em",
              padding: ".4em",
              maxWidth: "100%",
              overflowX: "hidden",
              backgroundColor: "#dedede",
            }}
          >
            typing...
          </Box>
        </Box>
      )}
      <Box ref={bottomSection} />
    </Box>
  );
}

MessagesDisplay.propTypes = {
  currentUser: PropTypes.any.isRequired,
  messages: PropTypes.array.isRequired,
  isTyping: PropTypes.bool,
};

MessagesDisplay.defaultProps = {
  messages: [],
  isTyping: false,
};

export default MessagesDisplay;
