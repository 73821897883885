import React, { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ChatOutlined,
  FavoriteBorderOutlined,
  ViewModuleOutlined,
} from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { IsMobileContext } from "../../providers/IsMobileService";

function NavigationTabs(props) {
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  const [value, setValue] = useState(props.selectedTab);

  function handleChange(value, newVal) {
    setValue(newVal);

    localStorage.setItem(props.navigationTabItemName, newVal);

    props.handleClick(newVal);
  }

  return (
    <Box
      sx={{
        borderBottom: "1px solid #ddd",
        width: isMobile ? "100vw" : "65vw",
      }}
    >
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        sx={{
          ".MuiTab-root": {
            mx: isMobile ? 1 : 4,
          },
          ".MuiTab-root.Mui-selected": {
            color: theme.palette[props.color]?.main,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette[props.color]?.main,
          },
        }}
        aria-label="home page icon tabs"
      >
        <Tab
          sx={{ textTransform: "none" }}
          iconPosition="start"
          icon={<ViewModuleOutlined />}
          value={"fed"}
          label={"Feed"}
          aria-label="feed"
        />

        <Tab
          sx={{ textTransform: "none" }}
          iconPosition="start"
          icon={<FavoriteBorderOutlined />}
          value="fav"
          label={"Favorites"}
          aria-label="favorite"
        />

        {/*<Tab*/}
        {/*  icon={<Whatshot />}*/}
        {/*  value="top"*/}
        {/*  label="TOP VIEWED"*/}
        {/*  aria-label="top"*/}
        {/*/>*/}

        {/*<Tab*/}
        {/*  icon={<RecentActors />}*/}
        {/*  value={"rec"}*/}
        {/*  label={isMobile ? "" : "RECENT"}*/}
        {/*  aria-label="recent"*/}
        {/*/>*/}

        <Tab
          sx={{ textTransform: "none" }}
          iconPosition="start"
          icon={<ChatOutlined />}
          value={"msg"}
          label={"Messages"}
          aria-label="messages"
        />
      </Tabs>
    </Box>
  );
}

export default NavigationTabs;
