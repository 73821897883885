import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import Webcam from "@uppy/webcam";
import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import userStore from "../../../../stores/userStore";
import * as userActions from "../../../../actions/userActions";
import { Storage } from "aws-amplify";
import * as PropTypes from "prop-types";
import { Box, Button, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { openSnackbar } from "../../../../shared/Notifier";
import AlertModal from "../../AlertModal/AlertModal";

function CircularProgressWithLabel(props) {
  return null;
}

CircularProgressWithLabel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
};

export default function VideoUpload(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [alertModalState, setAlertModalState] = useState({
    isOpen: false,
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uppy, setUppy] = useState("");

  useEffect(() => {
    const setup = new Uppy({
      id: "uppy1",
      meta: { type: "video" },
      autoProceed: false,
      restrictions: {
        maxFileSize: 1024 * 1024 * 500,
        maxNumberOfFiles: props.maxVideo - props.currUploaded || 0,
        minNumberOfFiles: 1,
        allowedFileTypes: ["video/*"],
      },
    });
    setup.use(Webcam, {
      onBeforeSnapshot: () => Promise.resolve(),
      countdown: true,
      modes: ["video-audio"],
      mirror: true,
      facingMode: "user",
      showRecordingLength: true,
      preferredVideoMimeType: null,
      preferredImageMimeType: null,
      locale: {},
    });

    setup.on("file-added", (file) => {
      Storage.configure({
        AWSS3: {
          bucket: process.env.REACT_APP_S3_VIDEO_UPLOAD_BUCKET,
          region: "us-east-1",
        },
      });

      let filename = file.name.slice(0, -(file.extension.length + 1));
      setIsUploading(true);

      setup.reset();
      handleClose();

      userActions
        .addUserVideo(filename, userStore.getCurrentType())
        .then((updatedFilename) => {
          file.name = updatedFilename + "." + file.extension;

          Storage.put(file.name, file.data, {
            progressCallback(progress) {
              let percent = (progress.loaded * 100) / progress.total;

              if (percent < 100) {
                setUploadProgress(percent);
              } else {
                setIsUploading(false);
                setUploadProgress(0);
                openSnackbar(
                  "Video uploaded successfully, soon it will be added to your profile!",
                  "success"
                );
              }
            },
          });
        });
    });

    setUppy(setup);
  }, []);

  function handleUpload() {
    setAlertModalState({
      dialogTitleText: "Important",
      messageText: `Shoot horizontal videos up to 500mb`,
      isOpen: true,
      handleReject: () => {
        setAlertModalState({ isOpen: false });
      },
      handleApprove: () => {
        handleOpen();
        setAlertModalState({ isOpen: false });
      },
    });
  }

  function handleOpen() {
    setModalOpen(true);
  }
  function handleClose() {
    setModalOpen(false);
  }

  return (
    <>
      {uppy && (
        <DashboardModal
          uppy={uppy}
          open={modalOpen}
          closeModalOnClickOutside
          onRequestClose={handleClose}
          replaceTargetContent
          showProgressDetails
          waitForThumbnailsBeforeUpload
          animateOpenClose
          note={`Shoot horizontal videos up to 500mb`}
          height="470"
          browserBackButtonClose
          plugins={["Webcam"]}
          locale={{
            strings: {
              dropHereOr: "Drop here or %{browse}",
              browse: "browse",
            },
          }}
        />
      )}

      <AlertModal {...alertModalState} />

      {!isUploading ? (
        <>
          <Fab
            size="small"
            aria-label="add"
            onClick={handleUpload}
            // variant="contained"
            // color={"personal"}
            sx={{
              color: "#fff",
              backgroundColor: props.userColor.main,
              "&:hover": { backgroundColor: props.userColor.dark },
            }}
          >
            <AddIcon />
          </Fab>
          <Box onClick={handleUpload}>{props.label || ""}</Box>
        </>
      ) : (
        <Box>
          <CircularProgress />
          <Box
            top={0}
            bottom={"0.2em"}
            left={"0.2em"}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >{`${Math.round(uploadProgress)}%`}</Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
