import React, { useEffect, useState } from "react";
import HomePage from "../HomePage/HomePage";
import RegistrationPage from "../Register/RegistrationPage";
import { Route, Switch } from "react-router-dom";
import * as userActions from "../../actions/userActions";
import EditPage from "../EditPage/EditPage";
import UserViewPage from "../ViewPage/UserViewPage";
import VacancyViewPage from "../ViewPage/VacancyViewPage";
import Fabs from "../Fabs/Fabs";
import { Notifier } from "../../shared/Notifier";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import SearchPage from "../common/Search/SearchPage";
import Navigation from "../common/Navigation/Navigation";
import FeedPage from "../FeedPage/FeedPage";
import VacancyManage from "../VacancyManage/VacancyManage";

import Amplify, { Auth } from "aws-amplify";
import conf from "../../configurations/applicationConf";
import LoginPage from "../Login/LoginPage";
import PasswordReset from "../Login/PasswordReset";
import Settings from "../Settings/Settings";
import IsMobileProvider from "../../providers/IsMobileService";

Amplify.configure(conf.AMPLIFY_CONFIG);

function App() {
  const appTheme = createTheme({
    components: {
      MuiTypography: {
        defaultProps: {
          fontFamily: "Poppins",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.5,
          letterSpacing: "normal",
          textAlign: "left",
          color: "#000",
        },
      },
    },
    sizing: {
      mainView: {
        width: 1650,
      },
      sideNav: {
        width: 230,
      },
      navBar: {
        height: "116px",
      },
    },
    palette: {
      body: {
        main: "#c5c4c4",
        dark: "#333",

        background: "#f6f8fc",
        foreground: "#fff",
        info: "#f9fbec",
        placeholder: "#221e1f",
        avatar: "#c5c4c4",
        text: "#092223",
        lightText: "#bab8b9",
      },
      sideNav: {
        main: "#c5c4c4",
        dark: "#333",

        headers: "#757575",
        labels: "#171725",
        linkLabel: "#092223",
        icons: "#7e84a3",
      },
      footer: {
        bright: "#f3f3eb",
        dark: "#707070",
      },
      buttons: {
        main: "#c5c4c4",
        dark: "#333",

        lightBorder: "#c5c4c4",
        text: "#092223",
      },

      default: {
        main: "#c5c4c4",
        dark: "#092223",
        contrastText: "#092223",
      },

      white: {
        main: "#fff",
        dark: "#fff",
        contrastText: "#fff",
      },

      personal: {
        main: "#764ebb",
        dark: "#4d2592",
        contrastText: "#fff",
        hover: "rgba(118,78,187,0.1)",
      },
      talent: {
        // light: "#e42b76",
        main: "#d81b60",
        dark: "#930c3e",
        contrastText: "#fff",

        hover: "rgba(205,25,91,0.1)",
      },
      business: {
        // light: "#22c2e2",
        main: "#1cb9d9",
        dark: "#0e869e",
        contrastText: "#fff",

        hover: "rgba(26,174,204,0.1)",
      },
      freelancer: {
        // light: "#45d3ad",
        main: "#39d0a8",
        dark: "#1ea581",
        contrastText: "#fff",

        hover: "rgba(47,202,161,0.1)",
      },
    },
  });

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  const [session, setSession] = useState(false);
  const [refresh, setRefresh] = useState(false);

  function handleRefresh() {
    setRefresh(!refresh);
  }

  useEffect(() => {
    Auth.currentSession()
      .then((userData) => {
        //TODO: check if session expired & email verified, call refresh() else login()
        userActions.tokenLoginUser(userData).catch((error) => {
          console.log(error);
        });

        setSession(true);
      })
      .catch(() => {
        setSession(false);
      });
  }, [refresh]);

  return (
    <ThemeProvider theme={appTheme}>
      <IsMobileProvider>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Notifier />
          <Fabs />
          <Navigation handleLogin={handleRefresh} />
          <Box
            sx={{
              mt: appTheme.sizing.navBar.height,
              maxWidth: session ? appTheme.sizing.mainView.width : "100vw",
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
            }}
          >
            <Box
              sx={{
                width:
                  !session || isMobile
                    ? "100vw"
                    : `calc(100% - ${appTheme.sizing.sideNav.width - 9}px)`,
              }}
            >
              <Switch>
                <Route
                  path="/"
                  exact
                  component={session ? FeedPage : HomePage}
                />
                <Route path="/register" exact component={RegistrationPage} />
                <Route
                  path="/login"
                  exact
                  render={() => <LoginPage handleLogin={handleRefresh} />}
                />
                <Route path="/recovery" exact component={PasswordReset} />
                <Route path="/search" exact component={SearchPage} />
                <Route
                  path="/profile"
                  exact
                  component={session ? FeedPage : HomePage}
                />
                <Route
                  path="/edit"
                  exact
                  component={session ? EditPage : HomePage}
                />
                <Route
                  path="/settings"
                  exact
                  component={session ? Settings : HomePage}
                />
                <Route
                  path="/view/vacancy/:vacancyId/"
                  component={VacancyViewPage}
                />
                <Route
                  exact
                  path="/:userType/:username/"
                  component={UserViewPage}
                />
                <Route
                  path="/vacancyManage/"
                  component={session ? VacancyManage : HomePage}
                />
              </Switch>
            </Box>
          </Box>
        </Box>
      </IsMobileProvider>
    </ThemeProvider>
  );
}

export default App;
