import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Slide, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationDialog(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "inline",
    },
    label: {
      display: "inline",
      color: "inherit",
      cursor: "pointer",
      "&:hover": {
        textDecoration: props.link ? "underline" : "inherit",
      },
    },
  }));

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  function handleOpen(e) {
    e.preventDefault();

    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <>
      <Box onClick={handleOpen}>{props.children}</Box>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.dialogTitleText || props.label}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {props.bodyText || "Information text area"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {props.buttonText || "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
