import React, { useContext, useEffect, useState } from "react";
import { CONST } from "../../../shared/consts";
import SearchResult from "./SearchResult";
import searchStore from "../../../stores/searchStore";
import { useHistory } from "react-router-dom";
import * as searchActions from "../../../actions/searchActions";
import FiltersContainer from "./FiltersContainer";
import { searchType } from "../../../shared/searchType";
import userStore from "../../../stores/userStore";
import videoStore from "../../../stores/videoStore";
import CollisionObserver from "../CollisionObserver/CollisionObserver";
import DevPrintBox from "../../Development/DevPrintBox";
import * as filterActions from "../../../actions/filterActions";
import filterStore from "../../../stores/filterStore";
import Skeleton from "@mui/material/Skeleton";
import { debounce } from "lodash";
import MetaTags from "react-meta-tags";
import { Box, useTheme } from "@mui/material";
import * as reactionsActions from "../../../actions/reactionsActions";
import { IsMobileContext } from "../../../providers/IsMobileService";

export default function SearchPage() {
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);
  const history = useHistory();

  const [isVideoMuted, setIsVideoMuted] = useState(videoStore.getIsMuted());

  const [searchState, setSearchState] = useState({
    filters: filterStore.getCurrentFilters(),
    displayResults: searchStore.getResults(),
  });

  const [currentUserType] = useState(
    history?.location?.state?.isDemo
      ? history.location.state.userType
      : userStore.getCurrentType()
  );

  useEffect(() => {
    searchStore.addChangeListener(updateResults);
    filterStore.addChangeListener(updateFilters);
    userStore.addChangeListener(updateFilters);
    videoStore.addChangeListener(updateVideoOptions);

    // Get searchType from store and make clean search with it
    updateFilters();

    return () => {
      if (history.location?.pathname !== "/search") {
        searchStore.removeChangeListener(updateResults);
        filterStore.removeChangeListener(updateFilters);
        userStore.removeChangeListener(updateFilters);
        videoStore.removeChangeListener(updateVideoOptions);
      }
    };
  }, []);

  function updateVideoOptions() {
    setIsVideoMuted(videoStore.getIsMuted());
  }

  function updateResults() {
    setSearchState({
      filters: filterStore.getCurrentFilters(),
      displayResults: searchStore.getResults(),
    });
  }

  function updateFilters() {
    searchActions.search(filterStore.getCurrentFilters());
  }

  function resetFilters() {
    filterActions.resetFilters();
  }

  function handleNextPage() {
    // Only if results are appearing
    if (searchState.displayResults.length !== 0) {
      // if there are lastResults returned
      if (
        searchStore.getResults().length !== 0 &&
        !searchStore.isEndOfResults()
      ) {
        const currentFilters = filterStore.getCurrentFilters();

        filterActions.setFilters({
          ...currentFilters,
          currentPage: ++currentFilters.currentPage,
        });
      }
    }
  }

  function handleVacancyView(vacancy) {
    window.open(`/view/vacancy/${vacancy._id}`, "_blank");
  }
  function handleUserView(user) {
    window.open(
      `/${searchState.filters.searchType}/${user.username}`,
      "_blank"
    );
  }

  function handleAddOrRemoveFavorite(item) {
    reactionsActions.addOrRemoveFavorite(currentUserType, item.type, item._id);
  }

  return (
    <Box sx={{ ml: !isMobile && 2, width: isMobile ? "100vw" : "100%" }}>
      <MetaTags>
        <title>{`Search Results | GoGeThem`}</title>
      </MetaTags>
      <DevPrintBox
        displayParams={[
          {
            name: "currentPage",
            value: searchState.filters.currentPage,
          },
          {
            name: "end?",
            value: searchStore.isEndOfResults() + "",
          },
          {
            name: "searchType",
            value: searchState.filters.searchType,
          },
          {
            name: "field",
            value: searchState.filters.field,
          },
        ]}
      />

      <Box
        name={"filters"}
        sx={{
          position: "sticky",
          top: theme.sizing.navBar.height,
          height: "64px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          py: 1,
          px: 3,
          mb: 4,
          borderBottom: "1px solid #ddd",
          userSelect: "none",
          width: "100%",
          zIndex: 9,
        }}
      >
        {searchState.filters && (
          <FiltersContainer
            filters={searchState.filters}
            resetFilters={resetFilters}
            userType={currentUserType}
          />
        )}
      </Box>

      <Box
        name={"search-results"}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {searchState.displayResults?.length > 0 &&
          searchState.filters.searchType && (
            <>
              {[
                searchType.TALENT_SEARCH,
                searchType.FREELANCER_SEARCH,
                searchType.BUSINESS_SEARCH,
                searchType.PERSONAL_SEARCH,
              ].includes(searchState.filters.searchType) &&
                searchState.displayResults.map((user) => (
                  <SearchResult
                    key={searchState.filters.searchType + user._id}
                    resultType={searchState.filters.searchType}
                    isMuted={isVideoMuted}
                    result={user}
                    handleView={handleUserView}
                    handleAddOrRemoveFavorite={handleAddOrRemoveFavorite}
                  />
                ))}
              {searchState.filters.searchType === searchType.JOB_SEARCH &&
                searchState.displayResults.map((vacancy) => (
                  <SearchResult
                    key={searchState.filters.searchType + vacancy._id}
                    resultType={searchState.filters.searchType}
                    isMuted={isVideoMuted}
                    result={vacancy}
                    handleView={handleVacancyView}
                    handleAddOrRemoveFavorite={handleAddOrRemoveFavorite}
                  />
                ))}
              {!searchStore.isEndOfResults() && (
                <CollisionObserver
                  callback={debounce(handleNextPage, 2000)}
                  divForIntersection={"end-of-page"}
                >
                  <div id={"end-of-page"} />
                  {searchState.filters.currentPage !== 0 && (
                    <>
                      {[...Array(15)].map((e, i) => (
                        <Skeleton
                          key={i}
                          variant="rect"
                          sx={{
                            height: 300,
                            marginBottom: "2em",
                            marginLeft: 12,
                            marginRight: 12,
                            [theme.breakpoints.up(CONST.SM)]: {
                              width: "calc((100% - 72px) / 3)",
                            },
                            [theme.breakpoints.down(CONST.SM)]: {
                              width: "100%",
                            },
                          }}
                        />
                      ))}
                    </>
                  )}
                </CollisionObserver>
              )}
            </>
          )}
      </Box>
    </Box>
  );
}
