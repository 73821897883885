import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import FieldsStore from "../../stores/FieldsStore";
import { getFields } from "../../actions/fieldsActions";
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import NumberFormatCustom from "../common/NumberFormat/NumberFormanCustom";
import HorizontalLinearStepper from "../common/Stepper/HorizontalLinearStepper";
import VacancyVideoUpload from "../common/VideoManage/VacancyVideoManage/VacancyVideoUpload";
import { CONST } from "../../shared/consts";
import Geolocation from "../common/Geolocation/GooglePlaces";

function AddOrEditVacancyDialog(props) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    dialogContent: {
      width: 550,
    },
    fullWidth: {
      width: "100%",
    },
  }));
  const classes = useStyles();

  const [vacancy, setVacancy] = useState(props.vacancy || {});
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    getValues,
  } = useForm({
    defaultValues: vacancy,
    shouldFocusError: true,
  });

  const [shouldNext, setShouldNext] = useState(false);

  const [fields, setFields] = useState(FieldsStore.getFields());
  const [roles, setRoles] = useState(
    FieldsStore.getFields()?.find((f) => f.name === vacancy?.field)?.roles || ""
  );

  const [address, setAddress] = useState(vacancy?.address || "");

  useEffect(() => {
    FieldsStore.addChangeListener(fieldsChanged);

    if (FieldsStore.getFields().length === 0) {
      getFields();
    }

    return () => FieldsStore.removeChangeListener(fieldsChanged);
  }, []);

  function fieldsChanged() {
    setFields(FieldsStore.getFields());
  }

  function getSteps() {
    return ["Basic info", "Additional details", "Video upload"];
  }

  function toggleShouldNextOff() {
    setShouldNext(false);
  }

  function handleFirst(event) {
    let updatedVacancy = {};
    setShouldNext(true);

    Object.assign(updatedVacancy, vacancy, event);
    setVacancy(updatedVacancy);

    props.handleSubmit(updatedVacancy, true, false, props.vacancyId);
  }

  function handleNext(event) {
    let updatedVacancy = {};
    setShouldNext(true);

    if (event?.salary) {
      event.salary = event.salary.replace(/[,]|[$]/g, "");
    }

    Object.assign(updatedVacancy, vacancy, event);
    setVacancy(updatedVacancy);

    props.handleSubmit(updatedVacancy, false, false, props.vacancyId);
  }

  function handleFinish(updatedVacancy) {
    props.handleClose();
    props.handleSubmit(updatedVacancy, false, true, props.vacancyId);
  }

  function handleModalClose() {
    if (vacancy) {
      props.handleClose(vacancy);
    }
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <form onSubmit={handleSubmit(handleFirst)} id="requiredInfoForm">
            <TextField
              label="Vacancy Name"
              name="name"
              type="text"
              inputRef={register({ required: true })}
              error={!!errors.name}
              helperText={errors.name && "Enter the vacancy name to continue"}
              margin="dense"
              fullWidth
            />

            <FormControl
              className={`${classes.formControl} ${classes.fullWidth}`}
              error={!!errors.field}
            >
              <InputLabel id={"field-label"}>Field</InputLabel>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    labelId={"field-label"}
                    onChange={(e) => {
                      onChange(e);
                      let field = fields.find((f) => f.name === e.target.value);

                      setRoles(field.roles);
                    }}
                    value={value ? value : ""}
                  >
                    {fields &&
                      fields.map((prof) => (
                        <MenuItem key={prof.id} value={prof.name}>
                          <ListItemText primary={prof.name} />
                        </MenuItem>
                      ))}
                  </Select>
                )}
                name={"field"}
                control={control}
                rules={{ required: true }}
                defaultValue={""}
              />
            </FormControl>

            {roles && roles.length > 0 && (
              <FormControl
                className={`${classes.formControl} ${classes.fullWidth}`}
                error={!!errors.role}
              >
                <InputLabel id={"role-label"}>Role</InputLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      labelId={"role-label"}
                      label={"Role"}
                      onChange={onChange}
                      value={value ? value : ""}
                    >
                      {roles.map((rol) => (
                        <MenuItem key={rol.id} value={rol.name}>
                          <ListItemText primary={rol.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  name={"role"}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                />
              </FormControl>
            )}

            <TextField
              label="Vacancy Number"
              name="number"
              type="text"
              inputRef={register()}
              margin="dense"
              fullWidth
            />

            <FormControl
              className={classes.formControl}
              error={!!errors.address}
              fullWidth
            >
              <Geolocation
                label="Where is the job"
                address={address}
                handleChange={({ target }) => {
                  setValue("address", target.value, {
                    shouldValidate: true,
                  });
                  setAddress(target.value);
                }}
              />
            </FormControl>
            <TextField
              label="About"
              name={"about"}
              type="text"
              inputRef={register({ required: true })}
              error={!!errors.about}
              helperText={
                errors.about && "Tell about the vacancy to publish it"
              }
              multiline
              minRows={5}
              maxRows={15}
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </form>
        );
      case 1:
        return (
          <form onSubmit={handleSubmit(handleNext)} id="optionalInfoForm">
            <TextField
              label="Requirements"
              name={"requirements"}
              type="text"
              inputRef={register({ maxLength: CONST.TEXT_FIELD_MAX_LENGTH })}
              error={!!errors.requirements}
              helperText={errors.requirements && "maximum 5000 characters"}
              multiline
              minRows={5}
              maxRows={15}
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <FormControl fullWidth>
              <FormControlLabel
                label="Driving license required"
                control={
                  <Controller
                    as={<Checkbox size={"small"} />}
                    name={"isDrivingLicenseRequired"}
                    control={control}
                    defaultValue={false}
                  />
                }
              />
            </FormControl>

            <div>
              <TextField
                label="Salary"
                name={"salary"}
                inputRef={register({
                  min: 0,
                })}
                // InputProps={{
                //   inputComponent: NumberFormatCustom,
                // }}
                margin="normal"
                defaultValue={getValues("salary")}
              />

              <FormControl
                margin="normal"
                style={{ marginLeft: 16 }}
                className={classes.formControl}
              >
                <InputLabel id={"salary-label"}>Salary period</InputLabel>
                <Controller
                  as={
                    <Select labelId={"salary-label"} label={"Salary Period"}>
                      <MenuItem key={"daily"} value={"daily"}>
                        <ListItemText primary={"Daily"} />
                      </MenuItem>
                      <MenuItem key={"weekly"} value={"weekly"}>
                        <ListItemText primary={"Weekly"} />
                      </MenuItem>
                      <MenuItem key={"monthly"} value={"monthly"}>
                        <ListItemText primary={"Monthly"} />
                      </MenuItem>
                      <MenuItem key={"yearly"} value={"yearly"}>
                        <ListItemText primary={"Yearly"} />
                      </MenuItem>
                    </Select>
                  }
                  name={"salaryPeriod"}
                  control={control}
                  defaultValue={"monthly"}
                />
              </FormControl>
            </div>

            <FormControl margin="normal" className={classes.formControl}>
              <InputLabel id={"vacancy-label"}>Vacancy type</InputLabel>

              <Controller
                as={
                  <Select labelId={"vacancy-label"}>
                    <MenuItem value={"full"}>
                      <ListItemText primary={"Full"} />
                    </MenuItem>
                    <MenuItem value={"partial"}>
                      <ListItemText primary={"Partial"} />
                    </MenuItem>
                    <MenuItem value={"shifts"}>
                      <ListItemText primary={"Shifts"} />
                    </MenuItem>
                    <MenuItem value={"nights"}>
                      <ListItemText primary={"Nights"} />
                    </MenuItem>
                    <MenuItem value={"weekends"}>
                      <ListItemText primary={"Weekends"} />
                    </MenuItem>
                    <MenuItem value={"student"}>
                      <ListItemText primary={"Student"} />
                    </MenuItem>
                    <MenuItem value={"temporary"}>
                      <ListItemText primary={"Temporary"} />
                    </MenuItem>
                    <MenuItem value={"longTerm"}>
                      <ListItemText primary={"Long term"} />
                    </MenuItem>
                  </Select>
                }
                name={"vacancyType"}
                control={control}
                defaultValue={"full"}
              />
            </FormControl>
            <FormControl style={{ marginLeft: 24, marginTop: "2em" }}>
              <FormControlLabel
                label="Immediate start"
                control={
                  <Controller
                    as={<Checkbox size={"small"} />}
                    name={"isImmediate"}
                    control={control}
                    defaultValue
                  />
                }
              />
            </FormControl>
          </form>
        );
      case 2:
        return (
          <VacancyVideoUpload
            vacancyId={props.vacancyId}
            uploadFinish={handleFinish}
          />
        );
      default:
        return "Unknown step";
    }
  }

  function getStepForm(step) {
    switch (step) {
      case 0:
        return "requiredInfoForm";
      case 1:
        return "optionalInfoForm";
      default:
        return "Unknown step";
    }
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.headerText}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <HorizontalLinearStepper
          shouldNext={shouldNext}
          toggleShouldNextOff={toggleShouldNextOff}
          getStepForm={getStepForm}
          getStepContent={getStepContent}
          getSteps={getSteps}
          handleClose={handleModalClose}
        />
      </DialogContent>
    </Dialog>
  );
}

AddOrEditVacancyDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  vacancy: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

export default AddOrEditVacancyDialog;
