import React, { useEffect } from "react";
import PropTypes from "prop-types";

function CollisionObserver(props) {
  const observer = new IntersectionObserver(
    function (entries) {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if (entries[0].isIntersecting === true) {
        if (props.once) {
          observer.disconnect();
        }

        props.callback("#" + props.divForIntersection);
      }
    },
    { threshold: [props.threshold] }
  );

  useEffect(() => {
    let elem = document.querySelector("#" + props.divForIntersection);

    if (elem) {
      observer.observe(elem);
    }

    return () => observer.disconnect();
  }, []);

  return props.children;
}

CollisionObserver.propTypes = {
  once: PropTypes.bool,
  threshold: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  divForIntersection: PropTypes.string.isRequired,
};

CollisionObserver.defaultProps = {
  once: false,
  threshold: 0,
  divForIntersection: document.body,
};

export default CollisionObserver;
