import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import CONF from "../../../configurations/applicationConf";
import { Box, Paper } from "@mui/material";
import UserDetailsAndActions from "../UserDetailsAndActions/UserDetailsAndActions";
import { IsMobileContext } from "../../../providers/IsMobileService";

function FeedItem(props) {
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);

  const player = useRef();
  const item = props.item;
  const playerOptions = {
    controls: true,
    autoPlay: false,
    muted: true,
    loop: false,
    preload: "metadata",
    poster: `${CONF.AWS_ACCESS_BUCKET_URL}/resources/images/video.poster.png`,
  };

  function handleView() {
    // history.push(item.link);
    window.open(item.link, "_blank");
  }

  useEffect(() => {
    if (props.isPlaying === "#" + props.elemId) {
      player.current.play();
    } else {
      player.current.pause();
    }
  }, [props.isPlaying]);

  return (
    <Paper
      id={props.id}
      elevation={2}
      square
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        marginBottom: isMobile ? "1.5em" : "3em",
        height: "100%",
        width: "100%",
        p: isMobile ? 3 : 5,
        backgroundColor: "#fff",
      }}
    >
      <UserDetailsAndActions
        handleClick={handleView}
        item={item}
        like
        share
        avatar={{ size: isMobile ? "2em" : "3em" }}
        isFavorite={props.isFavorite}
        handleToggleAddToFavorites={props.handleToggleAddToFavorites}
      />
      <Box
        ref={player}
        component="video"
        {...playerOptions}
        src={item.video}
        sx={{
          mt: 2,
          width: "100%",
          aspectRatio: "16 / 9",
          backgroundColor: "#333",
        }}
      >
        <video />
      </Box>
    </Paper>
  );
}

FeedItem.propTypes = {
  item: PropTypes.any.isRequired,
};

export default FeedItem;
