import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormControl, FormLabel, TextField } from "@mui/material";
import NumberFormatCustom from "../../NumberFormat/NumberFormanCustom";

function SalaryFilter(props) {
  const useStyles = makeStyles((theme) => ({
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: ".5em",
    },
    input: {
      width: "48%",
    },
  }));
  const classes = useStyles();

  const [from, setFrom] = useState(props.from);
  const [to, setTo] = useState(props.to);

  return (
    <FormControl>
      <FormLabel>Salary range</FormLabel>
      <div className={classes.row}>
        <TextField
          className={classes.input}
          size="small"
          id="salaryFrom"
          name={"salaryFrom"}
          onChange={(e) => {
            setFrom(e.target.value);
            props.handleChange(e);
          }}
          label="From"
          variant="outlined"
          value={from}
          // InputProps={{
          //   inputComponent: NumberFormatCustom,
          // }}
        />
        <TextField
          className={classes.input}
          size="small"
          id="salaryTo"
          name={"salaryTo"}
          onChange={(e) => {
            setTo(e.target.value);
            props.handleChange(e);
          }}
          label="To"
          variant="outlined"
          value={to}
          // InputProps={{
          //   inputComponent: NumberFormatCustom,
          // }}
        />
      </div>
    </FormControl>
  );
}

SalaryFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default SalaryFilter;
