import React from "react";
import { makeStyles } from "@mui/styles";

export default function JobStatus(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      top: ".5em",
      bottom: ".5em",
      right: ".5em",
      width: ".3em",
      borderRadius: "4px",
      backgroundColor: props.isAvailable ? "green" : "red",
    },
  }));

  const classes = useStyles();

  return <div className={classes.root} />;
}
