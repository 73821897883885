import * as searchApi from "../api/searchApi";
import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";

export function search(filters) {
  return searchApi
    .search(filters)
    .then((data) => {
      dispatcher.dispatch({
        actionType:
          filters.currentPage === 0
            ? actionTypes.SET_RESULTS
            : actionTypes.UPDATE_RESULTS,
        results: data?.searchResults,
        isEndOfResults: data?.searchResults?.length === 0,
      });

      return data?.searchResults;
    })
    .catch((error) => {
      throw error;
    });
}
