import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { userType } from "../../../shared/userType";
import RegularText from "../Text/RegularText";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { searchType } from "../../../shared/searchType";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import * as videoActions from "../../../actions/videoActions";
import CONF from "../../../configurations/applicationConf";
import { Box } from "@mui/material";
import UserDetailsAndActions from "../UserDetailsAndActions/UserDetailsAndActions";
import { IsMobileContext } from "../../../providers/IsMobileService";

function FavoriteItem(props) {
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);

  const [muteButtonDisplay, setMuteButtonDisplay] = useState(false);

  const item = props.item;

  const playerOptions = {
    autoPlay: false,
    muted: props.isMuted,
    loop: true,
    preload: "metadata",
    poster: `${CONF.AWS_ACCESS_BUCKET_URL}/resources/images/video.poster.png`,
  };

  function handleView() {
    // history.push(item.link);
    window.open(item.link, "_blank");
  }

  function toggleMute(e) {
    e.preventDefault();

    // set an action that will update store and then dispatch to all application
    videoActions.toggleMute(!props.isMuted);
  }

  function play(e) {
    if (item.video && item.video.slice(-1) !== "#") {
      setMuteButtonDisplay(true);
      e.currentTarget.querySelector("video").play();
    }
  }

  function pause(e) {
    if (item.video && item.video.slice(-1) !== "#") {
      setMuteButtonDisplay(false);
      e.currentTarget.querySelector("video").pause();
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        marginBottom: "2em",
        width: isMobile ? "100%" : "calc((100% - 72px) / 3)",
        mx: isMobile ? 0 : "12px",
      }}
      onMouseEnter={play}
      onMouseLeave={pause}
    >
      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          top: ".5em",
          right: ".5em",
          transitionDuration: "0.2s",
          visibility: muteButtonDisplay ? "visible" : "hidden",
        }}
        onClick={toggleMute}
      >
        <IconButton color="secondary" aria-label="sound control">
          {props.isMuted ? <VolumeOff /> : <VolumeUp />}
        </IconButton>
      </Box>
      <Card>
        <Box>
          <CardMedia
            component="video"
            {...playerOptions}
            controls={!!(isMobile && item.video)}
            src={item.video}
            sx={{
              backgroundColor: "#333",
              width: "100%",
              aspectRatio: "16 / 9",
            }}
          >
            <video />
          </CardMedia>
          <CardContent>
            <UserDetailsAndActions
              handleClick={handleView}
              item={item}
              like
              avatar={{ size: "1.5em" }}
              isFavorite={true}
              handleToggleAddToFavorites={() => {
                props.handleRemoveFromFavorites(item);
              }}
            />
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}

FavoriteItem.propTypes = {
  item: PropTypes.any.isRequired,
};

export default FavoriteItem;
