import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { userType } from "../../../shared/userType";
import RegularText from "../Text/RegularText";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { searchType } from "../../../shared/searchType";
import CONF from "../../../configurations/applicationConf";
import FieldsStore from "../../../stores/FieldsStore";
import * as videoActions from "../../../actions/videoActions";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { CONST } from "../../../shared/consts";
import { Box, useTheme } from "@mui/material";
import { IsMobileContext } from "../../../providers/IsMobileService";
import UserDetailsAndActions from "../UserDetailsAndActions/UserDetailsAndActions";
import * as reactionsActions from "../../../actions/reactionsActions";

function SearchResult(props) {
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  const MAX_LENGTH = 76;

  const resultSearchType = props.resultType;
  const isMuted = props.isMuted;
  const result = props.result;

  let videoSrc = "#";

  const [muteButtonDisplay, setMuteButtonDisplay] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const playerOptions = {
    muted: isMuted,
    autoPlay: false,
    loop: true,
    preload: "metadata",
    poster: result[resultSearchType]?.image
      ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${result[resultSearchType].image}`
      : `${CONF.AWS_ACCESS_BUCKET_URL}/resources/images/video.poster.png`,
  };

  function toggleMute(e) {
    e.preventDefault();

    // set an action that will update store and then dispatch to all application
    videoActions.toggleMute(!isMuted);
  }

  if (resultSearchType === searchType.JOB_SEARCH) {
    if (result.video) {
      videoSrc = `${CONF.AWS_ACCESS_BUCKET_URL}/videos/${result.video}#t=0.1`;
    }
  } else {
    if (result[resultSearchType] && result[resultSearchType].videos[0]) {
      videoSrc = `${CONF.AWS_ACCESS_BUCKET_URL}/videos/${result[resultSearchType].videos[0]}#t=0.1`;
    }
  }

  function play(e) {
    if (videoSrc && videoSrc.slice(-1) !== "#") {
      setMuteButtonDisplay(true);
      e.currentTarget.querySelector("video").play();
    }
  }

  function pause(e) {
    if (videoSrc && videoSrc.slice(-1) !== "#") {
      setMuteButtonDisplay(false);
      e.currentTarget.querySelector("video").pause();
    }
  }

  function toggleAddToFavorites() {
    setIsFavorite(!isFavorite);
  }

  return (
    result &&
    (resultSearchType === searchType.JOB_SEARCH ||
      result.userType?.includes(resultSearchType)) && (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          mx: "12px",
          marginBottom: "2em",
          width: isMobile ? "100%" : "calc((100% - 72px) / 3)",
        }}
        onMouseEnter={play}
        onMouseLeave={pause}
      >
        <Box
          sx={{
            zIndex: 1,
            position: "absolute",
            top: ".5em",
            right: ".5em",
            transitionDuration: "0.2s",
            visibility: muteButtonDisplay ? "visible" : "hidden",
          }}
          onClick={toggleMute}
        >
          <IconButton color="secondary" aria-label="sound control">
            {isMuted ? <VolumeOff /> : <VolumeUp />}
          </IconButton>
        </Box>

        <Card>
          <Box>
            <CardMedia
              component="video"
              {...playerOptions}
              controls={!!(isMobile && videoSrc)}
              src={videoSrc}
              sx={{
                backgroundColor: "#333",
                width: "100%",
                aspectRatio: "16 / 9",
              }}
            >
              <video />
            </CardMedia>

            <CardContent>
              <UserDetailsAndActions
                handleClick={() => props.handleView(result)}
                item={{
                  entity: result,
                  type: resultSearchType,
                }}
                like
                avatar={{ size: "1.5em" }}
                isFavorite={isFavorite}
                handleToggleAddToFavorites={() => {
                  toggleAddToFavorites();
                  props.handleAddOrRemoveFavorite(result);
                }}
              />
            </CardContent>
          </Box>
        </Card>
      </Box>
    )
  );
}

SearchResult.propTypes = {
  result: PropTypes.any.isRequired,
  resultType: PropTypes.string.isRequired,
  handleView: PropTypes.func.isRequired,
};

export default SearchResult;
