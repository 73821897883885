import React, { createContext } from "react";

const IsMobileContext = createContext(null);

export { IsMobileContext };

let isMobile;

export default ({ children }) => {
  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  return (
    <IsMobileContext.Provider value={isMobile}>
      {children}
    </IsMobileContext.Provider>
  );
};
