import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { useHistory } from "react-router-dom";
import filterStore from "../../../stores/filterStore";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FieldsStore from "../../../stores/FieldsStore";
import * as fieldsActions from "../../../actions/fieldsActions";
import { searchType as searchTypes } from "../../../shared/searchType";
import { Box, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { userType } from "../../../shared/userType";
import { Autocomplete } from "@material-ui/lab";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import * as filterActions from "../../../actions/filterActions";
import { IsMobileContext } from "../../../providers/IsMobileService";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

function SearchInput(props) {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState(searchTypes.TALENT_SEARCH);

  const [fields, setFields] = useState(
    searchType === searchTypes.TALENT_SEARCH
      ? FieldsStore.getTalentFields()
      : searchType === searchTypes.FREELANCER_SEARCH
      ? FieldsStore.getFreelancerFields()
      : FieldsStore.getFields()
  );

  useEffect(() => {
    setSearchType(searchTypes.TALENT_SEARCH);
  }, [props.currentUserType]);

  useEffect(() => {
    if (FieldsStore.getFields().length === 0) {
      fieldsActions.getFields();
    }
    if (FieldsStore.getTalentFields().length === 0) {
      fieldsActions.getTalentFields();
    }
    if (FieldsStore.getFreelancerFields().length === 0) {
      fieldsActions.getFreelancerFields();
    }
  }, []);

  function handleTypeChange({ target }) {
    // Change search type
    setFields(
      target.value === searchTypes.TALENT_SEARCH
        ? FieldsStore.getTalentFields()
        : target.value === searchTypes.FREELANCER_SEARCH
        ? FieldsStore.getFreelancerFields()
        : FieldsStore.getFields()
    );

    filterActions.setFilters({
      ...filterStore.getEmptyFilters(),
      searchType: target.value,
    });

    // Change the input
    setSearchType(target.value);
  }

  async function handleSubmit(event) {
    let filters = {
      ...filterStore.getCurrentFilters(),
      searchType,
      searchQuery,
      currentPage: 0,
      field: event.filters?.field.id,
    };

    filterActions.setFilters(filters);

    history.push("/search");
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: isMobile ? "row-reverse" : "row",
      }}
    >
      <Select
        disableUnderline
        IconComponent={ExpandMoreRoundedIcon}
        sx={{
          mr: isMobile ? 0 : 3,
          fontSize: 18,
        }}
        variant={"standard"}
        value={searchType}
        defaultValue={searchTypes.TALENT_SEARCH}
        onChange={handleTypeChange}
      >
        {[userType.FREELANCER, userType.BUSINESS].includes(
          props.currentUserType
        ) && (
          <MenuItem value={searchTypes.PERSONAL_SEARCH}>
            <Typography>Candidate</Typography>
          </MenuItem>
        )}
        <MenuItem value={searchTypes.TALENT_SEARCH}>
          <Typography>Talent</Typography>
        </MenuItem>
        <MenuItem value={searchTypes.FREELANCER_SEARCH}>
          <Typography>Freelancer</Typography>
        </MenuItem>
        <MenuItem value={searchTypes.BUSINESS_SEARCH}>
          <Typography>Company</Typography>
        </MenuItem>
        {[userType.TALENT, userType.PERSONAL, null].includes(
          props.currentUserType
        ) && (
          <MenuItem value={searchTypes.JOB_SEARCH}>
            <Typography>Job</Typography>
          </MenuItem>
        )}
      </Select>

      {!isMobile && (
        <Paper
          name={"search-paper"}
          sx={{
            px: theme.spacing(3),
            display: "flex",
            alignItems: "center",
            width: 600,
            height: 49,
            fontSize: 20,
            borderRadius: "100px !important",
            boxShadow: " 0 3px 6px rgba(0, 0, 0, 0.16)",
          }}
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          onClick={() => setSearchQuery("")}
        >
          <Autocomplete
            id="querySelect"
            autoComplete
            blurOnSelect
            includeInputInList
            selectOnFocus
            noOptionsText={
              "No fields or roles like this, maybe you're searching by name"
            }
            style={{ flex: 1 }}
            options={fields?.sort()}
            getOptionLabel={(option) => {
              return option.name;
            }}
            getOptionSelected={(option, value) => option.name === value}
            onInputChange={(event, newValue) => {
              setSearchQuery(newValue);
            }}
            onChange={(e, val) => {
              if (val) {
                handleSubmit({ filters: { field: val } });
              }
            }}
            renderInput={(params) => (
              <InputBase
                sx={{
                  fontSize: 20,
                }}
                disabled={params.disabled}
                fullWidth={params.fullWidth}
                id={params.id}
                inputProps={params.inputProps}
                ref={params.InputProps.ref}
                placeholder="Search"
              />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);

              return (
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ color: part.highlight ? "#e63a46" : "black" }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
          />

          <IconButton type="submit">
            <svg x="0px" y="0px" width="1.3em" viewBox="0 0 19.6 17">
              <g>
                <path
                  style={{
                    fill:
                      theme.palette[props.currentUserType || userType.TALENT]
                        .main,
                  }}
                  d="M4.25,9.35c0.53,2.27,2.54,3.69,4.81,3.38c3.07-0.42,3.27-2.52,3.21-2.85c-0.02-0.18-0.09-0.91-0.09-0.91
          c0-0.03-0.03-0.06-0.07-0.05l-1.48,0.43c-0.03,0-0.06-0.02-0.07-0.05l-0.44-2.05c-0.01-0.05,0.03-0.09,0.08-0.1l4.87-1.31
          c0.05-0.01,0.09,0.03,0.1,0.08c0.19,1.04,0.46,2.14,0.51,2.81c0.32,3.79-0.88,7.29-6.19,7.98c-4.63,0.6-8.8-2.77-9.42-7.41
          c-0.56-4.22,1.74-8.22,6.64-9.12c3.3-0.61,5.33,0.51,5.99,1.08c0.04,0.04,0.04,0.1,0,0.14C11.73,2.36,9.84,4.2,9.78,4.23
          C6.62,3.12,3.62,5.72,4.25,9.35z"
                />
              </g>
              <g>
                <path
                  style={{
                    fill:
                      theme.palette[props.currentUserType || userType.TALENT]
                        .main,
                    stroke: "rgba(255,255,255,0.4)",
                  }}
                  d="M14.93,16.9c-2.52,0-4.57-2.05-4.57-4.57c0-2.52,2.05-4.57,4.57-4.57s4.57,2.05,4.57,4.57
            C19.5,14.85,17.45,16.9,14.93,16.9z M14.93,10.4c-1.08,0-1.96,0.88-1.96,1.96c0,1.08,0.88,1.96,1.96,1.96s1.96-0.88,1.96-1.96
            C16.9,11.28,16.02,10.4,14.93,10.4z"
                />
              </g>
            </svg>
          </IconButton>
        </Paper>
      )}
      {isMobile && (
        <Button
          sx={{ mr: 0, minWidth: 0 }}
          color={props.currentUserType || userType.TALENT}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <SearchIcon />
        </Button>
      )}
    </Box>
  );
}

export default SearchInput;
