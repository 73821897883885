import React, { useContext, useState } from "react";
import { useTheme } from "@mui/styles";
import { Box, Divider, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import SignInWithGoogle from "../Register/SignInWithGoogleButton/SignInWithGoogle";
import { IsMobileContext } from "../../providers/IsMobileService";

function LoginPage(props) {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);

  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  function handleChange({ target }) {
    setLoginDetails({ ...loginDetails, [target.name]: target.value });
  }

  function handleSubmit(e) {
    handleLogin(loginDetails);
    setLoginDetails({ ...loginDetails, password: "" });

    e.preventDefault();
  }

  function handleLogin(loginDetails) {
    Auth.signIn(loginDetails.email, loginDetails.password)
      .then(() => {
        history.push("/profile");
        props.handleLogin();
      })
      .catch((error) => {
        console.log("error signing up:", error);
        setError(error.message);
      });
  }

  return (
    <Box
      sx={{
        pt: !isMobile && "3em",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "80%" : 400,
          backgroundColor: "#fff",
          px: "3em",
          py: !isMobile && "4em",
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Sign up</Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Typography
            variant={"subtitle2"}
            sx={{ color: theme.palette.body.lightText, pb: 1 }}
          >
            Email address
          </Typography>
          <TextField
            fullWidth
            id="email"
            name={"email"}
            onChange={handleChange}
            placeholder="Email"
            variant="outlined"
            color="personal"
            sx={{ mb: 3 }}
            value={loginDetails.email}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant={"subtitle2"}
              sx={{ color: theme.palette.body.lightText, pb: 1 }}
            >
              Password
            </Typography>
            <Link
              sx={{
                cursor: "pointer",
                color: theme.palette.body.lightText,
                "&:hover": { color: theme.palette.body.dark, opacity: 0.6 },
              }}
              onClick={() => history.push("/recovery")}
            >
              Forgot your password?
            </Link>
          </Box>
          <TextField
            fullWidth
            id="password"
            name={"password"}
            onChange={handleChange}
            placeholder="Password"
            variant="outlined"
            color="personal"
            sx={{ mb: 3 }}
            type={"password"}
            value={loginDetails.password}
          />
          {!!error && (
            <Typography
              className={{
                color: theme.palette.error.main,
                marginTop: theme.spacing(1),
              }}
            >
              {error}
            </Typography>
          )}

          <Box sx={{ mt: 3 }}>
            <Button
              sx={{ borderRadius: 19 }}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="personal"
            >
              Sign in
            </Button>
          </Box>
        </form>

        <Box sx={{ alignItems: "center", display: "flex" }}>
          <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
          <Button
            variant="outlined"
            sx={{
              cursor: "unset",
              m: 2,
              py: 0.5,
              px: 7,
              borderColor: `${theme.palette.grey[100]} !important`,
              color: `${theme.palette.grey[900]}!important`,
              fontWeight: 500,
              borderRadius: 4,
            }}
            disableRipple
            disabled
          >
            OR
          </Button>
          <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
        </Box>
        <SignInWithGoogle signInOrUp={"Sign in"} />
      </Box>
    </Box>
  );
}

export default LoginPage;
