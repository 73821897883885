import React, { useContext, useState } from "react";
import Geolocation from "../../common/Geolocation/GooglePlaces";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DatePickerCalendar from "../../common/DatePickerCalendar/DatePickerCalendar";
import { userType } from "../../../shared/userType";
import Checkbox from "@mui/material/Checkbox";
import { differenceInYears } from "date-fns";
import { CONST } from "../../../shared/consts";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import FieldFilter from "../../common/Search/Filters/FieldFilter";
import SocialNetworksInput from "../SocialNetworksInput/SocialNetworksInput";
import { IsMobileContext } from "../../../providers/IsMobileService";

export default function PersonalEditForm(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [selectedRole, setSelectedRole] = useState({
    sub1: 1,
    sub2: 1,
    sub3: 1,
  });
  const [roleOptions, setRoleOptions] = useState({
    sub1: props.user.personal.field?.roles || null,
    sub2: null,
    sub3: null,
  });

  function handleSubRoleChange({ target }) {
    let selectedField = props.fields.find(
      (f) => f.id === props.user.personal.field
    );
    handlePersonalChange({
      target: { name: target.name, value: target.value },
    });

    switch (target.name) {
      // Case sub1 selected
      case "subField1": {
        // Find the selectedSub1 obj
        let selectedSub1Object = selectedField.roles.find(
          (f) => f.id === target.value
        );

        //Save the selected sub1 id
        setSelectedRole({ sub1: selectedSub1Object.id, sub2: 1, sub3: 1 });
        // Set the sub2 options
        setRoleOptions({
          ...roleOptions,
          sub2: selectedSub1Object?.roles,
          sub3: null,
        });

        break;
      }
      case "subField2": {
        // Find the selectedSub2 obj
        let selectedSub2Object = roleOptions.sub2.find(
          (f) => f.id === target.value
        );

        //Save the selected sub2 id
        setSelectedRole({
          ...selectedRole,
          sub2: selectedSub2Object.id,
          sub3: 1,
        });
        // Set the sub3 options
        setRoleOptions({
          ...roleOptions,
          sub3: selectedSub2Object?.roles,
        });

        break;
      }
      case "subField3": {
        // Find the selectedSub3 obj
        let selectedSub3Object = roleOptions.sub3.find(
          (f) => f.id === target.value
        );

        //Save the selected sub3 id
        setSelectedRole({ ...selectedRole, sub3: selectedSub3Object.id });

        break;
      }
      default: {
        break;
      }
    }
  }

  function handlePersonalChange({ target }) {
    let personalUserObj = props.user.personal;

    personalUserObj[target.name] = target.value;

    props.handleChange({
      target: {
        name: userType.PERSONAL,
        value: personalUserObj,
      },
    });
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.body.foreground,
        display: "flex",
        flexDirection: "column",

        "& .MuiOutlinedInput-root": {
          px: 1,
          lineHeight: "1.5em",
          fontSize: "0.95em",
          fontWeight: 300,
          color: theme.palette.text.primary,
          mb: 2,
        },
        "& .MuiInputBase-multiline": {
          py: 3,
          px: 2,
          mb: 0,
        },

        "& .MuiSelect-select": { px: 1, py: 2 },
      }}
    >
      <Typography variant={"h6"} sx={{ mb: 1 }}>
        About Me
      </Typography>

      <TextField
        name={"about"}
        id="about"
        fullWidth
        multiline
        minRows={5}
        maxRows={15}
        variant="outlined"
        color={"personal"}
        margin="none"
        value={props.user.personal.about}
        onChange={handlePersonalChange}
      />

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 4 }}>
          Personal Details
        </Typography>

        <Typography variant={"subtitle2"}>Username</Typography>
        <TextField
          id="firstName"
          name={"firstName"}
          onChange={props.handleChange}
          placeholder="Full name"
          margin="none"
          variant="outlined"
          color={"personal"}
          fullWidth
          value={props.user.firstName}
          error={!!props.errors.firstName}
          helperText={props.errors.firstName}
        />

        <Typography variant={"subtitle2"}>Email</Typography>
        <TextField
          id="email"
          name={"email"}
          onChange={props.handleChange}
          placeholder={"Your email"}
          type={"email"}
          margin="none"
          variant="outlined"
          color={"personal"}
          fullWidth
          value={props.user.email}
          error={!!props.errors.email}
          helperText={props.errors.email}
        />

        <Typography variant={"subtitle2"}>Phone number</Typography>
        <TextField
          id="phone"
          name={"phone"}
          type={"text"}
          onChange={props.handleChange}
          placeholder="Your phone number"
          margin="none"
          variant="outlined"
          color={"personal"}
          fullWidth
          value={props.user.phone}
          error={!!props.errors.phone}
          helperText={props.errors.phone}
        />

        <Typography variant={"subtitle2"}>Address</Typography>
        <Geolocation
          placeholder="Enter your address"
          color={"personal"}
          handleChange={props.handleChange}
          address={props.user.address}
          error={props.errors?.address}
        />

        <Typography variant={"subtitle2"}>Birth date</Typography>
        <DatePickerCalendar
          name={"birthDate"}
          label={"Date of birth"}
          color={"personal"}
          value={props.user.personal.birthDate}
          handleChange={handlePersonalChange}
        />
        {differenceInYears(
          Date.now(),
          new Date(props.user.personal.birthDate)
        ) < CONST.PARENTAL_APPROVAL_AGE && (
          <FormControl required error={!!props.errors.parentalApproval}>
            <FormControlLabel
              label="Parental approval"
              control={
                <Checkbox
                  checked={props.user.personal.parentalApproval}
                  onChange={() => {
                    handlePersonalChange({
                      target: {
                        name: "parentalApproval",
                        value: !props.user.personal.parentalApproval,
                      },
                    });
                  }}
                  name="parentalApproval"
                  size={"small"}
                />
              }
            />
            <FormHelperText>{props.errors.parentalApproval}</FormHelperText>
          </FormControl>
        )}
      </Box>

      <FormControl component="fieldset">
        <FormLabel color={"personal"} component="legend">
          Gender
        </FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="gender"
          onChange={handlePersonalChange}
          value={props.user.personal.gender}
        >
          <FormControlLabel
            value="female"
            control={<Radio color={"personal"} size={"small"} />}
            label="Female"
          />
          <FormControlLabel
            value="male"
            control={<Radio color={"personal"} size={"small"} />}
            label="Male"
          />
          <FormControlLabel
            value="other"
            control={<Radio color={"personal"} size={"small"} />}
            label="Other"
          />
        </RadioGroup>
      </FormControl>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "80%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Professional Skills
        </Typography>

        {props.fields && props.fields.length > 0 && (
          <>
            <FieldFilter
              fieldName={"Your professional field"}
              name={"field"}
              color={"personal"}
              handleChange={(e) => {
                let field = props.fields.find((f) => f.id === e.target.value);

                setRoleOptions({
                  sub1: field.roles,
                  sub2: null,
                  sub3: null,
                });
                setSelectedRole({ ...selectedRole, sub1: 1 });
                handlePersonalChange(e);
              }}
              fields={props.fields}
              selected={props.user.personal.field}
            />

            {roleOptions.sub1 && roleOptions.sub1.length > 0 && (
              <FieldFilter
                fieldName={"Select your role"}
                name={"subField1"}
                color={"personal"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub1}
                selected={selectedRole.sub1}
              />
            )}

            {roleOptions.sub2 && roleOptions.sub2.length > 0 && (
              <FieldFilter
                fieldName={"Choose your expertise"}
                name={"subField2"}
                color={"personal"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub2}
                selected={selectedRole.sub2}
              />
            )}

            {roleOptions.sub3 && roleOptions.sub3.length > 0 && (
              <FieldFilter
                fieldName={"Select your exact field"}
                name={"subField3"}
                color={"personal"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub3}
                selected={selectedRole.sub3}
              />
            )}
          </>
        )}
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Social Presence (Optional)
        </Typography>

        <SocialNetworksInput
          networks={props.user.personal.socialNetworks}
          color={"personal"}
          handleChange={({ target }) => {
            props.handleChange({
              target: {
                name: "personal",
                value: {
                  ...props.user.personal,
                  socialNetworks: target.value,
                },
              },
            });
          }}
        />
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Salary Period
        </Typography>

        <FormControl fullWidth>
          <InputLabel color={"personal"} id={"salaryPeriod"}>
            Salary period
          </InputLabel>
          <Select
            labelId={"salaryPeriod"}
            id={"salaryPeriod"}
            color={"personal"}
            name={"salaryPeriod"}
            value={props.user.personal.salaryPeriod}
            onChange={handlePersonalChange}
          >
            <MenuItem value={"daily"}>
              <ListItemText primary={"Daily"} />
            </MenuItem>
            <MenuItem value={"weekly"}>
              <ListItemText primary={"Weekly"} />
            </MenuItem>
            <MenuItem value={"monthly"}>
              <ListItemText primary={"Monthly"} />
            </MenuItem>
            <MenuItem value={"yearly"}>
              <ListItemText primary={"Yearly"} />
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          className={"dou-item"}
          id="salaryFrom"
          name={"salaryFrom"}
          onChange={handlePersonalChange}
          label="From"
          variant="outlined"
          color={"personal"}
          fullWidth
          value={props.user.personal.salaryFrom}
        />
        <TextField
          label="To"
          className={"dou-item"}
          value={props.user.personal.salaryTo}
          variant="outlined"
          color={"personal"}
          fullWidth
          onChange={handlePersonalChange}
          name="salaryTo"
          id="salaryTo"
          error={!!props.errors.salaryTo}
          helperText={props.errors.salaryTo}
        />

        <TextField
          id="rank"
          name={"rank"}
          onChange={handlePersonalChange}
          label="Years of experience"
          type={"number"}
          variant="outlined"
          color={"personal"}
          fullWidth
          value={props.user.personal.rank}
          error={!!props.errors.rank}
          helperText={props.errors.rank}
        />
      </Box>
    </Box>
  );
}
