import React from "react";
import { makeStyles } from "@mui/styles";

export default function DeleteAccountForm() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      // flexDirection: "column",
      // flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>Delete Account will be available soon</div>
  );
}
