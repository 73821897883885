import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import * as userActions from "../../../actions/userActions";
import userStore from "../../../stores/userStore";
import { Storage } from "aws-amplify";
import MenuListButton from "../../common/MenuListButton/MenuListButton";
import RepositionDialog from "../../common/ImageScaleAndReposition/RepositionDialog";
import { Box, useTheme } from "@mui/material";
import { CameraAlt } from "@mui/icons-material";

function UploadAvatarImage(props) {
  const theme = useTheme();
  let myInput;

  const [isImageRepositionModalOpen, setIsImageRepositionModalOpen] = useState(
    false
  );
  const [imageFile, setImageFile] = useState(null);

  function handleImageSet() {
    setImageFile(myInput.files[0]);

    setIsImageRepositionModalOpen(true);
  }

  function handleImageUpload(image) {
    Storage.configure({
      AWSS3: {
        bucket: process.env.REACT_APP_S3_IMAGE_UPLOAD_BUCKET,
        region: "us-east-1",
      },
    });

    if (image) {
      userActions
        .uploadImage(image.name, props.currentUserType)
        .then((filename) => {
          // Upload to aws
          Storage.put("profile_images/" + filename, image, {});
        });
    }
  }

  function handleImageDelete() {
    let UserObj = props.user[props.currentUserType];

    UserObj.image = "";

    props.handleChange({
      target: {
        name: props.currentUserType,
        value: UserObj,
      },
    });

    myInput.value = "";
  }

  return (
    <>
      <RepositionDialog
        isOpen={isImageRepositionModalOpen}
        handleClose={() => setIsImageRepositionModalOpen(false)}
        handleSave={(image) => {
          handleImageUpload(image);
        }}
        imageFileOrSrc={imageFile}
        selectProps={{
          radius: 999,
          width: 350,
          modalWidth: 400,
          height: 350,
          aspectRatio: "1 / 1",
        }}
      />

      <Box sx={{ position: "absolute", top: "8.7em", left: "8em", zIndex: 9 }}>
        <MenuListButton
          disablePortal
          value={"image"}
          icon={<CameraAlt color={props.currentUserType} />}
          iconSx={{
            backgroundColor: "#fff",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          }}
          options={
            props.src
              ? [
                  {
                    name: "Upload",
                    func: () => {
                      myInput.click();
                    },
                  },
                  { name: "Delete", func: handleImageDelete },
                ]
              : [
                  {
                    name: "Upload",
                    func: () => {
                      myInput.click();
                    },
                  },
                ]
          }
        />
      </Box>

      <input
        id="myInput"
        type="file"
        ref={(ref) => (myInput = ref)}
        style={{ display: "none" }}
        onChange={handleImageSet}
      />
      <Avatar
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          backgroundColor: theme.palette.body.avatar,
          fontSize: "2em",
        }}
        className="floatingButton clickable"
        src={props.src}
        alt={props.alt}
      />
    </>
  );
}

export default UploadAvatarImage;
