import dispatcher from "../appDispatcher";
import * as fieldsApi from "../api/fieldsApi";
import actionTypes from "./actionTypes";

export function getFields() {
  return fieldsApi.getFields().then((result) => {
    dispatcher.dispatch({
      actionType: actionTypes.LOAD_FIELDS,
      fields: result,
    });
  });
}

export function getTalentFields() {
  return fieldsApi.getTalentFields().then((result) => {
    dispatcher.dispatch({
      actionType: actionTypes.LOAD_TALENT_FIELDS,
      talentFields: result,
    });
  });
}

export function getFreelancerFields() {
  return fieldsApi.getFreelancerFields().then((result) => {
    dispatcher.dispatch({
      actionType: actionTypes.LOAD_FREELANCER_FIELDS,
      freelancerFields: result,
    });
  });
}
