import React from "react";
import { makeStyles } from "@mui/styles";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDial from "@mui/material/SpeedDial";
import MenuItem from "../MenuItem/MenuItem";

function SpeedDialUsers(props) {
  let myInput;

  const useStyles = makeStyles((theme) => ({
    menuItem: {
      display: "flex",
      flexWrap: "wrap",
    },
    hidden: {
      marginLeft: "auto",
      marginRight: "auto",
      height: 0,
      opacity: 0,
      transitionTimingFunction: "ease-out",
      transitionDuration: "0.15s",
    },
    shown: {
      marginLeft: "auto",
      marginRight: "auto",
      height: "48px",
      transitionTimingFunction: "ease-in",
      transitionDuration: "0.15s",
    },
    circle: {
      height: "30px !important",
      minHeight: "30px !important",
      width: "30px !important",
    },
    talent: {
      color: "#fff",
      backgroundColor: theme.palette.talent.main,
      opacity: 0.6,
      "&:hover": {
        backgroundColor: theme.palette.talent.main,
        opacity: 1,
      },
    },
    personal: {
      color: "#fff",
      backgroundColor: theme.palette.personal.main,
      opacity: 0.6,
      "&:hover": {
        backgroundColor: theme.palette.personal.main,
        opacity: 1,
      },
    },
    business: {
      color: "#fff",
      backgroundColor: theme.palette.business.main,
      opacity: 0.6,
      "&:hover": {
        backgroundColor: theme.palette.business.main,
        opacity: 1,
      },
    },
    freelancer: {
      color: "#fff",
      backgroundColor: theme.palette.freelancer.main,
      opacity: 0.6,
      "&:hover": {
        backgroundColor: theme.palette.freelancer.main,
        opacity: 1,
      },
    },
    selected: {
      boxShadow: "0 0 3pt 2pt #2196f3",
      opacity: 1,
    },
  }));
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  function toggleOpen() {
    if (!open) {
      handleOpen();
    } else {
      handleClose();
    }
  }
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }

  return (
    <div className={classes.menuItem}>
      <MenuItem
        isLink
        button={props.buttonLabel}
        icon={props.icon}
        handleClick={() => myInput.click()}
        tooltip={props.tooltip}
      />
      <SpeedDial
        className={`${!open ? classes.hidden : classes.shown}`}
        ariaLabel={props.buttonLabel}
        hidden
        open={open}
        direction={"right"}
        onClick={toggleOpen}
        ref={(ref) => (myInput = ref)}
      >
        {props.userTypes.map((action) => (
          <SpeedDialAction
            className={`${classes.circle} ${classes[action.name]} ${
              action.selected && classes.selected
            }`}
            onClick={() => props.handleUserClick(action.name)}
            key={action.name}
            icon={action.display}
            tooltipTitle={action.tooltip}
            tooltipPlacement={"bottom"}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

export default SpeedDialUsers;
