import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Public,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import TitleAndText from "../../common/Text/TitleAndText";

export default function SocialNetworksInput(props) {
  const theme = useTheme();

  const [userNetworks, setUserNetworks] = useState(props.networks || []);
  const [socialData, setSocialData] = useState({ network: "", url: "" });
  const listOptions = [
    { type: "Facebook" },
    { type: "Instagram" },
    { type: "Twitter" },
    { type: "Youtube" },
    { type: "LinkedIn" },
    { type: "Tumbler" },
    { type: "TikTok" },
    { type: "Podcast" },
    { type: "Other" },
  ];

  function getSocialIcon(socialNetwork) {
    switch (socialNetwork) {
      case "Facebook":
        return <Facebook sx={{ mr: 1 }} />;
      case "Instagram":
        return <Instagram sx={{ mr: 1 }} />;
      case "YouTube":
        return <YouTube sx={{ mr: 1 }} />;
      case "LinkedIn":
        return <LinkedIn sx={{ mr: 1 }} />;
      case "Twitter":
        return <Twitter sx={{ mr: 1 }} />;
      default:
        return <Public sx={{ mr: 1 }} />;
    }
  }

  function handleDeleteNetwork(id) {
    let updatedNetworks = userNetworks.filter((net) => net.id !== id);

    setUserNetworks(updatedNetworks);

    props.handleChange({
      target: {
        name: "socialNetworks",
        value: updatedNetworks,
      },
    });
  }

  function handleAdd() {
    let updatedNetworks = [
      ...userNetworks,
      { type: socialData.network, url: socialData.url, id: generateId() },
    ];

    setUserNetworks(updatedNetworks);

    props.handleChange({
      target: {
        name: "socialNetworks",
        value: updatedNetworks,
      },
    });

    handleClear();
  }

  function generateId() {
    return Math.max(userNetworks.map((x) => x.id)) + 1;
  }

  function handleChange({ target }) {
    setSocialData({ ...socialData, [target.name]: target.value });
  }

  function handleClear() {
    setSocialData({ network: "", url: "" });
  }

  function handleEdit(networkInfo) {
    handleDeleteNetwork(networkInfo.id);

    setSocialData({ network: networkInfo.type, url: networkInfo.url });
  }

  return (
    <Box>
      {userNetworks &&
        userNetworks.map((network) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
            key={network.id}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {getSocialIcon(network.type)}
              <TitleAndText
                key={network.id}
                title={network.type}
                text={network.url}
                type={"socialNetwork"}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant={"body2"}
                sx={{
                  mr: 1,
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => handleDeleteNetwork(network.id)}
              >
                Delete
              </Typography>
              <Typography
                variant={"body2"}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => handleEdit(network)}
              >
                Edit
              </Typography>
            </Box>
          </Box>
        ))}

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <FormControl fullWidth>
          <InputLabel color={props.color || "personal"} id="selectLabel">
            Select Network
          </InputLabel>
          <Select
            labelId="selectLabel"
            label="Select Network"
            name={"network"}
            id="socialType"
            color={props.color || "personal"}
            value={socialData.network}
            onChange={handleChange}
          >
            {listOptions.map((net) => {
              return (
                <MenuItem key={net.type} value={net.type}>
                  {net.type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          name={"url"}
          id="url"
          label="Link"
          variant="outlined"
          color={props.color || "personal"}
          value={socialData.url}
          onChange={handleChange}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            "& .MuiButton-root": {
              borderRadius: "19px",
              color: theme.palette.buttons.text,
              borderColor: theme.palette.buttons.lightBorder,
            },
          }}
        >
          <Button color={"default"} onClick={handleClear}>
            Clear
          </Button>
          <Button
            color={"default"}
            variant={"outlined"}
            sx={{ ml: 2 }}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
