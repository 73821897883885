import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RightSide from "./RightSide/RightSide";
import userStore from "../../../../stores/userStore";
import SearchInput from "../../SearchInput/SearchInput";
import { useTheme } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/material";
import logo from "../../../../shared/resources/images/Logo.svg";
import CollisionObserver from "../../CollisionObserver/CollisionObserver";
import { IsMobileContext } from "../../../../providers/IsMobileService";

function Navbar(props) {
  const theme = useTheme();

  const [currentUserType, setCurrentUserType] = useState(
    userStore.getCurrentType()
  );

  const [currentUser, setCurrentUser] = useState(userStore.getCurrentUser());
  const [navbarStyle, setNavbarStyle] = useState(
    window.location.pathname === "/" && currentUser
      ? {
          backgroundColor: "transparent",
          boxShadow: "none",
        }
      : {
          backgroundColor: "#fff",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        }
  );

  useEffect(() => {
    userStore.addChangeListener(userLogin);

    return () => userStore.removeChangeListener(userLogin);
  }, []);

  function userLogin() {
    setCurrentUser(userStore.getCurrentUser());
    setCurrentUserType(userStore.getCurrentType());
  }

  function handleScroll() {
    setNavbarStyle({
      backgroundColor: "#fff",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    });
  }

  return (
    <CollisionObserver
      divForIntersection={`welcome-section`}
      callback={handleScroll}
      threshold={0.5}
      once
    >
      <AppBar
        sx={{
          position: "fixed",
          width: "100vw",
          left: 0,
          height: theme.sizing.navBar.height,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          // px: isMobile && 2,
          ...navbarStyle,

          transition: theme.transitions.create(["background-color"], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.short,
          }),
        }}
      >
        <Box
          sx={{
            width: "100vw",
            maxWidth: "1600px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to={"/"}>
            <img style={{ width: 200 }} src={logo} alt={"GoGeThem"} />
          </Link>

          <SearchInput currentUserType={currentUserType} />
          <RightSide
            currentUser={currentUser}
            currentUserType={currentUserType}
            handleToggleDeactivateUser={props.handleToggleDeactivateUser}
            toggleSideMenuOpen={props.toggleSideMenuOpen}
          />
        </Box>
      </AppBar>
    </CollisionObserver>
  );
}

export default Navbar;
