import React, { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import { Send } from "@mui/icons-material";
import MessagesDisplay from "./MessagesDisplay";
import chatStore from "../../stores/chatStore";
import * as chatActions from "../../actions/chatActions";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";

export default function ChatModal(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: 400,
    },
    dialogActions: {
      display: "flex",
      justifyContent: "space-between",
    },
    link: {
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [content, setContent] = useState(props.options.presetMessage || "");
  const [chatLog, setChatLog] = useState(
    props.chatLog ||
      chatStore.getChatLog(props.receiver.id, props.receiver.type)
  );

  const BUSY_WAIT_INTERVAL_SEC = 30;

  useEffect(() => {
    if (isLoading) {
      chatStore.addChangeListener(chatLogUpdated);
    }

    if (!chatLog || chatLog.length === 0) {
      chatActions.getChatLog(props.receiver, props.currentUser);
    }

    setInterval(() => {
      chatActions.getChatLog(props.receiver, props.currentUser);
    }, 1000 * BUSY_WAIT_INTERVAL_SEC);

    return () => {
      chatStore.removeChangeListener(chatLogUpdated);
    };
  }, []);

  useEffect(() => {
    if (props.options.presetMessage) {
      handleSubmit();
    }
  }, [props.options.presetMessage]);

  function chatLogUpdated() {
    let updatedChatData = chatStore.getChatLog(
      props.receiver.id,
      props.receiver.type
    );

    setChatLog(updatedChatData);
    setIsLoading(false);
  }

  function handleSubmit(e) {
    e && e.preventDefault();

    if (props.options.presetMessage) {
      let message = {
        time: Date.now(),
        content: props.options.presetMessage,
        sender: props.currentUser,
      };

      setChatLog([...chatLog, message]);
      chatActions.sendMessage(props.currentUser, props.receiver, message);
    } else if (content) {
      let message = {
        time: Date.now(),
        content,
        sender: props.currentUser,
      };

      setContent("");

      setChatLog([...chatLog, message]);
      chatActions.sendMessage(props.currentUser, props.receiver, message);
    }
  }

  function handleChange({ target }) {
    setContent(target.value);
  }

  return (
    <Dialog
      className={classes.root}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.options.isOpen}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        Chat with{" "}
        <Link
          className={classes.link}
          onClick={() =>
            history.push(`/${props.receiver.type}/${props.receiver.id}`)
          }
        >
          {props.roomName}
        </Link>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent
          id={"messageBoard"}
          className={"flex"}
          dividers
          style={{ alignItems: "center", width: "500px", height: "50vh" }}
        >
          <MessagesDisplay
            isTyping={isTyping}
            messages={chatLog}
            currentUser={props.currentUser}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus
            style={{ width: "90%" }}
            id="content"
            name={"content"}
            onChange={handleChange}
            label="Write to send"
            value={content}
          />
          <IconButton color="primary" type={"submit"}>
            <Send />
          </IconButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
const useStyles = makeStyles((theme) => ({
  errorLabel: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
