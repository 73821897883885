import React from "react";
import { Auth } from "aws-amplify";
import { Box, Button } from "@mui/material";
import Google from "../../../shared/resources/images/socialIcons/google.svg";

const SignInWithGoogle = ({ signInOrUp }) => {
  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={() => {
        Auth.federatedSignIn({ provider: "Google" }).catch((error) => {
          console.error(error);
        });
      }}
      size="large"
      sx={{
        borderRadius: 19,
        color: "grey.700",
        backgroundColor: "palette.grey[50]",
        borderColor: "palette.grey[100]",
      }}
    >
      <Box sx={{ mr: { xs: 1, sm: 2 } }}>
        <img src={Google} alt="google" width={16} height={16} />
      </Box>
      {signInOrUp} with Google
    </Button>
  );
};

export default SignInWithGoogle;
