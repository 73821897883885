import dispatcher from "../appDispatcher";
import * as userApi from "../api/userApi";
import actionTypes from "./actionTypes";
import userStore from "../stores/userStore";

export function getUserByUsername(username) {
  return userApi
    .getUserByUsername(username)
    .then((result) => {
      if (result) {
        return result.user;
      } else {
        console.log(
          `userActions: getUserByUsername(${username}) did not returned result from server`
        );
        return null;
      }
    })
    .catch((error) => {
      throw error;
    });
}

export function getUserById(id) {
  return userApi
    .getUserById(id)
    .then((result) => {
      if (result) {
        return result.user;
      } else {
        console.log(
          `userActions: getUserById(${id}) did not returned result from server`
        );
        return null;
      }
    })
    .catch((error) => {
      throw error;
    });
}

export function saveUser(user) {
  return userApi
    .saveUser(user)
    .then((result) => {})
    .catch((error) => {
      throw error;
    });
}

export function updateUser(user) {
  return userApi
    .updateUser(user)
    .then((result) => {
      // Hey dispatcher, go tell all the stores that a user updated
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_USER,
        user: result.user,
      });

      return result.user;
    })
    .catch((error) => {
      throw error;
    });
}

export function deleteUserVideo(videoToDelete, userType) {
  return userApi
    .deleteUserVideo(videoToDelete, userType)
    .then((result) => {
      // Hey dispatcher, go tell all the stores that a user updated
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_USER,
        user: result.user,
      });
    })
    .catch((error) => {
      throw error;
    });
}

export function setUserMainVideo(videoToSet, userType) {
  return userApi
    .setUserMainVideo(videoToSet, userType)
    .then((result) => {
      // Hey dispatcher, go tell all the stores that a user updated
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_USER,
        user: result.user,
      });
    })
    .catch((error) => {
      throw error;
    });
}

export function addUserVideo(videoNameToAdd, userType) {
  return userApi
    .addUserVideo(videoNameToAdd, userType)
    .then((result) => {
      // Hey dispatcher, go tell all the stores that a user updated
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_USER,
        user: result.updatedUser,
      });

      return result.updatedFilename;
    })
    .catch((error) => {
      throw error;
    });
}

export function tokenLoginUser(userData) {
  let userType = userStore.getCurrentType();

  return userApi
    .tokenLogin(userData, userType)
    .then((result) => {
      // TODO: get from localhost the userId and check if its same user, only if user changed dispatch
      dispatcher.dispatch({
        actionType: actionTypes.LOGIN_USER,
        loggedInUser: result.user,
      });
    })
    .catch((error) => {
      throw error;
    });
}

export function logout() {
  // Hey dispatcher, go tell all the stores that user logged in
  dispatcher.dispatch({
    actionType: actionTypes.LOGOUT_USER,
  });
}

export function changeType(user, type) {
  if (user.userType.includes(type)) {
    // Hey dispatcher, go tell all the stores that a user updated
    dispatcher.dispatch({
      actionType: actionTypes.TYPE_CHANGE,
      userType: type,
    });
  } else {
    throw new Error(
      `user does not contain: "${type}" type. available types are: "${user.userType}"`
    );
  }
}

export function uploadImage(filename, userType) {
  return userApi
    .uploadImage(filename, userType)
    .then((result) => {
      // Hey dispatcher, go tell all the stores that a user updated
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_USER,
        user: result.updatedUser,
      });

      return result.filename;
    })
    .catch((error) => {
      throw error;
    });
}

export function uploadCoverImage(filename, userType) {
  return userApi
    .uploadCoverImage(filename, userType)
    .then((result) => {
      // Hey dispatcher, go tell all the stores that a user updated
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_USER,
        user: result.updatedUser,
      });

      return result.filename;
    })
    .catch((error) => {
      throw error;
    });
}

export function defaultUserType() {
  dispatcher.dispatch({
    actionType: actionTypes.DEFAULT_USER_TYPE,
  });
}
