import { handleResponse, handleError } from "./apiUtils";
import CONF from "../configurations/applicationConf";
import { Auth } from "aws-amplify";
const baseUrl = CONF.API_URL + "/user/";

export function getUserByUsername(username) {
  const actionUrl = "view/";
  const params = `?username=${username}`;

  return fetch(baseUrl + actionUrl + params, {
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserById(id) {
  const actionUrl = "findById/";
  const params = `?id=${id}`;

  return fetch(baseUrl + actionUrl + params, {
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveUser(user) {
  const actionUrl = "register";

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateUser(user) {
  const actionUrl = "save/";
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT", // PUT to update when id already exists.
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteUserVideo(videoToDelete, userType) {
  const actionUrl = `deleteVideo`;
  const params = `?userType=${userType}`;
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl + params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
    body: JSON.stringify({ videoToDelete }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function setUserMainVideo(videoToSet, userType) {
  const actionUrl = `setMainVideo`;
  const params = `?userType=${userType}`;
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl + params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT",
    body: JSON.stringify({ videoToSet }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function addUserVideo(videoToAdd, userType) {
  const actionUrl = `addVideo`;
  const params = `?userType=${userType}`;
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl + params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT",
    body: JSON.stringify({ videoToAdd }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function login(loginDetails) {
  const actionUrl = "login/";

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST", // POST login details to compare to details at the server.
    body: JSON.stringify(loginDetails),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function tokenLogin(userData, userType) {
  const actionUrl = "login/token/";

  let token = userData.idToken.jwtToken;

  return fetch(baseUrl + actionUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({ userType }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function uploadImage(filename, userType) {
  const actionUrl = `upload/image`;
  const params = `?userType=${userType}`;
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl + params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT",
    body: JSON.stringify({ file: filename }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function uploadCoverImage(filename, userType) {
  const actionUrl = `upload/coverImage`;
  const params = `?userType=${userType}`;
  let session = await Auth.currentSession();
  let token = session.idToken.jwtToken;

  return fetch(baseUrl + actionUrl + params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT",
    body: JSON.stringify({ file: filename }),
  })
    .then(handleResponse)
    .catch(handleError);
}
