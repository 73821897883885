import { Typography } from "@mui/material";
import React from "react";

export const TermTexts = {
  DATA_POLICY_TEXT_AGREEMENT: (
    <>
      <Typography>
        This policy describes the information we process to support GoGeThem,
        Instagram, Messenger and other products and features offered by GoGeThem
        (GoGeThem Products or Products). You can find additional tools and
        information in the GoGeThem Settings and Instagram Settings.
      </Typography>
    </>
  ),
  COOKIES_POLICY_TEXT_AGREEMENT: (
    <>
      <Typography gutterBottom>
        Cookies are small pieces of text used to store information on web
        browsers. Cookies are used to store and receive identifiers and other
        information on computers, phones and other devices. Other technologies,
        including data we store on your web browser or device, identifiers
        associated with your device, and other software, are used for similar
        purposes. In this policy, we refer to all of these technologies as
        “cookies.”
      </Typography>

      <Typography gutterBottom>
        We use cookies if you have a GoGeThem account, use the GoGeThem
        Products, including our website and apps, or visit other websites and
        apps that use the GoGeThem Products (including the Like button or other
        GoGeThem Technologies). Cookies enable GoGeThem to offer the GoGeThem
        Products to you and to understand the information we receive about you,
        including information about your use of other websites and apps, whether
        or not you are registered or logged in.
      </Typography>

      <Typography>
        This policy explains how we use cookies and the choices you have. Except
        as otherwise stated in this policy, the Data Policy will apply to our
        processing of the data that we collect via cookies.
      </Typography>
    </>
  ),
  TERM_TEXT_AGREEMENT: (
    <>
      <Typography gutterBottom>Welcome to GoGeThem!</Typography>
      <Typography gutterBottom>
        GoGeThem builds technologies and services that enable people to connect
        with each other, build communities, and grow businesses. These Terms
        govern your use of GoGeThem and the other products, features, apps,
        services, technologies, and software we offer (the GoGeThem Products or
        Products), except where we expressly state that separate terms (and not
        these) apply. These Products are provided to you by GoGeThem, Inc.
      </Typography>

      <Typography gutterBottom>
        We don’t charge you to use GoGeThem or the other products and services
        covered by these Terms. Instead, businesses and organizations pay us to
        show you ads for their products and services. By using our Products, you
        agree that we can show you ads that we think will be relevant to you and
        your interests. We use your personal data to help determine which ads to
        show you.
      </Typography>

      <Typography gutterBottom>
        We don’t sell your personal data to advertisers, and we don’t share
        information that directly identifies you (such as your name, email
        address or other contact information) with advertisers unless you give
        us specific permission. Instead, advertisers can tell us things like the
        kind of audience they want to see their ads, and we show those ads to
        people who may be interested. We provide advertisers with reports about
        the performance of their ads that help them understand how people are
        interacting with their content. See Section 2 below to learn more.
      </Typography>

      <Typography>
        Our Data Policy explains how we collect and use your personal data to
        determine some of the ads you see and provide all of the other services
        described below. You can also go to your settings at any time to review
        the privacy choices you have about how we use your data.
      </Typography>
    </>
  ),
};
