import React, { useContext, useEffect, useState } from "react";
import Geolocation from "../../common/Geolocation/GooglePlaces";
import { Box, Divider, TextField, Typography, useTheme } from "@mui/material";
import { userType } from "../../../shared/userType";
import FieldFilter from "../../common/Search/Filters/FieldFilter";
import FieldsStore from "../../../stores/FieldsStore";
import { getFreelancerFields } from "../../../actions/fieldsActions";
import SocialNetworksInput from "../SocialNetworksInput/SocialNetworksInput";
import DatePickerCalendar from "../../common/DatePickerCalendar/DatePickerCalendar";
import { IsMobileContext } from "../../../providers/IsMobileService";

function FreelancerEditForm(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [fields, setFields] = useState(FieldsStore.getFreelancerFields());
  const [selectedRole, setSelectedRole] = useState({
    sub1: 1,
    sub2: 1,
    sub3: 1,
  });
  const [roleOptions, setRoleOptions] = useState({
    sub1: props.user.freelancer.field?.roles || null,
    sub2: null,
    sub3: null,
  });

  useEffect(() => {
    FieldsStore.addChangeListener(fieldsChanged);

    if (FieldsStore.getFreelancerFields().length === 0) {
      getFreelancerFields();
    }

    return () => {
      FieldsStore.removeChangeListener(fieldsChanged);
    };
  }, []);

  function fieldsChanged() {
    setFields(FieldsStore.getFreelancerFields());
  }

  function handleSubRoleChange({ target }) {
    let selectedField = fields.find(
      (f) => f.id === props.user.freelancer.field
    );
    handleFreelancerChange({
      target: { name: target.name, value: target.value },
    });

    switch (target.name) {
      // Case sub1 selected
      case "subField1": {
        // Find the selectedSub1 obj
        let selectedSub1Object = selectedField.roles.find(
          (f) => f.id === target.value
        );

        //Save the selected sub1 id
        setSelectedRole({ sub1: selectedSub1Object.id, sub2: 1, sub3: 1 });
        // Set the sub2 options
        setRoleOptions({
          ...roleOptions,
          sub2: selectedSub1Object?.roles,
          sub3: null,
        });

        break;
      }
      case "subField2": {
        // Find the selectedSub2 obj
        let selectedSub2Object = roleOptions.sub2.find(
          (f) => f.id === target.value
        );

        //Save the selected sub2 id
        setSelectedRole({
          ...selectedRole,
          sub2: selectedSub2Object.id,
          sub3: 1,
        });
        // Set the sub3 options
        setRoleOptions({
          ...roleOptions,
          sub3: selectedSub2Object?.roles,
        });

        break;
      }
      case "subField3": {
        // Find the selectedSub3 obj
        let selectedSub3Object = roleOptions.sub3.find(
          (f) => f.id === target.value
        );

        //Save the selected sub3 id
        setSelectedRole({ ...selectedRole, sub3: selectedSub3Object.id });

        break;
      }
      default: {
        break;
      }
    }
  }

  function handleFreelancerChange({ target }) {
    let freelancerUserObj = props.user.freelancer;

    freelancerUserObj[target.name] = target.value;

    props.handleChange({
      target: {
        name: userType.FREELANCER,
        value: freelancerUserObj,
      },
    });
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.body.foreground,
        display: "flex",
        flexDirection: "column",

        "& .MuiOutlinedInput-root": {
          px: 1,
          lineHeight: "1.5em",
          fontSize: "0.95em",
          fontWeight: 300,
          color: theme.palette.text.primary,
          mb: 2,
        },
        "& .MuiInputBase-multiline": {
          py: 3,
          px: 2,
          mb: 0,
        },

        "& .MuiSelect-select": { px: 1, py: 2 },
      }}
    >
      <Typography variant={"h6"} sx={{ mb: 1 }}>
        About Me
      </Typography>

      <TextField
        name={"about"}
        id="about"
        fullWidth
        multiline
        minRows={5}
        maxRows={15}
        variant="outlined"
        color={"freelancer"}
        margin="none"
        value={props.user.freelancer.about}
        onChange={handleFreelancerChange}
      />

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 4 }}>
          Professional Details
        </Typography>

        <Geolocation
          label="Business address"
          color={"freelancer"}
          address={props.user.address}
          handleChange={props.handleChange}
          error={props.error?.address}
        />

        <Typography variant={"subtitle2"}>Service radius (miles)</Typography>
        <TextField
          id="radius"
          name={"radius"}
          placeholder="Radius"
          value={props.user.freelancer.radius}
          type={"number"}
          variant="outlined"
          color={"freelancer"}
          fullWidth
          onChange={handleFreelancerChange}
        />

        <Typography variant={"subtitle2"}>Website</Typography>
        <TextField
          id="website"
          name={"website"}
          placeholder={"Website"}
          onChange={handleFreelancerChange}
          label="Business website"
          type={"text"}
          variant="outlined"
          color={"freelancer"}
          fullWidth
          value={props.user.freelancer.website}
        />
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Social Presence (Optional)
        </Typography>

        <SocialNetworksInput
          networks={props.user.freelancer.socialNetworks}
          color={"freelancer"}
          handleChange={({ target }) => {
            props.handleChange({
              target: {
                name: "freelancer",
                value: {
                  ...props.user.freelancer,
                  socialNetworks: target.value,
                },
              },
            });
          }}
        />
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "80%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Professional Skills
        </Typography>
        {fields?.length > 0 && (
          <>
            <FieldFilter
              fieldName={"Your professional field"}
              name={"field"}
              color={"freelancer"}
              handleChange={(e) => {
                let field = fields.find((f) => f.id === e.target.value);

                setRoleOptions({
                  sub1: field.roles,
                  sub2: null,
                  sub3: null,
                });
                setSelectedRole({ ...selectedRole, sub1: 1 });
                handleFreelancerChange(e);
              }}
              fields={fields}
              selected={props.user.freelancer.field}
            />

            {roleOptions.sub1 && roleOptions.sub1.length > 0 && (
              <FieldFilter
                fieldName={"Select your role"}
                name={"subField1"}
                color={"freelancer"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub1}
                selected={selectedRole.sub1}
              />
            )}

            {roleOptions.sub2 && roleOptions.sub2.length > 0 && (
              <FieldFilter
                fieldName={"Choose your expertise"}
                name={"subField2"}
                color={"freelancer"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub2}
                selected={selectedRole.sub2}
              />
            )}

            {roleOptions.sub3 && roleOptions.sub3.length > 0 && (
              <FieldFilter
                fieldName={"Select your exact field"}
                name={"subField3"}
                color={"freelancer"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub3}
                selected={selectedRole.sub3}
              />
            )}
          </>
        )}
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 4 }}>
          Contact Details
        </Typography>

        <Typography variant={"subtitle2"}>Username</Typography>
        <TextField
          id="firstName"
          name={"firstName"}
          onChange={props.handleChange}
          placeholder="Full name"
          margin="none"
          variant="outlined"
          color={"freelancer"}
          fullWidth
          value={props.user.firstName}
          error={!!props.errors.firstName}
          helperText={props.errors.firstName}
        />

        <Typography variant={"subtitle2"}>Email</Typography>
        <TextField
          id="email"
          name={"email"}
          onChange={props.handleChange}
          placeholder={"Your email"}
          type={"email"}
          margin="none"
          variant="outlined"
          color={"freelancer"}
          fullWidth
          value={props.user.email}
          error={!!props.errors.email}
          helperText={props.errors.email}
        />

        <Typography variant={"subtitle2"}>Phone number</Typography>
        <TextField
          id="phone"
          name={"phone"}
          type={"text"}
          onChange={props.handleChange}
          placeholder="Your phone number"
          margin="none"
          variant="outlined"
          color={"freelancer"}
          fullWidth
          value={props.user.phone}
          error={!!props.errors.phone}
          helperText={props.errors.phone}
        />

        <Typography variant={"subtitle2"}>Address</Typography>
        <Geolocation
          placeholder="Enter your address"
          color={"freelancer"}
          handleChange={props.handleChange}
          address={props.user.address}
          error={props.errors?.address}
        />

        <Typography variant={"subtitle2"}>Birth date</Typography>
        <DatePickerCalendar
          name={"birthDate"}
          label={"Date of birth"}
          color={"freelancer"}
          value={props.user.freelancer.birthDate}
          handleChange={handleFreelancerChange}
        />
      </Box>
    </Box>
  );
}

export default FreelancerEditForm;
