import React from "react";
import { makeStyles } from "@mui/styles";
import { CONST } from "../shared/consts";
import PropTypes from "prop-types";

function ExampleComponent(props) {
  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      [theme.breakpoints.up(CONST.LG)]: {
        width: "65%",
      },
      [theme.breakpoints.between(CONST.SM, CONST.LG)]: {
        width: "75%",
      },
      [theme.breakpoints.down(CONST.SM)]: {
        width: "90%",
      },
      maxWidth: "1440px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      userSelect: "none",
    },
  }));
  const classes = useStyles();

  return <div className={classes.mainContainer}></div>;
}

ExampleComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userTypes: PropTypes.array.isRequired,
};

ExampleComponent.defaultProps = {
  isOpen: false,
};

export default ExampleComponent;
