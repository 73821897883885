import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function FieldFilter(props) {
  const ALL_ID_VALUE_FIELD = 1;
  const fields = [...props.fields, { id: ALL_ID_VALUE_FIELD, name: "All" }];

  return (
    <FormControl sx={props.style} component="fieldset" fullWidth>
      <InputLabel color={props.color || "personal"}>
        {props.fieldName}
      </InputLabel>
      <Select
        label={props.fieldName}
        labelId="field-select-label"
        id="field-select"
        color={props.color || "personal"}
        name={props.name}
        value={props.selected}
        defaultValue={ALL_ID_VALUE_FIELD}
        onChange={props.handleChange}
      >
        {fields &&
          fields
            .sort((x, y) => x.name.localeCompare(y.name))
            .map((field) => (
              <MenuItem value={field.id} key={field.id}>
                {field.name}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}

FieldFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
};

FieldFilter.defaultProps = {
  isObj: false,
};

export default FieldFilter;
