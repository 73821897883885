import React, { useContext, useEffect, useState } from "react";
import userStore from "../../stores/userStore";
import MessagesBoard from "../common/MessagesBoard/MessagesBoard";
import FavoritesList from "../common/FavoritesList/FavoritesList";
import NavigationTabs from "./NavigationTabs";
import FeedComponent from "../common/FeedComponent/FeedComponent";
import MetaTags from "react-meta-tags";
import { userType } from "../../shared/userType";
import { Box, useTheme } from "@mui/material";
import { IsMobileContext } from "../../providers/IsMobileService";
import { CONST } from "../../shared/consts";

function FeedPage() {
  const NAVIGATION_TAB = "NAVIGATION_TAB";
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  const [currentUser, setCurrentUser] = useState(userStore.getCurrentUser());
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem(NAVIGATION_TAB) || "fed"
  );
  const [contentToDisplay, setContentToDisplay] = useState();
  const [currentUserType, setCurrentUserType] = useState(
    userStore.getCurrentType()
  );

  useEffect(() => {
    userStore.addChangeListener(userUpdated);

    if (currentUser) {
      loadContent(currentTab);
    }

    return () => userStore.removeChangeListener(userUpdated);
  }, [currentUser, currentTab, currentUserType]);

  function userUpdated() {
    setCurrentUser(userStore.getCurrentUser());
    setCurrentUserType(userStore.getCurrentType());
  }

  function loadContent(value) {
    switch (value) {
      case "fav": {
        setContentToDisplay(
          <FavoritesList currentUserType={currentUserType} />
        );

        break;
      }
      case "fed": {
        if (currentUserType) {
          setContentToDisplay(
            <FeedComponent
              currentUserType={currentUserType}
              user={currentUser}
            />
          );
        }

        break;
      }
      case "msg": {
        setContentToDisplay(
          <MessagesBoard
            currentUser={currentUser}
            currentUserType={currentUserType}
          />
        );

        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: `calc(100vh - ${theme.sizing.navBar.height})`,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
      }}
    >
      <MetaTags>
        <title>GoGeThem</title>
      </MetaTags>
      <NavigationTabs
        handleClick={setCurrentTab}
        navigationTabItemName={NAVIGATION_TAB}
        selectedTab={currentTab}
        color={currentUserType || userType.PERSONAL}
      />
      {currentUser && (
        <Box
          sx={{
            [theme.breakpoints.up(CONST.XL)]: {
              width: "inherit",
              ml: "8em",
            },
            [theme.breakpoints.down(CONST.XL)]: {
              width: isMobile ? "100vw" : "65vw",
            },
            mt: isMobile ? "1.6em" : "3em",
          }}
        >
          {contentToDisplay}
        </Box>
      )}
    </Box>
  );
}

export default FeedPage;
