import { handleResponse, handleError } from "./apiUtils";
import CONF from "../configurations/applicationConf";
import { Auth } from "aws-amplify";
const baseUrl = CONF.API_URL + "/search/";

export async function search(filters) {
  const objectToQuerystring = (obj) =>
    Object.keys(obj)
      .map((field) =>
        typeof obj[field] !== "object"
          ? field + "=" + encodeURIComponent(obj[field])
          : field + "=" + JSON.stringify(obj[field])
      )
      .join("&");

  const actionUrl = "top/?";
  let token = null;
  let session = null;

  try {
    session = await Auth.currentSession();
    token = session.idToken.jwtToken;
  } catch (e) {
    console.error(e);
  }

  const query = objectToQuerystring(filters);

  return fetch(baseUrl + actionUrl + query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}
