let conf = {};

conf.API_URL =
  process.env.REACT_APP_API_URL + ":" + process.env.REACT_APP_API_PORT;

conf.AWS_ACCESS_BUCKET_URL = process.env.REACT_APP_AWS_ACCESS_BUCKET_URL;

conf.AMPLIFY_CONFIG = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      scope: ["profile", "email", "openid"],
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  },
};

module.exports = conf;
