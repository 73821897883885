import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import video from "../../shared/resources/videos/GoGeThem.video.mp4";
import Footer from "./Footer/Footer";
import CONF from "../../configurations/applicationConf";
import "react-alice-carousel/lib/alice-carousel.css";
import MetaTags from "react-meta-tags";
import { Box, Paper, useTheme } from "@mui/material";
import ImageButton from "./ImageButton";
import AliceCarousel from "react-alice-carousel";
import CarouselItem from "./Carousel/CarouselItem";
import { useHistory } from "react-router-dom";
import { IsMobileContext } from "../../providers/IsMobileService";

function HomePage() {
  const theme = useTheme();
  const history = useHistory();

  const isMobile = useContext(IsMobileContext);

  function handleImageButtonClick(type) {
    history.push("/register", { type });
  }

  return (
    <Box
      name={"main-container"}
      sx={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        userSelect: "none",
        backgroundColor: theme.palette.background,
      }}
    >
      <MetaTags>
        <title>GoGeThem</title>
      </MetaTags>

      {isMobile ? (
        <video
          style={{
            width: "100vw",
          }}
          controls
          loop
          src={video}
        />
      ) : (
        <Paper sx={{ p: "2em" }}>
          <video
            style={{
              width: "990px",
            }}
            controls
            loop
            src={video}
          />
        </Paper>
      )}
      <Box
        id={"welcome-section"}
        name={"welcome-section"}
        sx={{
          // backgroundColor: "rgba(0,0,255,0.03)",
          height: "30vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          py: isMobile ? "3em" : "7em",

          "& .MuiTypography-root": {
            color: "#082224",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          {isMobile ? (
            <>
              <Typography
                display="inline"
                variant={"h4"}
                sx={{ fontWeight: "bold" }}
              >
                Welcome to your{" "}
              </Typography>
              <Typography
                display="inline"
                variant={"h4"}
                sx={{
                  color: theme.palette.talent.main + " !important",
                  fontWeight: "bold",
                }}
              >
                video based
              </Typography>
            </>
          ) : (
            <div>
              <Typography
                display="inline"
                variant={"h2"}
                sx={{ fontWeight: "bold" }}
              >
                Welcome to your{" "}
              </Typography>
              <Typography
                display="inline"
                variant={"h2"}
                sx={{
                  color: theme.palette.talent.main + " !important",
                  fontWeight: "bold",
                }}
              >
                video based
              </Typography>
            </div>
          )}
          <Typography
            variant={isMobile ? "h4" : "h2"}
            sx={{ lineHeight: 0.9, fontWeight: "bold" }}
          >
            professional network
          </Typography>
        </Box>

        <Typography
          sx={{
            maxWidth: isMobile ? "90%" : "40%",
            fontWeight: "light",
            textAlign: isMobile ? "left" : "center",
            mt: isMobile && "2em",
          }}
        >
          It's your time to be part of a local and international community in
          the world of employment. Go Get Them - the place that opens up a world
          of opportunities for you!
        </Typography>
      </Box>

      <Box
        name={"who-are-you-section"}
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant={"h4"} sx={{ pt: "1.5em", pb: "1em" }}>
          So, who are you?
        </Typography>
        <Typography
          sx={{
            maxWidth: isMobile ? "90%" : "40%",
            fontWeight: "light",
            pb: "3em",
            textAlign: "center",
          }}
        >
          Choose one of the options that are right for you. Not sure what's
          relevant to you? contact us
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            pb: isMobile ? "3em" : "6em",
          }}
        >
          <ImageButton
            type={"business"}
            label={"Business"}
            subtitle={
              "Small - large companies - advertising jobs, finding candidates, collaborations"
            }
            handleClick={handleImageButtonClick}
          />
          <ImageButton
            type={"personal"}
            label={"Personal"}
            subtitle={"Job seekers - Find your next job"}
            handleClick={handleImageButtonClick}
          />
          <ImageButton
            type={"freelancer"}
            label={"Freelancer"}
            subtitle={
              "Professionals, small businesses - increase your customer base - business and private"
            }
            handleClick={handleImageButtonClick}
          />
          <ImageButton
            type={"talent"}
            label={"Talent"}
            subtitle={
              "Artists in all fields - international exposure, collaborations"
            }
            handleClick={handleImageButtonClick}
          />
        </Box>

        <>
          {isMobile ? (
            <>
              <Typography variant={"h4"}>Why Choose</Typography>
              <Typography variant={"h4"}>GoGeThem?</Typography>
            </>
          ) : (
            <Typography variant={"h4"}>Why Choose GoGeThem?</Typography>
          )}
        </>
        <Box
          sx={{
            width: isMobile ? "100vw" : "70vw",
            pb: "6em",
          }}
        >
          <AliceCarousel
            items={
              isMobile
                ? [
                    <CarouselItem content={["No fees or brokerage fees"]} />,
                    <CarouselItem
                      content={["Forming together", "without the middle man"]}
                    />,
                    <CarouselItem
                      content={[
                        "There is a place for everyone!",
                        "Companies, Job Seeker & Talents",
                        "All professions in all fields",
                      ]}
                    />,
                    <CarouselItem content={["International exposure"]} />,
                    <CarouselItem
                      content={[
                        "Efficiency and authenticity",
                        "If a picture worth thousands words",
                        "then a video worth much more",
                      ]}
                    />,
                    <CarouselItem
                      content={[
                        "Because it’s time to the next",
                        "and this is here",
                      ]}
                    />,
                  ]
                : [
                    <CarouselItem content={["No fees or brokerage fees"]} />,
                    <CarouselItem
                      content={[
                        "Forming partnerships and hiring services",
                        "without the middle man",
                      ]}
                    />,
                    <CarouselItem
                      content={[
                        "There is a place for everyone!",
                        "Companies, Job Seeker, Self employed & Talents",
                        "All professions in all fields",
                      ]}
                    />,
                    <CarouselItem content={["International exposure"]} />,
                    <CarouselItem
                      content={[
                        "Efficiency and authenticity",
                        "If a picture worth thousands words",
                        "then a video worth much more",
                      ]}
                    />,
                    <CarouselItem
                      content={[
                        "Because it’s time to move to the next thing",
                        "and the next thing is already here",
                      ]}
                    />,
                  ]
            }
            autoPlay
            infinite
            animationDuration={1100}
            autoPlayInterval={3000}
            // disableDotsControls
            disableButtonsControls
          />
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}

export default HomePage;
