import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import RegularText from "../common/Text/RegularText";
import { userType } from "../../shared/userType";
import TitleAndText from "../common/Text/TitleAndText";
import { Box, Typography, useTheme } from "@mui/material";
import FieldsStore from "../../stores/FieldsStore";
import * as fieldsActions from "../../actions/fieldsActions";
import Divider from "@mui/material/Divider";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Public,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { IsMobileContext } from "../../providers/IsMobileService";

function FreelancerInfo(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [freelancerField, setFreelancerField] = useState();
  const [user, setUser] = useState(props.user);

  const subtitle1 = "1.4em";

  useEffect(() => {
    setUser(props.user);

    if (FieldsStore.getFreelancerFields().length > 0) {
      fieldsActions.getFreelancerFields();
    }
  }, [props.user]);

  useEffect(() => {
    FieldsStore.addChangeListener(getField);
    return () => FieldsStore.addChangeListener(getField);
  }, []);

  function getField() {
    setFreelancerField(
      FieldsStore.getFieldByUserAndType(props.user, userType.FREELANCER)
    );
  }

  function handleAction(cb) {
    if (props.isLoggedIn) {
      cb();
    } else {
      //TODO: open modal with options to login or register
    }
  }

  function getSocialIcon(socialNetwork) {
    switch (socialNetwork) {
      case "Facebook":
        return <Facebook sx={{ mr: 1 }} />;
      case "Instagram":
        return <Instagram sx={{ mr: 1 }} />;
      case "YouTube":
        return <YouTube sx={{ mr: 1 }} />;
      case "LinkedIn":
        return <LinkedIn sx={{ mr: 1 }} />;
      case "Twitter":
        return <Twitter sx={{ mr: 1 }} />;
      default:
        return <Public sx={{ mr: 1 }} />;
    }
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.body.foreground }}>
      <Typography variant={"h5"} sx={{ mb: 2 }}>
        About Me
      </Typography>
      <Typography
        variant={"body1"}
        sx={{ color: theme.palette.body.text, lineHeight: 1.56 }}
      >
        {user[userType.FREELANCER].about}
      </Typography>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box
        name={"professional-details"}
        sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
          <Typography variant={"h5"}>Professional Field</Typography>

          <TitleAndText
            title={"Professional Field"}
            text={freelancerField?.name}
          />
          {user[userType.FREELANCER].isDisplayed.website && (
            <TitleAndText
              title={"Website"}
              text={user[userType.FREELANCER].website}
              type={"website"}
            />
          )}
        </Box>
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
          <Typography variant={"h5"}>Social Presence</Typography>

          {user[userType.FREELANCER].isDisplayed.socialNetworks &&
            user[userType.FREELANCER].socialNetworks.map((network) => {
              if (!network.url) return;
              return (
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  key={network.id}
                >
                  {getSocialIcon(network.type)}
                  <TitleAndText
                    title={network.type}
                    text={network.url}
                    type={"socialNetwork"}
                  />
                </Box>
              );
            })}
          {user[userType.FREELANCER].socialNetworks.length < 1 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={"body1"}
                sx={{
                  color: theme.palette.body.lightText,
                  lineHeight: "2.7em",
                  fontWeight: 300,
                }}
              >
                No social presence yet
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <RegularText variant={"subtitle1"} size={subtitle1}>
        Contact Me
      </RegularText>
      <TitleAndText
        title={"Name"}
        text={`${user.firstName} ${user.lastName || ""}`}
      />
      <TitleAndText
        title={"Email"}
        text={`${
          user[userType.FREELANCER].isDisplayed.email ? user.email : "Hidden"
        }`}
        type={user[userType.FREELANCER].isDisplayed.email ? "email" : "text"}
      />
      <TitleAndText
        title={"Phone"}
        text={`${
          user[userType.FREELANCER].isDisplayed.phone ? user.phone : "Hidden"
        }`}
      />
      <TitleAndText
        title={"Address"}
        text={`${
          user[userType.FREELANCER].isDisplayed.address
            ? user.address
            : "Hidden"
        }`}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  infoRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid #eee",
    padding: "1em",
    marginBottom: "1em",
    boxShadow: "1px 1px 2px #ddd, 2px 2px 4px #aaa",
    backgroundColor: "#fff",
  },
  moreSpace: {
    marginBottom: ".5em",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

FreelancerInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default FreelancerInfo;
