import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import * as chatApi from "../api/chatApi";

/**
 * @params
 * withUser:{userId, userType},
 * askingUser:{userId, userType}
 */
export function getChatLog(withUser, askingUser) {
  chatApi.getChatLog(withUser, askingUser).then((data) => {
    if (data && data.chat) {
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_CHAT_LOG,
        chat: data.chat,
        withUser,
      });
    }
  });
}

/**
 * @params
 * chatId: id,
 */
export function getChatLogById(chatId, withUser) {
  chatApi.getChatLogById(chatId).then((data) => {
    dispatcher.dispatch({
      actionType: actionTypes.UPDATE_CHAT_LOG,
      chat: data.chat,
      withUser,
    });
  });
}

/**
 * @params
 * sender:{userId, userType},
 * receiver:{userId, userType},
 * message:{sender, time, content}
 */
export function sendMessage(sender, receiver, message) {
  chatApi.sendMessage(sender, receiver, message);
}
