import React, { useContext, useEffect, useState } from "react";
import Geolocation from "../../common/Geolocation/GooglePlaces";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DatePickerCalendar from "../../common/DatePickerCalendar/DatePickerCalendar";
import SocialNetworksInput from "../SocialNetworksInput/SocialNetworksInput";
import { userType } from "../../../shared/userType";
import FieldsStore from "../../../stores/FieldsStore";
import { getTalentFields } from "../../../actions/fieldsActions";
import FieldFilter from "../../common/Search/Filters/FieldFilter";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { IsMobileContext } from "../../../providers/IsMobileService";

function TalentEditForm(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();

  const [fields, setFields] = useState(FieldsStore.getTalentFields());
  const [selectedRole, setSelectedRole] = useState({
    sub1: 1,
    sub2: 1,
    sub3: 1,
  });
  const [roleOptions, setRoleOptions] = useState({
    sub1: props.user.talent.field?.roles || null,
    sub2: null,
    sub3: null,
  });

  function handleSubRoleChange({ target }) {
    let selectedField = fields.find((f) => f.id === props.user.talent.field);
    handleTalentChange({
      target: { name: target.name, value: target.value },
    });

    switch (target.name) {
      // Case sub1 selected
      case "subField1": {
        // Find the selectedSub1 obj
        let selectedSub1Object = selectedField.roles.find(
          (f) => f.id === target.value
        );

        //Save the selected sub1 id
        setSelectedRole({ sub1: selectedSub1Object.id, sub2: 1, sub3: 1 });
        // Set the sub2 options
        setRoleOptions({
          ...roleOptions,
          sub2: selectedSub1Object?.roles,
          sub3: null,
        });

        break;
      }
      case "subField2": {
        // Find the selectedSub2 obj
        let selectedSub2Object = roleOptions.sub2.find(
          (f) => f.id === target.value
        );

        //Save the selected sub2 id
        setSelectedRole({
          ...selectedRole,
          sub2: selectedSub2Object.id,
          sub3: 1,
        });
        // Set the sub3 options
        setRoleOptions({
          ...roleOptions,
          sub3: selectedSub2Object?.roles,
        });

        break;
      }
      case "subField3": {
        // Find the selectedSub3 obj
        let selectedSub3Object = roleOptions.sub3.find(
          (f) => f.id === target.value
        );

        //Save the selected sub3 id
        setSelectedRole({ ...selectedRole, sub3: selectedSub3Object.id });

        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
    FieldsStore.addChangeListener(fieldsChanged);

    if (FieldsStore.getTalentFields().length === 0) {
      getTalentFields();
    }

    return () => {
      FieldsStore.removeChangeListener(fieldsChanged);
    };
  }, []);

  function fieldsChanged() {
    setFields(FieldsStore.getTalentFields());
  }

  function handleTalentChange({ target }) {
    let talentUserObj = props.user.talent;

    talentUserObj[target.name] = target.value;

    props.handleChange({
      target: {
        name: userType.TALENT,
        value: talentUserObj,
      },
    });
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.body.foreground,
        display: "flex",
        flexDirection: "column",

        "& .MuiOutlinedInput-root": {
          px: 1,
          lineHeight: "1.5em",
          fontSize: "0.95em",
          fontWeight: 300,
          color: theme.palette.text.primary,
          mb: 2,
        },
        "& .MuiInputBase-multiline": {
          py: 3,
          px: 2,
          mb: 0,
        },

        "& .MuiSelect-select": { px: 1, py: 2 },
      }}
    >
      <Typography variant={"h6"} sx={{ mb: 1 }}>
        About Me
      </Typography>

      <TextField
        name={"about"}
        id="about"
        fullWidth
        multiline
        minRows={5}
        maxRows={15}
        variant="outlined"
        color={"talent"}
        margin="none"
        value={props.user.talent.about}
        onChange={handleTalentChange}
      />

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 4 }}>
          Personal Details
        </Typography>

        <Typography variant={"subtitle2"}>Username</Typography>
        <TextField
          id="firstName"
          name={"firstName"}
          onChange={props.handleChange}
          placeholder="Full name"
          margin="none"
          variant="outlined"
          color={"talent"}
          fullWidth
          value={props.user.firstName}
          error={!!props.errors.firstName}
          helperText={props.errors.firstName}
        />

        <Typography variant={"subtitle2"}>Email</Typography>
        <TextField
          id="email"
          name={"email"}
          onChange={props.handleChange}
          // label="Email"
          placeholder={"Your email"}
          type={"email"}
          margin="none"
          variant="outlined"
          color={"talent"}
          fullWidth
          value={props.user.email}
          error={!!props.errors.email}
          helperText={props.errors.email}
        />

        <Typography variant={"subtitle2"}>Phone number</Typography>
        <TextField
          id="phone"
          name={"phone"}
          type={"text"}
          onChange={props.handleChange}
          placeholder="Your phone number"
          margin="none"
          variant="outlined"
          color={"talent"}
          fullWidth
          value={props.user.phone}
          error={!!props.errors.phone}
          helperText={props.errors.phone}
        />

        <Typography variant={"subtitle2"}>Address</Typography>
        <Geolocation
          placeholder="Enter your address"
          color={"talent"}
          handleChange={props.handleChange}
          address={props.user.address}
          error={props.errors?.address}
        />

        <Typography variant={"subtitle2"}>Birth date</Typography>
        <DatePickerCalendar
          name={"birthDate"}
          label={"Date of birth"}
          color={"talent"}
          value={props.user.talent.birthDate}
          handleChange={handleTalentChange}
        />
        {!!props.errors.parentalApproval && (
          <FormControl required error={!!props.errors.parentalApproval}>
            <FormControlLabel
              label="Parental approval"
              color={"talent"}
              control={
                <Checkbox
                  checked={props.user.talent.parentalApproval}
                  onChange={() => {
                    handleTalentChange({
                      target: {
                        name: "parentalApproval",
                        value: !props.user.talent.parentalApproval,
                      },
                    });
                  }}
                  name="parentalApproval"
                  size={"small"}
                />
              }
            />
            <FormHelperText>{props.errors.parentalApproval}</FormHelperText>
          </FormControl>
        )}
      </Box>

      <FormControl component="fieldset">
        <FormLabel color={"talent"} component="legend">
          Gender
        </FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="gender"
          onChange={handleTalentChange}
          value={props.user.talent.gender}
        >
          <FormControlLabel
            value="female"
            control={<Radio color={"talent"} size={"small"} />}
            label="Female"
          />
          <FormControlLabel
            value="male"
            control={<Radio color={"talent"} size={"small"} />}
            label="Male"
          />
          <FormControlLabel
            value="other"
            control={<Radio color={"talent"} size={"small"} />}
            label="Other"
          />
        </RadioGroup>
      </FormControl>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "60%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Social Presence (Optional)
        </Typography>

        <SocialNetworksInput
          networks={props.user.talent.socialNetworks}
          color={"talent"}
          handleChange={({ target }) => {
            props.handleChange({
              target: {
                name: "talent",
                value: {
                  ...props.user.talent,
                  socialNetworks: target.value,
                },
              },
            });
          }}
        />
      </Box>

      <Divider variant={"middle"} light sx={{ my: 4 }} />

      <Box sx={{ width: isMobile ? "100%" : "80%" }}>
        <Typography variant={"h6"} sx={{ mb: 1 }}>
          Talent Skills
        </Typography>

        <Typography variant={"subtitle2"}>Nickname (stage name)</Typography>
        <TextField
          name={"nickname"}
          id="nickname"
          variant="outlined"
          color={"talent"}
          fullWidth
          value={props.user.talent.nickname}
          onChange={handleTalentChange}
        />

        {fields.length > 0 && (
          <>
            <FieldFilter
              fieldName={"Your talent field"}
              color={"talent"}
              name={"field"}
              handleChange={(e) => {
                let field = fields.find((f) => f.id === e.target.value);

                setRoleOptions({
                  sub1: field.roles,
                  sub2: null,
                  sub3: null,
                });
                setSelectedRole({ ...selectedRole, sub1: 1 });
                handleTalentChange(e);
              }}
              fields={fields}
              selected={props.user.talent.field}
            />

            {roleOptions.sub1 && roleOptions.sub1.length > 0 && (
              <FieldFilter
                fieldName={"Your talent type"}
                color={"talent"}
                name={"subField1"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub1}
                selected={selectedRole.sub1}
              />
            )}

            {roleOptions.sub2 && roleOptions.sub2.length > 0 && (
              <FieldFilter
                fieldName={"Choose your expertise"}
                color={"talent"}
                name={"subField2"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub2}
                selected={selectedRole.sub2}
              />
            )}

            {roleOptions.sub3 && roleOptions.sub3.length > 0 && (
              <FieldFilter
                fieldName={"Select your mastery"}
                color={"talent"}
                name={"subField3"}
                handleChange={handleSubRoleChange}
                fields={roleOptions.sub3}
                selected={selectedRole.sub3}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default TalentEditForm;
