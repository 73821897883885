import React, { useContext, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PersonalInfo from "./PersonalInfo";
import { userType } from "../../shared/userType";
import * as userActions from "../../actions/userActions";
import VideoManage from "../common/VideoManage/VideoManage";
import FreelancerInfo from "./FreelancerInfo";
import BusinessInfo from "./BusinessInfo";
import TalentInfo from "./TalentInfo";
import userStore from "../../stores/userStore";
import * as reactionsActions from "../../actions/reactionsActions";
import CONF from "../../configurations/applicationConf";
import Typography from "@mui/material/Typography";
import { Edit } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import ChatModal from "../Messages/ChatModal";
import { useHistory } from "react-router-dom";
import UploadCoverImage from "../Register/UploadCoverImage/UploadCoverImage";
import { IsMobileContext } from "../../providers/IsMobileService";
import Footer from "../HomePage/Footer/Footer";

export default function UserViewPage(props) {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);

  const [currentUser, setCurrentUser] = useState(userStore.getCurrentUser());
  let currentUserType = userStore.getCurrentType();

  const [displayUsername, setDisplayUsername] = useState(
    props.match?.params.username
  );
  const [displayUserType, setDisplayUserType] = useState(
    props.match?.params.userType
  );
  const [isSelfView, setIsSelfView] = useState(false);

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    userStore.addChangeListener(userChanged);

    setIsLoading(true);

    if (!user) {
      userActions.getUserByUsername(displayUsername).then((user) => {
        setUser(user);
        setIsLoading(false);
      });
    }

    if (
      props.match?.params.username !== displayUsername ||
      props.match?.params.userType !== displayUserType
    ) {
      setDisplayUsername(props.match?.params.username);
      setDisplayUserType(props.match?.params.userType);

      userActions
        .getUserByUsername(props.match?.params.username)
        .then((user) => {
          setUser(user);
          setIsLoading(false);
        });
    }

    if (currentUser && currentUser.username === displayUsername) {
      setIsSelfView(true);
    } else {
      setIsSelfView(false);
    }

    return () => userStore.removeChangeListener(userChanged);
  }, [userInfoComponent, props.match?.params]);

  function userChanged() {
    setCurrentUser(userStore.getCurrentUser());
    currentUserType = userStore.getCurrentType();
  }

  function isHeartFull(type) {
    let isFull = false;

    if (currentUser) {
      isFull = currentUser[currentUserType].favorites.find(
        (item) => item.favId === user._id && item.favType === type
      );
    }

    return isFull;
  }

  function userInfoComponent() {
    switch (displayUserType) {
      case userType.PERSONAL: {
        return (
          <PersonalInfo
            user={user}
            isLoggedIn={currentUser !== null}
            isSelfView={isSelfView}
          />
        );
      }
      case userType.TALENT: {
        return (
          <TalentInfo
            user={user}
            isLoggedIn={currentUser !== null}
            isSelfView={isSelfView}
          />
        );
      }
      case userType.FREELANCER: {
        return (
          <FreelancerInfo
            user={user}
            isLoggedIn={currentUser !== null}
            isSelfView={isSelfView}
          />
        );
      }
      case userType.BUSINESS: {
        return (
          <BusinessInfo
            user={user}
            isLoggedIn={currentUser !== null}
            isSelfView={isSelfView}
          />
        );
      }
      default:
        break;
    }
  }

  function handleCloseChat() {
    setIsChatOpen(false);
  }
  function handleOpenChat() {
    setIsChatOpen(true);
  }

  function addOrRemoveFavorite() {
    reactionsActions.addOrRemoveFavorite(
      currentUserType,
      displayUserType,
      user._id
    );
  }

  function handleAction(cb) {
    if (currentUser !== null) {
      cb();
    } else {
      //TODO: open modal with options to login or register
    }
  }

  return (
    <>
      {user && (
        <Box
          name={"main-container"}
          sx={{
            display: "flex",
            flexWrap: isMobile ? "nowrap" : "wrap",
            justifyContent: "space-between",
            alignItems: isMobile && "center",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
            // height: isMobile && "100vh",
          }}
        >
          <MetaTags>
            {displayUserType === userType.BUSINESS ? (
              <title>{`${user.business.companyName} | GoGeThem`}</title>
            ) : (
              <title>{`${user.firstName} ${
                user.lastName || ""
              } | GoGeThem`}</title>
            )}
          </MetaTags>

          <UploadCoverImage
            currentUserType={displayUserType}
            user={user}
            coverImage={
              user[displayUserType].coverImage
                ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/cover_images/${user[displayUserType].coverImage}`
                : ""
            }
            handleChange={() => {}}
            isSelfView={isSelfView}
          />

          {currentUser && currentUserType && (
            <ChatModal
              options={{ isOpen: isChatOpen }}
              handleClose={handleCloseChat}
              roomName={user.firstName}
              currentUser={{ id: currentUser._id, type: currentUserType }}
              receiver={{ id: user._id, type: displayUserType }}
            />
          )}
          <Box
            name={"user-page-content"}
            sx={{
              position: "relative",
              width: isMobile ? "100vw" : "100%",
              maxWidth: "1440px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {isMobile && (
              <Box
                name={"video-section"}
                sx={{
                  width: "100vw",
                  height: "calc(100% + 8em)",
                  backgroundColor: theme.palette.body.foreground,
                  position: "relative",
                }}
              >
                <VideoManage
                  userColor={theme.palette[currentUserType]}
                  isEdit={false}
                  videos={user[displayUserType].videos}
                  isMuted
                />
              </Box>
            )}
            <Box
              name={"account-overview"}
              sx={{
                width: isMobile ? "100vw" : "52%",
                height: "100%",
                backgroundColor: theme.palette.body.foreground,
              }}
            >
              <Box sx={{ p: isMobile ? 4 : 5 }}>
                <Box
                  name={"header"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,

                    "& .MuiButton-root": {
                      borderRadius: "20px",
                      color: theme.palette.buttons.text,
                      borderColor: theme.palette.buttons.lightBorder,
                    },
                    "& .MuiButton-root:hover": {
                      borderColor: theme.palette[currentUserType]?.main,
                    },
                  }}
                >
                  <Typography variant={"h4"} sx={{ fontWeight: "bold" }}>
                    Account overview
                  </Typography>
                  {isSelfView && !isMobile && (
                    <Button
                      variant="outlined"
                      size={"small"}
                      startIcon={
                        <Edit
                          sx={{ color: theme.palette[currentUserType]?.main }}
                        />
                      }
                      onClick={() => history.push("/edit")}
                      sx={{ height: "3em" }}
                    >
                      Edit profile
                    </Button>
                  )}
                </Box>

                {userInfoComponent()}
              </Box>
            </Box>
            {!isMobile && (
              <Box
                name={"video-section"}
                sx={{
                  width: "48%",
                  height: "calc(100% + 8em)",
                  backgroundColor: theme.palette.body.foreground,
                  position: "relative",
                  top: "-8em",
                  boxShadow: "0 10px #fff, 0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                }}
              >
                <VideoManage
                  userColor={theme.palette[currentUserType]}
                  isEdit={false}
                  videos={user[displayUserType].videos}
                  isMuted
                />
              </Box>
            )}
          </Box>

          {currentUser && currentUserType && (
            <ChatModal
              options={{ isOpen: isChatOpen }}
              handleClose={handleCloseChat}
              roomName={user.firstName}
              currentUser={{ id: currentUser._id, type: currentUserType }}
              receiver={{ id: user._id, type: displayUserType }}
            />
          )}

          {isMobile && <Footer />}
        </Box>
      )}
    </>
  );
}
