import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import AddOrEditVacancyDialog from "./AddOrEditVacancyDialog";
import { openSnackbar } from "../../shared/Notifier";
import * as vacancyActions from "../../actions/vacancyActions";
import * as userActions from "../../actions/userActions";
import userStore from "../../stores/userStore";
import { userType } from "../../shared/userType";

function AddVacancyFab() {
  const classes = useStyles();
  const [isAddVacancyDialogOpen, setIsAddVacancyDialogOpen] = useState(false);
  const [vacancy, setVacancy] = useState();

  function handleAddVacancyDialogOpen() {
    setIsAddVacancyDialogOpen(true);
  }
  function handleAddVacancyDialogClose() {
    setIsAddVacancyDialogOpen(false);
  }

  function handleVacancySubmit(vacancyFormData, isCreate, isLastStep) {
    if (isCreate) {
      let currentUser = userStore.getCurrentUser();

      vacancyActions
        .createVacancy({
          ...vacancyFormData,
          publisherId: currentUser._id,
          companyName: currentUser.business.companyName,
          companyLogo: currentUser.business.image,
        })
        .then((result) => {
          const createdVacancy = result.createdVacancy;

          currentUser[userType.BUSINESS].vacancies.push(createdVacancy._id);
          setVacancy(createdVacancy);

          userActions.updateUser(currentUser);
        });
    } else {
      vacancyActions.updateVacancy({ ...vacancyFormData, _id: vacancy._id });

      if (isLastStep) {
        openSnackbar("Vacancy published successfully!", "success");
      }
    }
  }

  return (
    <>
      <Fab
        className={classes.addVacancyFab}
        color={"primary"}
        aria-label={"add"}
        onClick={handleAddVacancyDialogOpen}
      >
        <AddIcon />
      </Fab>
      <AddOrEditVacancyDialog
        vacancyId={vacancy?._id}
        handleClose={handleAddVacancyDialogClose}
        isOpen={isAddVacancyDialogOpen}
        headerText={"Publish Vacancy"}
        handleSubmit={handleVacancySubmit}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  addVacancyFab: {
    position: "fixed",
    bottom: "1.5em",
    right: "1.5em",
  },
}));

export default AddVacancyFab;
