import React, { useContext, useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { searchType } from "../../../shared/searchType";
import Avatar from "@mui/material/Avatar";
import CONF from "../../../configurations/applicationConf";
import FieldsStore from "../../../stores/FieldsStore";
import {
  Favorite,
  FavoriteBorderOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import ShareDialog from "../../ShareDialog/ShareDialog";
import { IsMobileContext } from "../../../providers/IsMobileService";

export default function UserDetailsAndActions(props) {
  const theme = useTheme();
  const isMobile = useContext(IsMobileContext);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {props.item.type !== searchType.JOB_SEARCH && (
        <Box
          onClick={props.handleClick}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
            width: isMobile ? "60%" : "80%",
          }}
        >
          <Avatar
            sx={{
              width: props.avatar.size,
              height: props.avatar.size,
              border: `2px solid ${theme.palette[props.item.type].main}`,
              mr: 2,
            }}
            src={
              props.item.entity[props.item.type].image
                ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${
                    props.item.entity[props.item.type].image
                  }`
                : props.item.entity[props.item.type]?.socialImage || ""
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <Typography noWrap>{`${props.item.entity.firstName} ${
              props.item.entity.lastName || ""
            }`}</Typography>
            {props.item.entity[props.item.type].field && (
              <Typography noWrap sx={{ color: "#777", fontWeight: 300 }}>
                {
                  FieldsStore.getFieldByUserAndType(
                    props.item.entity,
                    props.item.type
                  )?.name
                }
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {props.item.type === searchType.JOB_SEARCH && (
        <Box
          onClick={props.handleClick}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
            width: isMobile ? "60%" : "80%",
          }}
        >
          <Avatar
            sx={{
              width: props.avatar.size,
              height: props.avatar.size,
              border: `2px solid ${theme.palette.business.main}`,
              mr: 2,
            }}
            src={
              props.item.entity.companyLogo
                ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${props.item.entity.companyLogo}`
                : props.item.entity.business?.socialImage || ""
            }
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <Typography noWrap>{props.item.entity.companyName}</Typography>

            <Typography noWrap sx={{ color: "#777", fontWeight: 300 }}>
              {props.item.entity.name} - {props.item.entity.field}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",

          "& .MuiIconButton-root": {
            // borderRadius: "4em",
            boxShadow: "0 4px 4px #ddd",
          },
        }}
      >
        {props.like && (
          <IconButton
            size={isMobile ? "small" : "large"}
            onClick={props.handleToggleAddToFavorites}
          >
            {props.isFavorite ? <Favorite /> : <FavoriteBorderOutlined />}
          </IconButton>
        )}
        {props.share && (
          <IconButton
            size={isMobile ? "small" : "large"}
            sx={{ ml: 2 }}
            onClick={() => {
              setShareDialogOpen(true);
            }}
          >
            <ShareOutlined />
          </IconButton>
        )}
        <ShareDialog
          isOpen={shareDialogOpen}
          handleClose={() => {
            setShareDialogOpen(false);
          }}
          pathname={`/${props.item.link}`}
        />
      </Box>
    </Box>
  );
}
