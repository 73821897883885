export async function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else if ([400, 401, 403, 404].includes(response.status)) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  } else {
    throw new Error(
      JSON.stringify({ message: "Network response was not ok." })
    );
  }
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  const errMsg = error?.message;
  console.error("API call failed, error: " + errMsg);
}
