import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function RegularText(props) {
  const useStyles = makeStyles(() => ({
    font: {
      fontFamily: "'Open Sans', sans-serif",
      fontDisplay: "swap",
      fontWeight: `${props.weight}`,
      src: `url(https://fonts.googleapis.com/css2?family=Open+Sans) format('truetype')`,
      color: `${props.color}`,
      fontSize: `${props.size}`,
      "&::selection": {
        background: "#3e3dae",
        color: "#fff",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Typography variant={props.variant} className={classes.font}>
      {props.children}
    </Typography>
  );
}

RegularText.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
  size: PropTypes.string,
};

export default RegularText;
