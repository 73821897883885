import React from "react";
import { makeStyles } from "@mui/styles";

export default function DevPrintBox({ displayParams }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      zIndex: 999,
      backgroundColor: "#fff",
      position: "fixed",
      right: "1em",
      top: "50%",

      border: "solid 3px red",
      padding: "1em",
    },
    item: {
      color: "red",
      maxWidth: 500,
    },
  }));
  const classes = useStyles();

  const isDev = process.env.REACT_APP_IS_DEV - 0 > 0;

  return (
    <>
      {isDev && (
        <div className={classes.root}>
          {displayParams.map((item) => (
            <div className={classes.item} key={item.name}>
              {item.name}: {item.value}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
