import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import * as reactionsApi from "../api/reactionsApi";

export function addOrRemoveFavorite(
  myUserType,
  favoriteUserType,
  favoriteUserId
) {
  return reactionsApi
    .addOrRemoveFavorite(myUserType, favoriteUserType, favoriteUserId)
    .then((res) => {
      dispatcher.dispatch({
        actionType: actionTypes.UPDATE_USER,
        user: res.updatedUser,
      });
    });
}

export function getFavorites(userType, pagination) {
  return reactionsApi.getFavorites(userType, pagination).then((res) => {
    return res;
  });
}
