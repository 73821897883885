import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function VacancyTypeFilter(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
  }));
  const classes = useStyles();

  const vacancyType = props.selected || "";

  return (
    <FormControl component="fieldset" className={classes.root}>
      <InputLabel id="vacancy-select-label">Vacancy type</InputLabel>
      <Select
        labelId="vacancy-select-label"
        id="vacancy-select"
        name={"vacancyType"}
        value={vacancyType}
        onChange={props.handleChange}
      >
        <MenuItem value={"full"} key={1}>
          Full
        </MenuItem>
        <MenuItem value={"partial"} key={2}>
          Partial
        </MenuItem>
        <MenuItem value={"shifts"} key={3}>
          Shifts
        </MenuItem>
        <MenuItem value={"nights"} key={4}>
          Nights
        </MenuItem>
        <MenuItem value={"weekends"} key={5}>
          Weekends
        </MenuItem>
        <MenuItem value={"student"} key={6}>
          Student
        </MenuItem>
        <MenuItem value={"temporary"} key={7}>
          Temporary
        </MenuItem>
        <MenuItem value={"longTerm"} key={8}>
          Long term
        </MenuItem>
      </Select>
    </FormControl>
  );
}

VacancyTypeFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default VacancyTypeFilter;
