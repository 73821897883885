import React, { useContext, useEffect, useState } from "react";
import userStore from "../../stores/userStore";
import PersonalEditForm from "../Register/PersonalEditForm/PersonalEditForm";
import FieldsStore from "../../stores/FieldsStore";
import Button from "@mui/material/Button";
import { CONST } from "../../shared/consts";
import * as userActions from "../../actions/userActions";
import { useHistory } from "react-router-dom";
import * as fieldsActions from "../../actions/fieldsActions";
import VideoManage from "../common/VideoManage/VideoManage";
import { userType } from "../../shared/userType";
import TalentEditForm from "../Register/TalentEditForm/TalentEditForm";
import BusinessEditForm from "../Register/BusinessEditForm/BusinessEditForm";
import FreelancerEditForm from "../Register/FreelancerEditForm/FreelancerEditForm";
import videoStore from "../../stores/videoStore";
import CONF from "../../configurations/applicationConf";
import UploadCoverImage from "../Register/UploadCoverImage/UploadCoverImage";
import MetaTags from "react-meta-tags";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CloseRounded } from "@mui/icons-material";
import { IsMobileContext } from "../../providers/IsMobileService";
import Footer from "../HomePage/Footer/Footer";

function EditPage(props) {
  const isMobile = useContext(IsMobileContext);
  const theme = useTheme();
  const history = useHistory();

  const [user, setUser] = useState(userStore.getCurrentUser());
  const [currentUserType, setCurrentUserType] = useState(
    props?.location?.state?.temporaryType || userStore.getCurrentType()
  );
  const [isTemp, setIsTemp] = useState(!!props?.location?.state?.temporaryType);
  const [errors] = useState({});
  const [formDisplayAllFields, setFormDisplayAllFields] = useState(false);
  const [fields, setFields] = useState(FieldsStore.getFields());
  const [isVideoMuted, setIsVideoMuted] = useState(videoStore.getIsMuted());

  useEffect(() => {
    FieldsStore.addChangeListener(fieldChange);
    userStore.addChangeListener(userChange);

    if (fields.length === 0) {
      fieldsActions.getFields();
    }

    return () => {
      userStore.removeChangeListener(userChange);
      FieldsStore.removeChangeListener(fieldChange);
    };
  }, [user, fields, currentUserType]);

  function userChange() {
    let currentStoreUserType = userStore.getCurrentType();

    if (currentStoreUserType !== currentUserType) {
      setIsTemp(false);
      setCurrentUserType(currentStoreUserType);
    }

    setUser(userStore.getCurrentUser());
  }
  function fieldChange() {
    setFields(FieldsStore.getFields());
  }

  function handleChange({ target }) {
    setUser({ ...user, [target.name]: target.value });
  }

  function handleSubmit(event) {
    // If temp user type is true - add the currentUserType to available user types
    if (isTemp) {
      userActions
        .updateUser({
          ...user,
          userType: [...user.userType, currentUserType],
        })
        .then(() => {
          history.push("/profile");
        });
    } else {
      userActions.updateUser(user).then(() => {
        history.push("/profile");
      });
    }

    event.preventDefault();
  }

  return (
    <>
      <MetaTags>
        <title>{`Edit Profile | GoGeThem`}</title>
      </MetaTags>
      {user && (
        <Box
          name={"main-form-container"}
          component={"form"}
          sx={{
            mb: 10,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: isMobile ? "nowrap" : "wrap",
            alignItems: isMobile && "center",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
          }}
          onSubmit={handleSubmit}
        >
          <UploadCoverImage
            isEdit
            currentUserType={currentUserType}
            user={user}
            coverImage={
              user[currentUserType].coverImage
                ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/cover_images/${user[currentUserType].coverImage}`
                : ""
            }
            handleChange={handleChange}
          />

          {isMobile && (
            <Box
              name={"video-section"}
              sx={{
                width: "100vw",
                height: "calc(100% + 8em)",
                backgroundColor: theme.palette.body.foreground,
                position: "relative",
              }}
            >
              <VideoManage
                isEdit
                isAutoplay
                isControlEnabled
                isMuted={isVideoMuted}
                videos={user[currentUserType].videos}
                userType={currentUserType}
                userColor={theme.palette[currentUserType]}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100vw",
            }}
          >
            <Box
              name={"account-overview"}
              sx={{
                width: isMobile ? "100vw" : "52%",
                backgroundColor: theme.palette.body.foreground,
              }}
            >
              <Box sx={{ p: isMobile ? 4 : 5 }}>
                <Box
                  name={"header"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,

                    "& .MuiButton-root": {
                      borderRadius: "19px",
                      color: theme.palette.buttons.text,
                      borderColor: theme.palette.buttons.lightBorder,
                    },
                    "& .MuiButton-root:hover": {
                      borderColor: theme.palette[currentUserType]?.main,
                    },
                  }}
                >
                  <Typography variant={"h4"} sx={{ fontWeight: "bold" }}>
                    Account overview
                  </Typography>
                  {!isMobile && (
                    <Button
                      variant="outlined"
                      size={"small"}
                      startIcon={
                        <CloseRounded
                          sx={{ color: theme.palette[currentUserType]?.main }}
                        />
                      }
                      onClick={() => history.goBack()}
                      sx={{ height: "3em" }}
                    >
                      cancel
                    </Button>
                  )}
                </Box>
                {fields.length > 0 && currentUserType === userType.PERSONAL && (
                  <PersonalEditForm
                    handleChange={handleChange}
                    fields={fields}
                    user={user}
                    errors={errors}
                    expandAll={formDisplayAllFields}
                    handleExpandAll={(val) => setFormDisplayAllFields(val)}
                  />
                )}
                {currentUserType === userType.TALENT && (
                  <TalentEditForm
                    handleChange={handleChange}
                    user={user}
                    errors={errors}
                    expandAll={formDisplayAllFields}
                    handleExpandAll={(val) => setFormDisplayAllFields(val)}
                  />
                )}
                {fields.length > 0 && currentUserType === userType.BUSINESS && (
                  <BusinessEditForm
                    handleChange={handleChange}
                    user={user}
                    errors={errors}
                    fields={fields}
                    expandAll={formDisplayAllFields}
                    handleExpandAll={(val) => setFormDisplayAllFields(val)}
                  />
                )}
                {fields.length > 0 &&
                  currentUserType === userType.FREELANCER && (
                    <FreelancerEditForm
                      handleChange={handleChange}
                      user={user}
                      errors={errors}
                      expandAll={formDisplayAllFields}
                      handleExpandAll={(val) => setFormDisplayAllFields(val)}
                    />
                  )}
              </Box>
            </Box>

            {!isMobile && (
              <Box
                sx={{
                  width: "48%",
                  height: "calc(100% + 8em)",
                  backgroundColor: theme.palette.body.foreground,
                  position: "relative",
                }}
              >
                {currentUserType && (
                  <VideoManage
                    isEdit
                    isAutoplay
                    isControlEnabled
                    isMuted={isVideoMuted}
                    videos={user[currentUserType].videos}
                    userType={currentUserType}
                    userColor={theme.palette[currentUserType]}
                  />
                )}
                <div className={"video-picker"} />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: "100vw",
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: theme.palette.body.foreground,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "3px 3px 50px 0 rgba(0, 0, 0, 0.16)",
              pt: 3,
              pb: 1,
            }}
          >
            <Button
              variant="outlined"
              size={"large"}
              color={currentUserType}
              sx={{ fontWeight: 100, mr: 4, px: 7 }}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size={"large"}
              color={currentUserType}
              type={"submit"}
              sx={{ fontWeight: 100, px: 4 }}
            >
              Save changes
            </Button>
          </Box>
          {isMobile && <Footer />}
        </Box>
      )}
    </>
  );
}

export default EditPage;
