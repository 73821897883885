import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Facebook,
  Link,
  Telegram,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";

function ShareDialog(props) {
  const protocol = "https://";
  const domain = "www.gogethem.com";
  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      sx={{ p: 4 }}
    >
      <DialogTitle
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant={"h6"} sx={{ lineHeight: 2.2 }}>
          Share This Gig
        </Typography>
        <Typography variant={"subtitle2"}>
          Spread the word about this Gig on GoGeThem
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ width: "420px", display: "flex", justifyContent: "center" }}
      >
        <IconButton
          edge={"start"}
          aria-label="whatsapp"
          color={"primary"}
          onClick={() =>
            window.open(
              `https://wa.me/?text=${protocol}${domain}${props.pathname}`,
              "_blank"
            )
          }
        >
          <WhatsApp fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="facebook"
          color={"primary"}
          onClick={() =>
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${domain}${props.pathname}&t=Check out my profile at GoGeThem!`,
              "_blank"
            )
          }
        >
          <Facebook fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="twitter"
          color={"primary"}
          onClick={() =>
            window.open(
              `https://twitter.com/intent/tweet?text=${protocol}${domain}${props.pathname}`,
              "_blank"
            )
          }
        >
          <Twitter fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="telegram"
          color={"primary"}
          onClick={() =>
            window.open(
              `https://telegram.me/share/url?url=${protocol}${domain}${props.pathname}&text=Check out my profile at GoGeThem!`,
              "_blank"
            )
          }
        >
          <Telegram fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="Copy link"
          edge={"end"}
          color={"primary"}
          onClick={() => {
            navigator.clipboard.writeText(
              `${protocol}${domain}${props.pathname}`
            );
          }}
        >
          <Link fontSize="large" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

export default ShareDialog;
