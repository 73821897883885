import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CONF from "../../../../../configurations/applicationConf";
import {
  alpha,
  Box,
  Button,
  Divider,
  Drawer,
  Switch,
  useTheme,
} from "@mui/material";
import { ClickAwayListener, Grow } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import FavoriteBorderOutlined from "@mui/icons-material/FavoriteBorderOutlined";
import MailOutlined from "@mui/icons-material/MailOutlined";
import { styled } from "@mui/styles";
import { userType } from "../../../../../shared/userType";
import { IsMobileContext } from "../../../../../providers/IsMobileService";
import { Menu } from "@mui/icons-material";
import logo from "../../../../../shared/resources/images/Logo.svg";

export default function RightSide({
  currentUser,
  currentUserType,
  handleToggleDeactivateUser,
  toggleSideMenuOpen,
}) {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useContext(IsMobileContext);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userInfo = React.useRef(null);

  function handleToggleUserMenu() {
    setIsUserMenuOpen((prevOpen) => !prevOpen);
  }

  const ColoredSwitch = styled(Switch)(() => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: theme.palette[currentUserType].main,
      "&:hover": {
        backgroundColor: alpha(
          theme.palette[currentUserType].main,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette[currentUserType].main,
    },
  }));

  function toggleDrawer() {
    setIsUserMenuOpen(!isUserMenuOpen);
  }

  return (
    <>
      {!currentUser && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isMobile ? (
            <>
              <Button onClick={toggleDrawer} color="talent">
                <Menu />
              </Button>
              <Drawer
                sx={{
                  "& .MuiDrawer-paper": {
                    width: "66vw",
                    display: "flex",
                    flexDirection: "column",
                    py: "2em",
                    px: "1.3em",
                  },
                }}
                open={isUserMenuOpen}
                onClose={toggleDrawer}
              >
                <img
                  style={{
                    width: "40vw",
                    marginBottom: "2em",
                  }}
                  src={logo}
                  alt={"GoGeThem"}
                />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Your profile
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 300, mb: "2em" }}>
                  Sign in to start
                </Typography>

                <Button
                  disableElevation
                  size={"large"}
                  variant="contained"
                  color={userType.TALENT}
                  sx={{
                    borderRadius: 19,
                    mb: "1.5em",
                  }}
                  onClick={() => {
                    toggleDrawer();
                    history.push("/login");
                  }}
                >
                  Sign in
                </Button>

                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                  Don't have an account?
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 300, textDecoration: "underline" }}
                  onClick={() => {
                    toggleDrawer();
                    history.push("/register");
                  }}
                >
                  Sign Up here
                </Typography>
              </Drawer>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  mr: 3,
                  cursor: "pointer",
                  // textDecoration: "underline",
                }}
              >
                <Link to={"/login"}>Sign in</Link>
              </Typography>

              <Button
                disableElevation
                size={isMobile ? "medium" : "large"}
                variant="contained"
                color={userType.TALENT}
                sx={{
                  borderRadius: 19,
                  mr: isMobile && 2,
                }}
                onClick={() => {
                  history.push("/register");
                }}
              >
                Get Started
              </Button>
            </>
          )}
        </Box>
      )}

      {currentUserType && currentUser && (
        <>
          {isMobile ? (
            <Button
              onClick={toggleSideMenuOpen}
              color={currentUserType || "talent"}
            >
              <Menu />
            </Button>
          ) : (
            <>
              <Box
                ref={userInfo}
                onClick={handleToggleUserMenu}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  alt={currentUser.firstName}
                  src={
                    currentUser[currentUserType].image
                      ? `${CONF.AWS_ACCESS_BUCKET_URL}/public/profile_images/${currentUser[currentUserType].image}`
                      : currentUser[currentUserType].socialImage || ""
                  }
                  sx={{
                    backgroundColor: theme.palette.body.avatar,
                    width: 51,
                    height: 51,
                  }}
                >
                  {!currentUser[currentUserType].image &&
                    currentUser.firstName[0]}
                </Avatar>

                <Box
                  sx={{
                    position: "relative",
                    top: -15,
                    left: -10,
                    width: 17,
                    height: 17,
                    objectFit: "contain",
                    border: "solid 1px #fff",
                    borderRadius: "100%",
                    backgroundColor: currentUser[currentUserType].availability
                      ? "#97d519"
                      : "#9e9e9e",
                  }}
                />

                {!isMobile && (
                  <Typography
                    variant={"body2"}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {currentUser.firstName}
                    <ExpandMoreRoundedIcon sx={{ ml: 1 }} />
                  </Typography>
                )}
              </Box>

              <Popper
                open={isUserMenuOpen}
                anchorEl={userInfo.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper elevation={0}>
                      <ClickAwayListener onClickAway={handleToggleUserMenu}>
                        <MenuList
                          sx={{
                            top: 32,
                            padding: 0,
                            backgroundColor: theme.palette.body.foreground,
                            boxShadow: "0 3px 0 0 rgba(0, 0, 0, 0.16)",
                            width: 222,
                          }}
                          id="menu-list-grow"
                        >
                          <MenuItem
                            sx={{
                              px: 3,
                              py: 1.5,
                              color: "#171725",
                              "&:hover": { backgroundColor: "#fff" },
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleToggleDeactivateUser();
                            }}
                          >
                            <Typography sx={{ letterSpacing: 0.11 }}>
                              User Visible
                            </Typography>
                            <ColoredSwitch
                              checked={
                                currentUser[currentUserType].availability
                              }
                            />
                          </MenuItem>
                          <Divider variant={"middle"} light />
                          <MenuItem
                            sx={{ px: 3, py: 1.5, color: "#171725" }}
                            onClick={() => {
                              history.push("/");
                            }}
                          >
                            <MailOutlined sx={{ color: "#7e84a3" }} />
                            <Typography sx={{ ml: 2, letterSpacing: 0.11 }}>
                              Inbox
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            sx={{ px: 3, py: 1.5, color: "#171725" }}
                            onClick={() => {
                              history.push("/");
                            }}
                          >
                            <FavoriteBorderOutlined sx={{ color: "#7e84a3" }} />
                            <Typography sx={{ ml: 2, letterSpacing: 0.11 }}>
                              Favorites
                            </Typography>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </>
      )}
    </>
  );
}
