import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import userStore from "../../stores/userStore";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import * as userActions from "../../actions/userActions";
import { IsMobileContext } from "../../providers/IsMobileService";

export default function PrivacySettingsForm() {
  const isMobile = useContext(IsMobileContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      // flexWrap: "wrap",
      alignItems: "center",
      marginLeft: isMobile ? "1em" : 0,
    },
    formItem: {
      // width: "30%",
      marginTop: "1.5em",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));
  const classes = useStyles();

  const [currentUser, setCurrentUser] = useState(userStore.getCurrentUser());
  const [currentUserType, setCurrentUserType] = useState(
    userStore.getCurrentType()
  );

  useEffect(() => {
    userStore.addChangeListener(updateUser);

    return () => userStore.removeChangeListener(updateUser);
  }, []);

  function updateUser() {
    setCurrentUser(userStore.getCurrentUser());
    setCurrentUserType(userStore.getCurrentType());
  }

  function handleUpdateProfileDisplay({ target }) {
    let newValue = !currentUser[currentUserType].isDisplayed[target.value];

    let updatedUser = {
      ...currentUser,
      [currentUserType]: {
        ...currentUser[currentUserType],
        isDisplayed: {
          ...currentUser[currentUserType].isDisplayed,
          [target.value]: newValue,
        },
      },
    };

    setCurrentUser(updatedUser);
    userActions.updateUser(updatedUser);
  }

  const camel2title = (camelCase) =>
    camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();

  return (
    <div className={classes.root}>
      <Typography variant={"h5"}>Display at profile page</Typography>
      {currentUser && currentUserType && (
        <div style={{ minWidth: isMobile ? 0 : 300 }}>
          {Object.keys(currentUser[currentUserType].isDisplayed).map((key) => (
            <div className={classes.formItem} key={key}>
              <Typography>{camel2title(key)}</Typography>
              <Switch
                value={key}
                checked={currentUser[currentUserType].isDisplayed[key]}
                onClick={handleUpdateProfileDisplay}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
