import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import userStore from "../../stores/userStore";
import { CONST } from "../../shared/consts";
import { Paper } from "@mui/material";
import { getVacanciesByIds, updateVacancy } from "../../actions/vacancyActions";
import MenuListButton from "../common/MenuListButton/MenuListButton";
import VideoManage from "../common/VideoManage/VideoManage";
import JobStatus from "./JobStatus";
import { compareDesc } from "date-fns";
import AddOrEditVacancyDialog from "./AddOrEditVacancyDialog";
import * as vacancyActions from "../../actions/vacancyActions";
import { openSnackbar } from "../../shared/Notifier";
import { userType } from "../../shared/userType";
import * as userActions from "../../actions/userActions";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

function VacancyManage() {
  let a = 0;

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      [theme.breakpoints.up(CONST.LG)]: {
        // width: "45%",
      },
      [theme.breakpoints.down(CONST.LG)]: {
        width: "90%",
      },
      maxWidth: "1440px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      userSelect: "none",
    },
    vacancyCard: {
      width: "100%",
      minHeight: "100px",
      padding: ".5em 1em .5em 1em",
      marginBottom: "1em",
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    cardColumn: {
      marginLeft: "1.5em",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    spacer: {
      alignSelf: "center",
      width: "5em",
      borderTop: "1px solid #c6c6c6",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      fontSize: ".9em",
      color: "#727272",
      marginBottom: "1em",
    },
    vacancyName: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      "&:hover": {
        color: "red",
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(userStore.getCurrentUser());
  const [jobs, setJobs] = useState([]);
  const [isEditVacancyDialogOpen, setIsEditVacancyDialogOpen] = useState(false);
  const [selectedVacancyToEdit, setSelectedVacancyToEdit] = useState(null);

  const MAX_LENGTH = 175;

  useEffect(() => {
    if (!currentUser) {
      userStore.addChangeListener(userLoaded);
    } else {
      getVacanciesByIds(currentUser.business.vacancies).then((vacancies) => {
        setJobs(
          vacancies.sort((j1, j2) =>
            compareDesc(new Date(j1.updatedDate), new Date(j2.updatedDate))
          )
        );
      });
    }

    return () => userStore.removeChangeListener(userLoaded);
  }, [currentUser]);

  function userLoaded() {
    setCurrentUser(userStore.getCurrentUser());
  }

  function handleEdit(jobId) {
    setSelectedVacancyToEdit(jobs.find((x) => x._id === jobId));
    setIsEditVacancyDialogOpen(true);
  }

  function handleCloseEditDialog(vacancy) {
    // Not good because the job edited is not returned
    if (vacancy) {
      let index;
      jobs.find((x, i) => {
        index = i;
        return x._id === vacancy._id;
      });

      jobs.splice(index, 1);
      setJobs([vacancy, ...jobs]);
    }

    setIsEditVacancyDialogOpen(false);
  }

  function handleUpdateJob(updatedVacancy, isCreate, isLastStep, vacancyId) {
    vacancyActions.updateVacancy(updatedVacancy);

    if (isLastStep) {
      openSnackbar("Vacancy published successfully!", "success");
    }
  }

  function handleDelete(jobId) {
    let index;
    let job = jobs.find((x, i) => {
      index = i;
      return x._id === jobId;
    });

    job.isDeleted = true;

    jobs.splice(index, 1);
    setJobs([...jobs]);

    updateVacancy(job);
  }

  function handleAvailability(jobId) {
    let index;
    let job = jobs.find((x, i) => {
      index = i;
      return x._id === jobId;
    });

    job.isEnabled = !job.isEnabled;
    jobs.splice(index, 1, job);
    setJobs([...jobs]);

    updateVacancy(job);
  }

  async function handleCopy(jobId) {
    let job = jobs.find((x) => {
      return x._id === jobId;
    });

    delete job._id;
    let duplicatedVacancy = await createVacancy({
      ...job,
      updatedDate: Date.now(),
      createdDate: Date.now(),
      name: `${job.name} - copy`,
    });
    let updatedJobs = [duplicatedVacancy, ...jobs];

    setSelectedVacancyToEdit(duplicatedVacancy);
    setJobs(updatedJobs);

    setIsEditVacancyDialogOpen(true);
  }

  function createVacancy(vacancy) {
    return vacancyActions
      .createVacancy({
        ...vacancy,
        publisherId: currentUser._id,
        companyName: currentUser.business.companyName,
        companyLogo: currentUser.business.image,
      })
      .then((result) => {
        const createdVacancy = result.createdVacancy;

        currentUser[userType.BUSINESS].vacancies.push(createdVacancy._id);
        userActions.updateUser(currentUser);

        return createdVacancy;
      });
  }

  return (
    <>
      {isEditVacancyDialogOpen && (
        <AddOrEditVacancyDialog
          isOpen={isEditVacancyDialogOpen}
          handleClose={handleCloseEditDialog}
          headerText={"Edit Vacancy"}
          vacancy={selectedVacancyToEdit}
          vacancyId={selectedVacancyToEdit._id}
          handleSubmit={handleUpdateJob}
        />
      )}

      <div className={classes.mainContainer}>
        {jobs &&
          jobs.length > 0 &&
          jobs.map((job) => (
            <Paper
              key={job._id}
              variant={"elevation"}
              elevation={2}
              className={classes.vacancyCard}
            >
              <MenuListButton
                disablePortal
                value={job._id}
                options={[
                  { name: "Edit", func: handleEdit },
                  {
                    name: !!job.isEnabled ? "Disable" : "Enable",
                    func: handleAvailability,
                  },
                  { name: "Remove", func: handleDelete },
                  { name: "Duplicate", func: handleCopy },
                ]}
              />

              <JobStatus isAvailable={!!job.isEnabled} />

              <div className={classes.cardColumn} style={{ width: "40%" }}>
                <div className={classes.header}>
                  <span>Name</span>
                  <div className={classes.spacer} />
                </div>
                <Typography
                  onClick={() => {
                    history.push(`/view/vacancy/${job._id}`);
                  }}
                  className={classes.vacancyName}
                >
                  {job.name?.length > MAX_LENGTH
                    ? `${job.name.slice(0, MAX_LENGTH)}...`
                    : `${job.name}`}
                </Typography>
              </div>

              <div className={classes.cardColumn} style={{ width: "14%" }}>
                <div className={classes.header}>
                  <span>Number</span>
                  <div className={classes.spacer} />
                </div>
                <span>{job.number}</span>
              </div>

              <div className={classes.cardColumn} style={{ width: "12%" }}>
                <div className={classes.header}>
                  <span>Created</span>
                  <div className={classes.spacer} />
                </div>
                <span>
                  {new Date(job.createdDate).toLocaleDateString("en-GB")}
                </span>
              </div>

              <div className={classes.cardColumn} style={{ width: "18%" }}>
                <VideoManage
                  maxHeight={"90px"}
                  maxWidth={"150px"}
                  isEdit={false}
                  isVacancyVideo
                  videos={[job.video]}
                />
              </div>

              <div
                style={{
                  marginLeft: "2em",
                  width: "80%",
                  color: "#727272",
                  fontSize: ".9em",
                }}
              >
                <span>
                  {job.about?.length > MAX_LENGTH
                    ? `${job.about.slice(0, MAX_LENGTH)}...`
                    : `${job.about}`}
                </span>
              </div>
            </Paper>
          ))}
      </div>
    </>
  );
}

export default VacancyManage;
